import React, { useMemo } from 'react';
import { Box, Typography, Chip, useMediaQuery, useTheme, CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import { useDrop, useDrag } from 'react-dnd';
import { motion, AnimatePresence } from 'framer-motion';

const generateColor = (seed) => {
  const hash = seed.split('').reduce((acc, char) => {
    const charCode = char.charCodeAt(0);
    return ((acc << 5) - acc) + charCode | 0;
  }, 0);
  return `#${(hash & 0x00FFFFFF).toString(16).padStart(6, '0')}`;
};

const WorkoutGroup = ({ workoutName, workouts, color, isPast, isMobile, onMoveWorkout, expanded }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'WORKOUT_GROUP',
    item: { workoutName, workouts },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <motion.div
      ref={drag}
      whileHover={{ 
        scale: 1.02,
        boxShadow: '0px 1px 3px rgba(0,0,0,0.1)',
        transition: { duration: 0.15 }
      }}
    >
      <Box
        sx={{
          backgroundColor: `${color}22`,
          fontSize: isMobile ? '0.4rem' : '0.65rem',
          padding: '1px 2px',
          borderRadius: '2px',
          marginBottom: '1px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          opacity: isPast ? 0.6 : isDragging ? 0.7 : 1,
          cursor: 'move',
          border: `1px solid ${color}44`,
          transition: 'all 0.2s ease',
        }}
      >
        <Typography 
          variant="caption" 
          sx={{ 
            display: 'block', 
            overflow: 'hidden',
            fontWeight: 500,
          }}
        >
          {workoutName}
        </Typography>
        <AnimatePresence>
          {expanded && workouts.map(workout => (
            <motion.div
              key={workout.id}
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
            >
              <IndividualWorkout
                workout={workout}
                color={color}
                isPast={isPast}
                isMobile={isMobile}
                onMoveWorkout={onMoveWorkout}
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </Box>
    </motion.div>
  );
};

const IndividualWorkout = ({ workout, color, isPast, isMobile, onMoveWorkout }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'INDIVIDUAL_WORKOUT',
    item: { workout },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <Box
      ref={drag}
      sx={{
        marginLeft: '8px',
        backgroundColor: `${color}11`,
        padding: '1px 2px',
        borderRadius: '2px',
        marginBottom: '1px',
        border: `1px solid ${color}33`,
        cursor: 'move',
        opacity: isPast ? 0.6 : isDragging ? 0.7 : 1,
      }}
    >
      <Typography 
        variant="caption" 
        sx={{ 
          display: 'block', 
          overflow: 'hidden',
          fontWeight: 500,
        }}
      >
        {workout.athlete.name}
      </Typography>
    </Box>
  );
};

const Day = ({ day, currentMonth, optionalDay, onClick, data, sx, isBulkSelected, onMoveWorkout, expanded, isLoading }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isToday = day.isSame(dayjs(), 'day');
  const isPast = day.isBefore(dayjs(), 'day');

  const [{ isOver }, drop] = useDrop(() => ({
    accept: ['WORKOUT_GROUP', 'INDIVIDUAL_WORKOUT'],
    drop: (item) => onMoveWorkout(day, item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const workoutColors = useMemo(() => {
    if (!data || !data.athleteWorkout) return {};
    return data.athleteWorkout.reduce((acc, workout) => {
      if (workout.workoutData && workout.workoutData.name) {
        acc[workout.workoutData.name] = generateColor(workout.workoutData.name);
      }
      return acc;
    }, {});
  }, [data]);

  const renderModelCounts = () => {
    if (isLoading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress size={20} />
        </Box>
      );
    }

    if (!data) return null;

    return Object.keys(data).map((modelName) => {
      if (data[modelName].length === 0) return null;

      if (modelName === 'athleteWorkout') {
        const workoutGroups = data[modelName].reduce((acc, workout) => {
          const name = workout.workoutData && workout.workoutData.name;
          if (!acc[name]) acc[name] = [];
          acc[name].push(workout);
          return acc;
        }, {});

        return (
          <>
            {Object.entries(workoutGroups).map(([workoutName, workouts]) => (
              <WorkoutGroup
                key={workoutName}
                workoutName={workoutName}
                workouts={workouts}
                color={workoutColors[workoutName]}
                isPast={isPast}
                isMobile={isMobile}
                onMoveWorkout={onMoveWorkout}
                expanded={expanded}
              />
            ))}
          </>
        );
      }

      return (
        <Typography 
          key={modelName} 
          variant="caption" 
          sx={(theme) => ({ 
            display: 'block', 
            padding: '2px', 
            borderRadius: '2px', 
            fontSize: isMobile ? '0.5rem' : '0.75rem',
            opacity: isPast ? 0.5 : 1,
          })}
        >
          {modelName}: {data[modelName].length}
        </Typography>
      );
    });
  };

  return (
    <Box
      ref={drop}
      onClick={onClick}
      sx={{
        minHeight: isMobile ? '100px' : '140px',
        position: 'relative',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
        backgroundColor: isOver
          ? 'rgba(0, 255, 0, 0.1)'
          : isBulkSelected 
            ? 'rgba(0, 0, 0, 0.2)' 
            : isToday 
              ? theme.palette.background.paperSecondary
              : 'inherit',
        ...sx
      }}
    >
      {day.month() === currentMonth.month() && (
        isMobile ? (
          <Typography
            variant="h6"
            sx={{
              position: 'absolute',
              top: 2,
              left: 2,
              fontSize: '1rem',
              color: isToday ? theme.palette.primary.main : isPast ? theme.palette.text.secondary : theme.palette.text.primary,
            }}
          >
            {day.format('D')}
          </Typography>
        ) : (
          <Chip 
            label={day.format('D')}
            color={isToday ? 'primary' : 'default'}
            size="small"
            sx={{ 
              position: 'absolute', 
              top: 8, 
              left: 8,
              color: isToday ? theme.palette.primary.contrastText : isPast ? theme.palette.text.secondary : theme.palette.text.primary,
              backgroundColor: isToday ? theme.palette.primary.main : 'inherit',
            }}
          />
        )
      )}
      <Box sx={{ mt: isMobile ? 6 : 8, p: isMobile ? 0.5 : 1 }}>
        {renderModelCounts()}
      </Box>
    </Box>
  );
};

export { Day };
import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { Routes, Route, Navigate } from 'react-router-dom';
import PageWrapper from '../pageWrapper/pageWrapper';
import WorkoutsSection from './_components/workoutsSection/workoutsSection';
import CalanderTraining from './_components/trainingPlanSection/calanderTraining';
import { AuthContext } from '../../context/authContext';

const getTabs = (userType) => {
  const tabs = [
    { label: "Plan", path: "schedule" },
  ];

  if (userType === 'coach') {
    tabs.push({ label: "Workouts", path: "workouts" });
  }

  return tabs;
};

function TrainingPlan() {
  const { userType } = useContext(AuthContext);
  const tabs = getTabs(userType);

  return (
    <PageWrapper title="Training Plan" tabs={tabs} rootUrl="/training-plan">
        <Box sx={{ padding: 2 }}>
          <Routes>
            <Route index element={<Navigate to="schedule" replace />} />
            <Route path="schedule" element={
              <>
                <CalanderTraining />
              </>
            } />
            {userType === 'coach' && (
              <Route path="workouts" element={
                <>
                  <WorkoutsSection />
                </>
              } />
            )}
          </Routes>
        </Box>
    </PageWrapper>
  );
}

export default TrainingPlan;

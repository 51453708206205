import React, { useState, useEffect } from "react";
import {
  TextField,
  Stack,
  Divider,
  Chip,
  Collapse,
  Typography,
} from "@mui/material";
import { TimeInputFields, DistanceUnitToggle } from "./common";
import ViewModal from "../../../../components/viewModal/viewModal";

export const TimeCalc = ({ open, onClose }) => {
  const [raceDistance, setRaceDistance] = useState("");
  const [raceDistanceUnit, setRaceDistanceUnit] = useState("meters");
  const [splitDistance, setSplitDistance] = useState("");
  const [splitDistanceUnit, setSplitDistanceUnit] = useState("meters");
  const [splitTimeHours, setSplitTimeHours] = useState("");
  const [splitTimeMinutes, setSplitTimeMinutes] = useState("");
  const [splitTimeSeconds, setSplitTimeSeconds] = useState("");
  const [raceTime, setRaceTime] = useState("");
  const [showRaceTime, setShowRaceTime] = useState(false);

  const convertDistance = (value, fromUnit, toUnit) => {
    if (fromUnit === toUnit) return value;
    const conversionFactors = {
      miles: {
        meters: 1609.34,
        kilometers: 1.60934,
      },
      meters: {
        miles: 1 / 1609.34,
        kilometers: 1 / 1000,
      },
      kilometers: {
        meters: 1000,
        miles: 0.621371,
      },
    };
    return value * (conversionFactors[fromUnit][toUnit] || 1);
  };

  useEffect(() => {
    const calculateRaceTime = () => {
      const splitTimeInSeconds =
        (parseInt(splitTimeHours) * 3600 || 0) +
        (parseInt(splitTimeMinutes) * 60 || 0) +
        (parseInt(splitTimeSeconds) || 0);
      const splitDistanceInMeters = convertDistance(
        splitDistance,
        splitDistanceUnit,
        "meters"
      );
      const raceDistanceInMeters = convertDistance(
        raceDistance,
        raceDistanceUnit,
        "meters"
      );

      if (
        splitTimeInSeconds > 0 &&
        splitDistanceInMeters > 0 &&
        raceDistanceInMeters > 0
      ) {
        const pacePerMeter = splitTimeInSeconds / splitDistanceInMeters;
        const raceTimeInSeconds = pacePerMeter * raceDistanceInMeters;
        let raceHours = Math.floor(raceTimeInSeconds / 3600);
        let raceMinutes = Math.floor((raceTimeInSeconds % 3600) / 60);
        let raceSeconds = Math.round(raceTimeInSeconds % 60);

        raceMinutes = raceMinutes < 10 ? "0" + raceMinutes : raceMinutes;
        raceSeconds = raceSeconds < 10 ? "0" + raceSeconds : raceSeconds;

        if (raceSeconds === "60") {
          raceSeconds = "00";
          raceMinutes = parseInt(raceMinutes) + 1;
          raceMinutes = raceMinutes < 10 ? "0" + raceMinutes : raceMinutes;
        }

        if (raceMinutes === "60") {
          raceMinutes = "00";
          raceHours += 1;
        }

        return `${raceHours}:${raceMinutes}:${raceSeconds}`;
      } else {
        return "";
      }
    };

    const newRaceTime = calculateRaceTime();
    if (newRaceTime) {
      setRaceTime(newRaceTime);
      setShowRaceTime(true);
    } else {
      setShowRaceTime(false);
    }
  }, [
    raceDistance,
    raceDistanceUnit,
    splitDistance,
    splitDistanceUnit,
    splitTimeHours,
    splitTimeMinutes,
    splitTimeSeconds,
  ]);

  const handleRaceDistanceChange = (e) => {
    setRaceDistance(e.target.value);
  };

  const handleRaceDistanceUnitChange = (newUnit) => {
    if (newUnit !== null) {
      const convertedDistance = convertDistance(
        raceDistance,
        raceDistanceUnit,
        newUnit
      );
      setRaceDistanceUnit(newUnit);
      setRaceDistance(convertedDistance.toString());
    }
  };

  const handleSplitDistanceChange = (e) => {
    setSplitDistance(e.target.value);
  };

  const handleSplitDistanceUnitChange = (newUnit) => {
    if (newUnit !== null) {
      const convertedDistance = convertDistance(
        splitDistance,
        splitDistanceUnit,
        newUnit
      );
      setSplitDistanceUnit(newUnit);
      setSplitDistance(convertedDistance.toString());
    }
  };

  return (
    <ViewModal open={open} onClose={onClose}>
      <Typography
        variant="h5"
        component="h2"
        sx={{ width: "100%", my: 2, fontWeight: "bold" }}
      >
        Race Time Calculator
      </Typography>
      <Stack direction="column" spacing={2}>
        <Divider
          sx={{ width: "100%", my: 2, fontWeight: "bold" }}
          component="div"
        >
          Race Distance
        </Divider>
        <Stack direction="row" spacing={2}>
          <TextField
            label="Race Distance"
            type="number"
            value={raceDistance}
            onChange={handleRaceDistanceChange}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <DistanceUnitToggle
            distanceUnit={raceDistanceUnit}
            setDistanceUnit={handleRaceDistanceUnitChange}
          />
        </Stack>
        <Divider
          sx={{ width: "100%", my: 2, fontWeight: "bold" }}
          component="div"
        >
          Split Distance & Time
        </Divider>
        <Stack direction="row" spacing={2}>
          <TextField
            label="Split Distance"
            type="number"
            value={splitDistance}
            onChange={handleSplitDistanceChange}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <DistanceUnitToggle
            distanceUnit={splitDistanceUnit}
            setDistanceUnit={handleSplitDistanceUnitChange}
          />
        </Stack>
        <TimeInputFields
          hours={splitTimeHours}
          setHours={setSplitTimeHours}
          minutes={splitTimeMinutes}
          setMinutes={setSplitTimeMinutes}
          seconds={splitTimeSeconds}
          setSeconds={setSplitTimeSeconds}
        />
      </Stack>
      <Collapse in={showRaceTime}>
        <Chip label={`Race Time: ${raceTime}`} />
      </Collapse>
    </ViewModal>
  );
};

import React from 'react';
import { Paper, Typography, Grid, Box, useTheme } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import SyncIcon from '@mui/icons-material/Sync';

const TffrsSection = () => {
  const theme = useTheme();

  return (
    <Paper 
      elevation={3} 
      sx={{ 
        p: 4, 
        mb: 3, 
        borderRadius: theme.shape.borderRadius,
        background: theme.palette.background.paper,
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.primary.main, mb: 3 }}>
        Streamline Your Athlete Management
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box sx={{ 
            p: 3, 
            borderRadius: theme.shape.borderRadius,
            background: theme.palette.background.default,
            transition: 'transform 0.3s ease-in-out',
            '&:hover': {
              transform: 'translateY(-5px)',
            }
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <DownloadIcon sx={{ fontSize: 40, color: theme.palette.secondary.main, mr: 2 }} />
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                Import from TFFRS
              </Typography>
            </Box>
            <Typography variant="body1">
              Effortlessly import athlete data with a single click. Update rosters, add new athletes, and sync personal records seamlessly.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ 
            p: 3, 
            borderRadius: theme.shape.borderRadius,
            background: theme.palette.background.default,
            transition: 'transform 0.3s ease-in-out',
            '&:hover': {
              transform: 'translateY(-5px)',
            }
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <SyncIcon sx={{ fontSize: 40, color: theme.palette.primary.main, mr: 2 }} />
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                Sync TFFRS PRs
              </Typography>
            </Box>
            <Typography variant="body1">
              Keep your athlete data up-to-date with ease. Our sync feature allows you to import the latest performances from TFFRS with just a press of a button, ensuring you have the most current information at your fingertips.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export { TffrsSection };
import React, { useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';

const NumberTicker = ({ value, direction = 'up', delay = 0, className, decimalPlaces = 0 }) => {
  const ref = useRef(null);
  const { ref: inViewRef, inView } = useInView({ triggerOnce: true, threshold: 0 });
  const springProps = useSpring({
    from: { number: direction === 'down' ? value : 0 },
    to: { number: direction === 'down' ? 0 : value },
    delay: inView ? delay * 1000 : 0,
    config: { tension: 100, friction: 60 },
    reset: inView,
  });

  return (
    <animated.span
      className={`inline-block tabular-nums text-black dark:text-white tracking-wider ${className}`}
      ref={node => {
        ref.current = node;
        inViewRef(node);
      }}
    >
      {springProps.number.to(n => n.toFixed(decimalPlaces))}
    </animated.span>
  );
};

export { NumberTicker };

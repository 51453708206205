import React, { useContext } from 'react';
import { Typography } from '@mui/material';
import { AuthContext } from '../../../../context/authContext';
import { ComponentWrapper } from '../../../../components/utils';

const Motivation = () => {
  const { user } = useContext(AuthContext);

  const greetings = [
    { timeWindow: [0, 4], messages: [
      (name) => `Burning the midnight oil, ${name}? Remember, rest is crucial for peak performance.`,
      (name) => `${name}, it's late! A good night's sleep is key to athletic success.`,
      (name) => `Night owl ${name}? Don't forget, recovery is as important as training.`,
      (name) => `${name}, your dedication is admirable, but don't forget to recharge for tomorrow.`,
      (name) => `Midnight musings, ${name}? Channel that energy into tomorrow's workout.`,
      (name) => `${name}, even champions need their beauty sleep. Time to hit the hay!`,
      (name) => `Still up, ${name}? Remember, sleep is when the magic of recovery happens.`,
      (name) => `${name}, tomorrow's victory is built on tonight's rest. Sweet dreams!`,
      (name) => `Burning bright at night, ${name}? Save some fire for tomorrow's challenges.`,
      (name) => `${name}, the path to greatness includes a good night's sleep. Time to rest up!`
    ]},
    { timeWindow: [4, 6], messages: [
      (name) => `Early bird ${name}! Your dedication is admirable. Let's make today count.`,
      (name) => `Rise and grind, ${name}! Your competitors are still sleeping.`,
      (name) => `${name}, you're up with the sun! That's the spirit of a champion.`,
      (name) => `Dawn breaker ${name}! Your commitment is what sets you apart.`,
      (name) => `${name}, the early start gives you an edge. Make it count!`,
      (name) => `Impressive, ${name}! Your early rise is the first victory of the day.`,
      (name) => `${name}, you're already ahead of the game. Keep that momentum!`,
      (name) => `Morning champion ${name}! Your day is full of potential.`,
      (name) => `${name}, your early start is laying the foundation for success.`,
      (name) => `First light, first win, ${name}! Let's make today extraordinary.`
    ]},
    { timeWindow: [6, 9], messages: [
      (name) => `Good morning, ${name}! Ready to conquer the day?`,
      (name) => `Rise and shine, ${name}! It's time to chase your goals.`,
      (name) => `Hello, ${name}! Your potential is endless today.`,
      (name) => `Morning, ${name}! Let's kick-start the day with positivity and purpose.`,
      (name) => `${name}, the early athlete catches the win! Let's get moving.`,
      (name) => `A new day, a new opportunity, ${name}! Make it count.`,
      (name) => `${name}, your morning motivation sets the tone for the day. Let's make it great!`,
      (name) => `Good morning, ${name}! Today's efforts are tomorrow's results.`,
      (name) => `Rise and conquer, ${name}! Your journey to greatness continues today.`,
      (name) => `${name}, each morning is a fresh start. What will you achieve today?`
    ]},
    { timeWindow: [9, 12], messages: [
      (name) => `Mid-morning energy, ${name}! Keep that momentum going!`,
      (name) => `${name}, you're in the groove! Make every moment count.`,
      (name) => `Crushing it, ${name}! Your dedication is inspiring.`,
      (name) => `${name}, you're making strides! Keep pushing your limits.`,
      (name) => `Focus and determination, ${name}! That's what champions are made of.`,
      (name) => `${name}, your mid-morning hustle is setting you apart. Keep it up!`,
      (name) => `Riding the productivity wave, ${name}? Surf it to success!`,
      (name) => `${name}, your consistent effort is the key to extraordinary results.`,
      (name) => `Keep the fire burning, ${name}! Your mid-morning grind is shaping your future.`,
      (name) => `${name}, every rep, every step, every moment counts. Make them all matter!`
    ]},
    { timeWindow: [12, 14], messages: [
      (name) => `Lunchtime, ${name}! Fuel up for an amazing afternoon.`,
      (name) => `${name}, take a breather and recharge. The day is young!`,
      (name) => `Midday check-in, ${name}! How are you progressing towards your goals?`,
      (name) => `${name}, lunch break is a perfect time to reflect on your morning wins!`,
      (name) => `Refuel and refocus, ${name}! The best is yet to come.`,
      (name) => `${name}, use this break to energize your body and mind for the afternoon ahead.`,
      (name) => `Halfway through the day, ${name}! Time to double down on your goals.`,
      (name) => `${name}, lunch isn't just for eating. It's for plotting your afternoon domination!`,
      (name) => `Recharge those batteries, ${name}! The afternoon is full of opportunities.`,
      (name) => `${name}, use this midday pause to visualize your afternoon success!`
    ]},
    { timeWindow: [14, 17], messages: [
      (name) => `Good afternoon, ${name}! Keep that momentum going!`,
      (name) => `${name}, you're doing great! Keep pushing forward.`,
      (name) => `Hey ${name}! Remember, every step counts towards your goal.`,
      (name) => `Afternoon hustle, ${name}! Your dedication is paying off.`,
      (name) => `${name}, you're in the home stretch of the day. Finish strong!`,
      (name) => `${name}, the afternoon is young and full of potential. What will you achieve?`,
      (name) => `Pushing through the afternoon, ${name}? That's what separates good from great!`,
      (name) => `${name}, your afternoon efforts are building your legacy. Make them count!`,
      (name) => `Afternoon slump? Not for you, ${name}! You're just getting started!`,
      (name) => `${name}, the fire of your morning hasn't faded. Let it blaze through the afternoon!`
    ]},
    { timeWindow: [17, 20], messages: [
      (name) => `Evening approaches, ${name}! Time to reflect on your day's achievements.`,
      (name) => `Hi ${name}! Ready to finish the day strong?`,
      (name) => `${name}, you're making progress every day. Be proud!`,
      (name) => `Winding down, ${name}? Take a moment to appreciate your efforts today.`,
      (name) => `${name}, evening reflection time! What was your biggest win today?`,
      (name) => `${name}, the day may be ending, but your journey continues. What's next?`,
      (name) => `Evening check-in, ${name}! How did you move closer to your goals today?`,
      (name) => `${name}, use this evening to set yourself up for tomorrow's success.`,
      (name) => `As the day winds down, ${name}, remember: consistency is key to greatness.`,
      (name) => `${name}, evening is for planning. What will you conquer tomorrow?`
    ]},
    { timeWindow: [20, 24], messages: [
      (name) => `Good evening, ${name}! Time to relax and recharge for tomorrow.`,
      (name) => `${name}, great job today! Now it's time for some well-deserved rest.`,
      (name) => `Evening, ${name}! Remember, recovery is just as important as training.`,
      (name) => `Winding down, ${name}? A good night's sleep is your secret weapon.`,
      (name) => `${name}, reflect on your day's victories and prepare for tomorrow's challenges.`,
      (name) => `${name}, your evening routine sets the stage for tomorrow's success.`,
      (name) => `Night-time, ${name}. Let your body rest while your dreams soar!`,
      (name) => `${name}, tomorrow's champion is born in tonight's preparation.`,
      (name) => `Evening wind-down, ${name}. Celebrate today's wins, but hunger for more tomorrow.`,
      (name) => `${name}, use this quiet evening to visualize tomorrow's triumphs.`
    ]}
  ];

  const getGreeting = () => {
    const currentHour = new Date().getHours();
    const name = user?.firstName || 'Athlete';
    
    const { messages } = greetings.find(({ timeWindow }) => 
      currentHour >= timeWindow[0] && currentHour < timeWindow[1]
    ) || greetings[0];

    const randomIndex = Math.floor(Math.random() * messages.length);
    return messages[randomIndex](name);
  };

  return (
    <ComponentWrapper sx={{ padding: 3 }}>
      <Typography variant="h4">{getGreeting()}</Typography>
    </ComponentWrapper>
  );
};

export { Motivation };
import React from 'react';
import { Box } from '@mui/material';

const ButtonWrapper = ({ children, sx }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="stretch"
      justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
      width="100%"
      sx={{
        '& > *': {
          flex: { xs: '1 1 auto', sm: '0 0 15%' },
          mb: { xs: 1, sm: 0 },
          mr: { xs: 1, sm: 1 },
          height: { xs: '40px', sm: 'auto' }, // Ensures same height for mobile
          '&:last-child': {
            mb: 0,
            mr: 0,
          },
        },
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default ButtonWrapper;

import React from "react";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ViewDayIcon from "@mui/icons-material/ViewDay";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";

const DesktopNav = ({
  isSideNavCollapsed,
  toggleSideNav,
  isBulkSelectMode,
  handleBulkSelectToggle,
  handleViewAsClick,
  handleViewAsEventGroupClick,
  handleCreateWorkout,
  handleExpandToggle,
  expanded,
  theme,
}) => {
  const SideNavContent = () => (
    <List>
      <Tooltip
        title={
          isSideNavCollapsed
            ? isBulkSelectMode
              ? "Disable Bulk Create"
              : "Enable Bulk Create"
            : ""
        }
        placement="left"
      >
        <ListItem button onClick={handleBulkSelectToggle}>
          <ListItemIcon sx={{ minWidth: 40, color: "white" }}>
            <PlaylistAddIcon />
          </ListItemIcon>
          {!isSideNavCollapsed && (
            <ListItemText
              primary={
                <Typography noWrap variant="caption" sx={{ color: "white" }}>
                  {isBulkSelectMode
                    ? "Disable Bulk Create"
                    : "Enable Bulk Create"}
                </Typography>
              }
            />
          )}
        </ListItem>
      </Tooltip>
      <Tooltip
        title={isSideNavCollapsed ? "View as athlete" : ""}
        placement="left"
      >
        <ListItem button onClick={handleViewAsClick}>
          <ListItemIcon sx={{ minWidth: 40, color: "white" }}>
            <PersonIcon />
          </ListItemIcon>
          {!isSideNavCollapsed && (
            <ListItemText
              primary={
                <Typography noWrap variant="caption" sx={{ color: "white" }}>
                  View as athlete
                </Typography>
              }
            />
          )}
        </ListItem>
      </Tooltip>
      <Tooltip
        title={isSideNavCollapsed ? "View as Event Group" : ""}
        placement="left"
      >
        <ListItem button onClick={handleViewAsEventGroupClick}>
          <ListItemIcon sx={{ minWidth: 40, color: "white" }}>
            <GroupsIcon />
          </ListItemIcon>
          {!isSideNavCollapsed && (
            <ListItemText
              primary={
                <Typography noWrap variant="caption" sx={{ color: "white" }}>
                  View as Event Group
                </Typography>
              }
            />
          )}
        </ListItem>
      </Tooltip>
      {isBulkSelectMode && (
        <Tooltip
          title={isSideNavCollapsed ? "Create Workout" : ""}
          placement="left"
        >
          <ListItem button onClick={handleCreateWorkout}>
            <ListItemIcon sx={{ minWidth: 40, color: "white" }}>
              <AddCircleOutlineIcon />
            </ListItemIcon>
            {!isSideNavCollapsed && (
              <ListItemText
                primary={
                  <Typography noWrap variant="caption" sx={{ color: "white" }}>
                    Create Workout
                  </Typography>
                }
              />
            )}
          </ListItem>
        </Tooltip>
      )}
      <Tooltip
        title={
          isSideNavCollapsed
            ? expanded
              ? "Collapse Day View"
              : "Expand Day View"
            : ""
        }
        placement="left"
      >
        <ListItem button onClick={handleExpandToggle}>
          <ListItemIcon sx={{ minWidth: 40, color: "white" }}>
            {expanded ? <ViewAgendaIcon /> : <ViewDayIcon />}
          </ListItemIcon>
          {!isSideNavCollapsed && (
            <ListItemText
              primary={
                <Typography noWrap variant="caption" sx={{ color: "white" }}>
                  {expanded ? "Collapse Day View" : "Expand Day View"}
                </Typography>
              }
            />
          )}
        </ListItem>
      </Tooltip>
    </List>
  );

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      PaperProps={{
        sx: {
          width: isSideNavCollapsed ? 64 : 180,
          flexShrink: 0,
          border: "none",
          borderLeft: (theme) => `1px solid ${theme.palette.border.main}`,
          backgroundColor: (t) => `${t.palette.background.dark}`,
          transition: "width 0.3s",
          overflowX: "hidden",
        },
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1 }}>
        <Tooltip
          title={isSideNavCollapsed ? "Expand menu" : "Collapse menu"}
          placement="left"
        >
          <IconButton onClick={toggleSideNav} sx={{ color: "white" }}>
            {isSideNavCollapsed ? <MenuOpenIcon /> : <ChevronRightIcon />}
          </IconButton>
        </Tooltip>
      </Box>
      <SideNavContent />
    </Drawer>
  );
};

export default DesktopNav;

import React, { useState, useContext } from "react";
import {
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import ComponentWrapper from "../../../components/utils/wrappers/componentWrapper";
import { AuthContext } from "../../../context/authContext";
import useApiService from "../../../api/apiService";
import { useTheme } from "@mui/material/styles";
import { useParams, useLocation } from "react-router-dom";
import Form from "../../../components/form/form";
import AreYouSure from "../../../components/utils/areYouSure/areYouSure";

const Comment = ({ comment, onCommentUpdated, onCommentDeleted }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const { useUpdateComment, useDeleteComment } = useApiService();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { bulletinId } = useParams();
  const location = useLocation();

  const currentBulletinId =
    bulletinId ||
    (isNaN(location.pathname.split("/").pop())
      ? null
      : location.pathname.split("/").pop());

  const updateCommentMutation = useUpdateComment(
    currentBulletinId,
    comment.postId
  );
  const deleteCommentMutation = useDeleteComment(
    currentBulletinId,
    comment.postId
  );

  const formatDistanceToNow = (createdAt) => {
    const diffInSeconds = Math.floor((new Date() - new Date(createdAt)) / 1000);
    if (diffInSeconds < 60) return "just now";
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h`;
    return `${Math.floor(diffInSeconds / 86400)}d`;
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    setEditFormOpen(true);
    handleMenuClose();
  };

  const handleEditFormClose = () => {
    setEditFormOpen(false);
  };

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
    handleMenuClose();
  };

  const handleDeleteConfirm = async () => {
    try {
      if (comment.id && currentBulletinId && comment.postId) {
        await deleteCommentMutation.mutateAsync({ id: comment.id });
        onCommentDeleted(comment.id);
      } else {
        console.error("Missing required IDs for comment deletion");
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const handleEditSubmit = async (submittedData) => {
    try {
      if (comment.id && currentBulletinId && comment.postId) {
        const response = await updateCommentMutation.mutateAsync({
          id: comment.id,
          data: submittedData,
        });
        onCommentUpdated(response.data);
        handleEditFormClose();
      } else {
        console.error("Missing required IDs for comment update");
      }
    } catch (error) {
      console.error("Error updating comment:", error);
    }
  };

  const isAuthor =
    user.firstName === comment.author.firstName &&
    user.lastName === comment.author.lastName;

  const editFields = [
    { name: "content", label: "Comment", type: "textarea", required: true },
  ];

  return (
    <ComponentWrapper
      sx={{ textAlign: "left", mb: 1, py: 1, px: isMobile ? 2 : 3 }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box>
          <Typography
            variant={isMobile ? "body2" : "subtitle2"}
            component="span"
          >
            {isAuthor
              ? "You"
              : `${comment.author.firstName} ${comment.author.lastName}`}
          </Typography>
          <Typography
            variant="caption"
            color="text.secondary"
            component="span"
            sx={{ ml: 1 }}
          >
            {formatDistanceToNow(comment.createdAt)}
          </Typography>
        </Box>
        {isAuthor && (
          <IconButton size="small" onClick={handleMenuOpen}>
            <MoreVert fontSize="small" />
          </IconButton>
        )}
      </Box>
      <Typography
        variant={isMobile ? "body2" : "body1"}
        sx={{ mt: 0.5, whiteSpace: "pre-wrap" }}
      >
        {comment.content}
      </Typography>
      {isAuthor && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleEditClick}>Edit</MenuItem>
          <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
        </Menu>
      )}
      <Form
        open={editFormOpen}
        onClose={handleEditFormClose}
        fields={editFields}
        onSubmit={handleEditSubmit}
        title="Edit Comment"
        defaultValues={comment}
      />
      <AreYouSure
        isOpen={deleteDialogOpen}
        handleClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleDeleteConfirm}
        itemName="this comment"
      />
    </ComponentWrapper>
  );
};

export default Comment;

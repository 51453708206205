import React, { useState, useEffect } from 'react';
import {
  SwipeableDrawer,
  Button,
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  TextField,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { motion } from 'framer-motion';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
}));

const MobileForm = ({
  open,
  onClose,
  fields,
  onSubmit,
  title,
  defaultValues = {},
  data = {},
  sx = {},
  noSubmitButton = false,
}) => {
  const theme = useTheme();
  const [formData, setFormData] = useState({ ...defaultValues, ...data });
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [filterTexts, setFilterTexts] = useState({});
  const [selectOpens, setSelectOpens] = useState({});

  useEffect(() => {
    setFormData({ ...defaultValues, ...data });
  }, [defaultValues, data]);

  const handleChange = (name, value) => {
    if (name === 'email') {
      value = value.toLowerCase();
    }
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
    onClose();
  };

  const toggleMoreOptions = () => {
    setShowMoreOptions(!showMoreOptions);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSelectOpen = (fieldName) => {
    setSelectOpens((prev) => ({ ...prev, [fieldName]: true }));
    setFilterTexts((prev) => ({ ...prev, [fieldName]: '' }));
  };

  const handleSelectClose = (fieldName) => {
    setSelectOpens((prev) => ({ ...prev, [fieldName]: false }));
  };

  const renderField = (field) => {
    if (field.conditional && !field.conditional(formData)) {
      return null;
    }

    if (field.hidden && field.hidden(formData)) {
      return null;
    }

    switch (field.type) {
      case 'text':
      case 'number':
      case 'email':
      case 'password':
      case 'date':
      case 'time':
        return (
          <StyledFormControl key={field.name} variant="filled">
            <TextField
              label={field.label}
              type={
                field.type === 'password'
                  ? showPassword
                    ? 'text'
                    : 'password'
                  : field.type
              }
              value={formData[field.name] || ''}
              onChange={(e) => handleChange(field.name, e.target.value)}
              variant="filled"
              fullWidth
              InputProps={{
                endAdornment:
                  field.type === 'password' ? (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={toggleShowPassword}
                        edge="end"
                        size="small"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ) : null,
              }}
            />
          </StyledFormControl>
        );
      case 'textarea':
        return (
          <StyledFormControl key={field.name} variant="filled">
            <TextField
              label={field.label}
              multiline
              rows={4}
              value={formData[field.name] || ''}
              onChange={(e) => handleChange(field.name, e.target.value)}
              variant="filled"
              fullWidth
              InputProps={{
                style: { whiteSpace: 'pre-wrap' },
              }}
            />
          </StyledFormControl>
        );
      case 'bool':
        return (
          <StyledFormControl key={field.name}>
            <Box display="flex" alignItems="center">
              <Checkbox
                checked={formData[field.name] || false}
                onChange={(e) => handleChange(field.name, e.target.checked)}
                size="medium"
              />
              <Typography variant="body1">{field.label}</Typography>
            </Box>
          </StyledFormControl>
        );
      case 'singleSelect':
      case 'multiSelect':
        return (
          <StyledFormControl key={field.name} variant="filled">
            <InputLabel>{field.label}</InputLabel>
            <Select
              multiple={field.type === 'multiSelect'}
              value={
                field.type === 'multiSelect'
                  ? formData[field.name] || []
                  : formData[field.name] || ''
              }
              onChange={(e) => handleChange(field.name, e.target.value)}
              renderValue={(selected) => {
                if (Array.isArray(selected)) {
                  return selected
                    .map(
                      (value) =>
                        field.options.find((option) => option.value === value)
                          ?.label
                    )
                    .join(', ');
                } else {
                  return (
                    field.options.find((option) => option.value === selected)
                      ?.label || ''
                  );
                }
              }}
              open={selectOpens[field.name]}
              onOpen={() => handleSelectOpen(field.name)}
              onClose={() => handleSelectClose(field.name)}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: 250,
                  },
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                disableScrollLock: true,
                disableAutoFocusItem: true,
              }}
            >
              {field.options.length > 10 && (
                <MenuItem
                  disableRipple
                  style={{
                    position: 'sticky',
                    top: 0,
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 1,
                  }}
                >
                  <TextField
                    size="small"
                    placeholder="Filter options..."
                    value={filterTexts[field.name] || ''}
                    onChange={(e) => {
                      e.stopPropagation();
                      setFilterTexts((prev) => ({
                        ...prev,
                        [field.name]: e.target.value,
                      }));
                    }}
                    onClick={(e) => e.stopPropagation()}
                    onKeyDown={(e) => {
                      if (e.key !== 'Escape') {
                        e.stopPropagation();
                      }
                    }}
                    fullWidth
                    autoFocus
                  />
                </MenuItem>
              )}
              {field.options
                .filter((option) =>
                  option.label
                    .toLowerCase()
                    .includes((filterTexts[field.name] || '').toLowerCase())
                )
                .map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {field.type === 'multiSelect' && (
                      <Checkbox
                        checked={
                          (formData[field.name] || []).indexOf(option.value) >
                          -1
                        }
                        size="small"
                      />
                    )}
                    <ListItemText
                      primary={option.label}
                      primaryTypographyProps={{ variant: 'body2' }}
                    />
                  </MenuItem>
                ))}
            </Select>
          </StyledFormControl>
        );
      case 'custom':
        return (
          <Box key={field.name} width="100%">
            {field.render(formData, handleChange)}
          </Box>
        );
      default:
        return null;
    }
  };

  const optionalFields = fields.filter((field) => field.optional);

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      PaperProps={{
        style: { width: '100%', borderRadius: '16px 16px 0 0', ...sx },
      }}
      ModalProps={{
        keepMounted: false,
      }}
      disableSwipeToOpen={true}
      swipeAreaWidth={0}
    >
      <Box
        sx={{
          p: 2,
          pb: 4,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          position: 'relative',
        }}
      >
        {/* Pull Indicator */}
        <Box
          sx={{
            width: '40px',
            height: '4px',
            backgroundColor: theme.palette.grey[400],
            borderRadius: '2px',
            mx: 'auto',
            mb: 2,
          }}
        />

        <Typography variant="h6" align="center" sx={{ mb: 2 }}>
          {title}
        </Typography>

        <form onSubmit={handleSubmit} style={{ overflowY: 'auto', flexGrow: 1 }}>
          <Stack spacing={2}>
            {fields
              .filter((field) => !field.optional)
              .map((field) => renderField(field))}

            {optionalFields.length > 0 && (
              <>
                <Button
                  onClick={toggleMoreOptions}
                  endIcon={
                    <motion.div
                      animate={{ rotate: showMoreOptions ? 180 : 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <ExpandMoreIcon />
                    </motion.div>
                  }
                  fullWidth
                  variant="text"
                  sx={{
                    mt: 2,
                    justifyContent: 'space-between',
                    fontWeight: 'bold',
                  }}
                >
                  {showMoreOptions ? 'Less Options' : 'More Options'}
                </Button>
                <motion.div
                  initial={false}
                  animate={{ height: showMoreOptions ? 'auto' : 0 }}
                  style={{ overflow: 'hidden' }}
                  transition={{ duration: 0.5 }}
                >
                  <Stack spacing={2} sx={{ mt: 2 }}>
                    {optionalFields.map((field) => renderField(field))}
                  </Stack>
                </motion.div>
              </>
            )}
          </Stack>
          {!noSubmitButton && (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              sx={{ mt: 3 }}
            >
              Submit
            </Button>
          )}
        </form>
      </Box>
    </SwipeableDrawer>
  );
};

export default MobileForm;
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  useTheme,
  Container,
  Grid,
  Paper,
  Fade,
  Chip,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { motion, AnimatePresence } from 'framer-motion';

const features = [
  {
    image: 'https://imgur.com/MvEEzO1.png',
    title: 'Athlete Management',
    description: 'Streamline your team organization with our powerful athlete management tools.',
    bullets: [
      'Effortlessly manage team rosters',
      'Seamless TFFRS integration',
      'Dynamic workout calculations tailored to each athlete',
    ],
  },
  {
    image: 'https://imgur.com/oi6gDWD.png',
    title: 'Event Groups',
    description: 'Optimize your training strategy with specialized event-based organization.',
    bullets: [
      'Organize athletes by event groups',
      'Tailor training plans for specific events',
      'Maximize team performance through strategic grouping',
    ],
  },
  {
    image: 'https://imgur.com/BkYfwGv.png',
    title: 'Workout Planning',
    description: 'Create and customize workouts with ease using our intuitive planning tools.',
    bullets: [
      'Design custom workout templates',
      'Automatically calculate paces for each athlete',
      'Categorize workouts for easy reference and planning',
    ],
  },
  {
    image: 'https://imgur.com/W7fzowg.png',
    title: 'Training Plans',
    description: 'Develop comprehensive training plans that adapt to your athletes\' needs.',
    bullets: [
      'Edit plans on-the-fly for maximum flexibility',
      'Auto-updating workouts based on athlete progress',
      'Allow athletes to view and track their personalized plans',
    ],
  },
];

export default function ExploreFeatures() {
  const [currentFeature, setCurrentFeature] = useState(0);
  const [loadedImages, setLoadedImages] = useState({});
  const theme = useTheme();

  useEffect(() => {
    features.forEach((feature) => {
      const img = new Image();
      img.src = feature.image;
      img.onload = () => {
        setLoadedImages((prev) => ({ ...prev, [feature.image]: true }));
      };
    });
  }, []);

  const handlePrev = () => {
    setCurrentFeature((prev) => (prev === 0 ? features.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrentFeature((prev) => (prev === features.length - 1 ? 0 : prev + 1));
  };

  const variants = {
    enter: (direction) => ({
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }),
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction) => ({
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    }),
  };

  return (
    <Box sx={{ bgcolor: 'background.default', py: { xs: 6, md: 10 } }}>
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          component="h2"
          align="center"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            mb: 6,
            background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Explore Our Powerful Features
        </Typography>
        <Paper 
          elevation={3} 
          sx={{ 
            position: 'relative', 
            overflow: 'hidden', 
            borderRadius: 4,
            bgcolor: theme.palette.background.paper,
          }}
        >
          <Box sx={{ position: 'relative', minHeight: { xs: '650px', md: '500px' } }}>
            <IconButton
              onClick={handlePrev}
              sx={{
                position: 'absolute',
                left: 16,
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 2,
                bgcolor: 'background.paper',
                boxShadow: 2,
                '&:hover': { bgcolor: 'background.default' },
              }}
            >
              <ArrowBackIosNewIcon />
            </IconButton>
            <AnimatePresence initial={false} custom={currentFeature}>
              <motion.div
                key={currentFeature}
                custom={currentFeature}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  x: { type: 'spring', stiffness: 300, damping: 30 },
                  opacity: { duration: 0.2 },
                }}
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                }}
              >
                <Grid container spacing={0} alignItems="center" sx={{ height: '100%' }}>
                  <Grid item xs={12} md={6} sx={{ p: { xs: 3, md: 5 } }}>
                    {loadedImages[features[currentFeature].image] ? (
                      <Fade in={true} timeout={500}>
                        <Box
                          component="img"
                          src={features[currentFeature].image}
                          alt={features[currentFeature].title}
                          sx={{
                            width: '100%',
                            maxHeight: { xs: '250px', md: '350px' },
                            objectFit: 'contain',
                            borderRadius: 2,
                            boxShadow: 3,
                          }}
                        />
                      </Fade>
                    ) : (
                      <Box
                        sx={{
                          width: '100%',
                          height: { xs: '250px', md: '350px' },
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          bgcolor: 'grey.100',
                          borderRadius: 2,
                        }}
                      >
                        <Typography>Loading image...</Typography>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ p: { xs: 3, md: 5 } }}>
                    <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold', color: 'primary.main' }}>
                      {features[currentFeature].title}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 4, color: 'text.secondary' }}>
                      {features[currentFeature].description}
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                      {features[currentFeature].bullets.map((bullet, idx) => (
                        <Fade key={idx} in={true} timeout={(idx + 1) * 300}>
                          <Chip
                            label={bullet}
                            sx={{
                              bgcolor: theme.palette.primary.light,
                              color: theme.palette.primary.contrastText,
                              '&:hover': {
                                bgcolor: theme.palette.primary.main,
                              },
                              transition: 'background-color 0.3s',
                              fontWeight: 500,
                            }}
                          />
                        </Fade>
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              </motion.div>
            </AnimatePresence>
            <IconButton
              onClick={handleNext}
              sx={{
                position: 'absolute',
                right: 16,
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 2,
                bgcolor: 'background.paper',
                boxShadow: 2,
                '&:hover': { bgcolor: 'background.default' },
              }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
}

export { ExploreFeatures };
import React, { useContext, useState } from "react";
import Form from "../form/form";
import useApiService from "../../api/apiService";
import { AuthContext } from "../../context/authContext";
import { uploadFile } from "../../api/amazonS3";
import { Button, Box } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const FormTeam = ({ open, handleClose, onTeamUpdated, team }) => {
  const { useUpdateTeam, useGeneratePresignedUrl } = useApiService();
  const updateTeamMutation = useUpdateTeam();
  const generatePresignedUrl = useGeneratePresignedUrl();
  const { updateTeam } = useContext(AuthContext);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file && file.size <= 1024 * 1024 * 5) { // 5MB limit
      setSelectedFile(file);
    } else {
      console.error("File too large - 5MB maximum");
    }
  };

  const handleSubmit = async (submittedData) => {
    try {
      const updateData = { ...submittedData };

      if (selectedFile) {
        // Step 1: Get presigned URL
        const presignedUrlResponse = await generatePresignedUrl.mutateAsync({
          fileType: selectedFile.type,
        });

        if (presignedUrlResponse?.data?.presignedUrl) {
          // Step 2: Upload to S3
          const uploadResponse = await uploadFile({
            file: selectedFile,
            presignedUrl: presignedUrlResponse.data.presignedUrl,
          });

          if (uploadResponse?.status === 200) {
            // Step 3: Add the logoUrl as the s3Key
            updateData.logoUrl = presignedUrlResponse.data.s3Key;
          }
        }
      }

      const response = await updateTeamMutation.mutateAsync({
        id: team.id,
        data: updateData,
      });

      updateTeam(response.data);
      onTeamUpdated(response.data);
      handleClose();
    } catch (error) {
      console.error("Error updating team:", error);
    }
  };

  const fields = [
    { name: 'name', label: 'Team Name', type: 'text', required: true },
    { 
      name: 'logoUrl',
      label: 'Team Logo',
      type: 'custom',
      render: (formData, handleChange) => (
        <Box sx={{ mt: 2 }}>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="logo-upload"
            type="file"
            onChange={handleFileSelect}
          />
          <label htmlFor="logo-upload">
            <Button
              variant="contained"
              component="span"
              startIcon={<CloudUploadIcon />}
            >
              Upload Team Logo
            </Button>
          </label>
          {selectedFile && (
            <Box sx={{ mt: 1 }}>
              Selected file: {selectedFile.name}
            </Box>
          )}
        </Box>
      )
    }
  ];

  return (
    <Form
      open={open}
      onClose={handleClose}
      fields={fields}
      onSubmit={handleSubmit}
      title="Edit Team"
      defaultValues={{ name: team?.name || "" }}
    />
  );
};

export default FormTeam;

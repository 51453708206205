import React, { useState, useEffect } from "react";
import { Slider, Stack, Chip, Typography, TextField } from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { DistanceUnitToggle } from "./common";
import ViewModal from "../../../../components/viewModal/viewModal";

export const MphToMiles = ({ open, onClose }) => {
  const [mph, setMph] = useState(12);
  const [distance, setDistance] = useState(1); // Default to 1 mile
  const [distanceUnit, setDistanceUnit] = useState("miles"); // Default unit
  const [minutesPerDistance, setMinutesPerDistance] = useState("");

  const convertDistance = (value, fromUnit, toUnit) => {
    const conversionFactors = {
      miles: {
        meters: 1609.34,
        kilometers: 1.60934,
      },
      meters: {
        miles: 1 / 1609.34,
        kilometers: 1 / 1000,
      },
      kilometers: {
        meters: 1000,
        miles: 0.621371,
      },
    };
    return value * (conversionFactors[fromUnit][toUnit] || 1);
  };

  useEffect(() => {
    const calculateTime = () => {
      let timeInMinutes;
      if (distanceUnit === "miles") {
        timeInMinutes = (60 / mph) * distance;
      } else if (distanceUnit === "kilometers") {
        const distanceInMiles = convertDistance(
          distance,
          "kilometers",
          "miles"
        );
        timeInMinutes = (60 / mph) * distanceInMiles;
      } else {
        const distanceInMiles = convertDistance(distance, "meters", "miles");
        timeInMinutes = (60 / mph) * distanceInMiles;
      }
      const wholeMinutes = Math.floor(timeInMinutes);
      const seconds = Math.round((timeInMinutes - wholeMinutes) * 60 * 10) / 10; // Round seconds to nearest tenth
      return `${wholeMinutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };
    setMinutesPerDistance(calculateTime());
  }, [mph, distance, distanceUnit]); // Added distanceUnit to dependencies array

  const handleSliderChange = (event, newValue) => {
    setMph(newValue);
  };

  const handleDistanceChange = (event) => {
    const newDistance = event.target.value;
    if (!newDistance) {
      setDistance("");
      return;
    }
    setDistance(newDistance);
  };

  const handleUnitChange = (newUnit) => {
    if (newUnit !== null) {
      const convertedDistance = convertDistance(
        distance,
        distanceUnit,
        newUnit
      );
      setDistanceUnit(newUnit);
      setDistance(convertedDistance);
    }
  };

  return (
    <ViewModal open={open} onClose={onClose}>
      <Typography
        variant="h5"
        component="h2"
        sx={{ width: "100%", my: 2, fontWeight: "bold" }}
      >
        MPH To Time
      </Typography>
      <Stack
        direction="column"
        spacing={2}
        sx={{ width: "100%", alignItems: "center" }}
      >
        <Stack
          direction="row"
          spacing={2}
          sx={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Chip label={`MPH: ${mph.toFixed(1)}`} variant="outlined" />
          <SwapHorizIcon />
          <Chip label={`Time: ${minutesPerDistance}`} variant="outlined" />
        </Stack>
        <Slider
          aria-label="Speed in MPH"
          defaultValue={0.1}
          valueLabelDisplay="off"
          step={0.1}
          min={0.1}
          max={25}
          onChange={handleSliderChange}
          value={typeof mph === "number" ? mph : 0}
        />
        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            label="Distance"
            type="number"
            value={distance}
            onChange={handleDistanceChange}
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: "100%" }}
          />
          <DistanceUnitToggle
            distanceUnit={distanceUnit}
            setDistanceUnit={handleUnitChange}
          />
        </Stack>
      </Stack>
    </ViewModal>
  );
};

import React, { useState } from "react";
import { ListItemText, Paper, Box, Grid } from "@mui/material";
import AIRep from "./aiRep";
import { SetForm } from "../../../pages/trainingPlan/_components/workoutsSection/forms/setForm";

const AISet = ({ set, setIndex, onEditSet, onEditRep }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleEditRep = (repIndex, editedRep) => {
    onEditRep(setIndex, repIndex, editedRep);
  };

  const handleOpenEditModal = () => {
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handleSaveSet = (editedSet) => {
    onEditSet(setIndex, editedSet);
    handleCloseEditModal();
  };

  const formatRecoveryTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    if (minutes === 0) {
      return `${seconds} seconds`;
    } else if (remainingSeconds === 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""}`;
    } else {
      return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
    }
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          borderRadius: 4,
          overflow: "hidden",
          mb: 2,
          border: (theme) => `1px solid ${theme.palette.border.main}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: (theme) => theme.palette.background.paperDark,
            cursor: "pointer",
          }}
          onClick={handleOpenEditModal}
        >
          <ListItemText
            sx={{ ml: 2 }}
            primary={`${set.repCount}x sets`}
            secondary={
              set.repCount > 1
                ? `Recovery: ${formatRecoveryTime(set.recovery)}`
                : null
            }
          />
        </Box>
        <Box sx={{ p: 2 }}>
          <Grid container spacing={1}>
            {set.reps.map((rep, repIndex) => (
              <Grid item xs={12} sm={6} md={4} key={repIndex}>
                <AIRep
                  rep={rep}
                  repIndex={repIndex}
                  onEditRep={handleEditRep}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Paper>
      <SetForm
        open={isEditModalOpen}
        onClose={handleCloseEditModal}
        onSave={handleSaveSet}
        initialSet={{
          reps: set.repCount,
          recovery: set.recovery,
        }}
      />
    </>
  );
};

export default AISet;

import React, { useState } from "react";
import { TextField, Stack, useMediaQuery, useTheme } from "@mui/material";

export const TimeInputFields = ({
  setHours,
  setMinutes,
  setSeconds,
  direction = "row",
}) => {
  const [time, setTime] = useState("");
  const [error, setError] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const validateAndSetTime = (inputTime) => {
    const timeParts = inputTime.split(":").map((part) => parseInt(part, 10));
    const isValidTime =
      timeParts.every((part) => !isNaN(part)) &&
      (timeParts.length === 1 ||
        timeParts.length === 2 ||
        timeParts.length === 3);

    if (!isValidTime) {
      setError(true);
      return;
    }

    setError(false);

    if (timeParts.length === 1) {
      setHours(0);
      setMinutes(0);
      setSeconds(timeParts[0]);
    } else if (timeParts.length === 2) {
      setHours(0);
      setMinutes(timeParts[0]);
      setSeconds(timeParts[1]);
    } else if (timeParts.length === 3) {
      setHours(timeParts[0]);
      setMinutes(timeParts[1]);
      setSeconds(timeParts[2]);
    }
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    const regex = /^[0-9:.]*$/; // Only numbers and colon are allowed

    if (regex.test(newValue)) {
      setTime(newValue);
      validateAndSetTime(newValue);
    }
  };

  return (
    <Stack direction={direction} spacing={2} alignItems="center">
      <TextField
        label="Time (HH:MM:SS, MM:SS or SS)"
        type="text"
        value={time}
        onChange={handleInputChange}
        placeholder="00:00:00"
        error={error}
        helperText={error ? "Enter time as HH:MM:SS, MM:SS, or SS" : ""}
        InputLabelProps={{
          shrink: true,
        }}
        FormHelperTextProps={{
          style: { fontSize: isMobile ? "0.3rem" : ".5rem" }, // Smaller font size on mobile
        }}
      />
    </Stack>
  );
};

import React, { useState, useContext } from "react";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Collapse,
  Button,
  List,
  Box,
  Menu,
  MenuItem,
  Chip,
} from "@mui/material";
import { Comment as CommentIcon, MoreVert } from "@mui/icons-material";
import useApiService from "../../../api/apiService";
import { useParams, useLocation } from "react-router-dom";
import Comment from "./comment";
import { AuthContext } from "../../../context/authContext";
import Form from "../../../components/form/form";
import AreYouSure from "../../../components/utils/areYouSure/areYouSure";

const Post = ({ post, onPostUpdated, onPostDeleted }) => {
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [commentFormOpen, setCommentFormOpen] = useState(false);
  const [showFullContent, setShowFullContent] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const { bulletinId } = useParams();
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const currentBulletinId =
    bulletinId ||
    (isNaN(location.pathname.split("/").pop())
      ? null
      : location.pathname.split("/").pop());

  const { useCreateComment, useComments, useUpdatePost, useDeletePost } =
    useApiService();

  const createCommentMutation = useCreateComment(currentBulletinId, post.id);
  const {
    data: comments,
    isLoading: isCommentsLoading,
    error: commentsError,
  } = useComments(currentBulletinId, post.id);
  const updatePostMutation = useUpdatePost(currentBulletinId);
  const deletePostMutation = useDeletePost(currentBulletinId);

  const formatDistanceToNow = (createdAt) => {
    const diffInSeconds = Math.floor((new Date() - new Date(createdAt)) / 1000);
    if (diffInSeconds < 60) return "just now";
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h`;
    return `${Math.floor(diffInSeconds / 86400)}d`;
  };

  const handleCommentSubmit = async (submittedData) => {
    try {
      await createCommentMutation.mutateAsync({
        content: submittedData.content,
      });
      setCommentFormOpen(false);
    } catch (error) {
      console.error("Error creating comment:", error);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    setEditFormOpen(true);
    handleMenuClose();
  };

  const handleEditFormClose = () => {
    setEditFormOpen(false);
  };

  const handleEditSubmit = async (submittedData) => {
    try {
      const response = await updatePostMutation.mutateAsync({
        id: post.id,
        data: submittedData,
      });
      onPostUpdated(response.data);
      handleEditFormClose();
    } catch (error) {
      console.error("Error updating post:", error);
    }
  };

  const handleDeleteClick = () => {
    setDeleteConfirmOpen(true);
    handleMenuClose();
  };

  const handleDeleteConfirm = async () => {
    try {
      await deletePostMutation.mutateAsync({ id: post.id });
      onPostDeleted(post.id);
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  const isAuthor =
    user.firstName === post.author.firstName &&
    user.lastName === post.author.lastName;

  const editFields = [
    { name: "title", label: "Title", type: "text", required: true },
    { name: "content", label: "Content", type: "textarea", required: true },
  ];

  const commentFields = [
    { name: "content", label: "Comment", type: "textarea", required: true },
  ];

  const truncateContent = (content, lines = 3) => {
    if (!content) return "";
    const words = content.split(" ");
    const truncated = words.slice(0, lines * 10).join(" ");
    return truncated + (words.length > lines * 10 ? "..." : "");
  };

  const toggleComments = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ mr: -2, ml: -2, mb: 2, borderRadius: 2 }}>
      <CardContent sx={{ p: 2, "&:last-child": { pb: 1 } }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: "bold",
              mb: 0.5,
              textAlign: "left",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "calc(100% - 40px)",
            }}
          >
            {post.title}
          </Typography>
          {isAuthor && (
            <IconButton size="small" onClick={handleMenuOpen}>
              <MoreVert fontSize="small" />
            </IconButton>
          )}
          {isAuthor && (
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleEditClick}>Edit</MenuItem>
              <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
            </Menu>
          )}
        </Box>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            mb: 0.5,
            fontSize: "0.8rem",
            textAlign: "left",
            whiteSpace: "pre-wrap",
          }}
        >
          {showFullContent ? post.content : truncateContent(post.content)}
          {post.content && post.content.split(" ").length > 30 && (
            <Button
              onClick={() => setShowFullContent(!showFullContent)}
              sx={{
                ml: 1,
                p: 0,
                minWidth: "auto",
                textTransform: "none",
                fontSize: "0.8rem",
              }}
            >
              {showFullContent ? "Read less" : "Read more"}
            </Button>
          )}
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={toggleComments}
          >
            <IconButton size="small">
              <CommentIcon fontSize="small" />
            </IconButton>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ ml: 0.5, fontSize: "0.7rem" }}
            >
              {comments ? comments.length : 0}{" "}
              {comments && comments.length === 1 ? "comment" : "comments"}
            </Typography>
            <Chip
              label="Comment"
              size="small"
              onClick={() => setCommentFormOpen(true)}
              sx={{ ml: 1, height: 20, fontSize: "0.7rem" }}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Chip
              label={
                isAuthor
                  ? "You"
                  : `${post.author.firstName} ${post.author.lastName}`
              }
              variant="outlined"
              size="small"
              sx={{ height: 20, fontSize: "0.7rem" }}
            />
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ fontSize: "0.7rem" }}
            >
              {formatDistanceToNow(post.createdAt)}
            </Typography>
          </Box>
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box mt={1}>
            {isCommentsLoading ? (
              <Typography variant="caption">Loading comments...</Typography>
            ) : commentsError ? (
              <Typography variant="caption" color="error">
                Error loading comments
              </Typography>
            ) : (
              <List disablePadding>
                {comments &&
                  comments.map((comment, index) => (
                    <Comment key={index} comment={comment} />
                  ))}
              </List>
            )}
            <Button
              onClick={() => setCommentFormOpen(true)}
              variant="contained"
              color="primary"
              size="small"
              sx={{ mt: 0.5 }}
            >
              Add Comment
            </Button>
          </Box>
        </Collapse>
      </CardContent>
      <Form
        open={editFormOpen}
        onClose={handleEditFormClose}
        fields={editFields}
        onSubmit={handleEditSubmit}
        title="Edit Post"
        defaultValues={post}
      />
      <Form
        open={commentFormOpen}
        onClose={() => setCommentFormOpen(false)}
        fields={commentFields}
        onSubmit={handleCommentSubmit}
        title="Add Comment"
      />
      <AreYouSure
        isOpen={deleteConfirmOpen}
        handleClose={() => setDeleteConfirmOpen(false)}
        onConfirm={handleDeleteConfirm}
        itemName="this post"
      />
    </Card>
  );
};

export default Post;

import { Box, useMediaQuery, useTheme, Typography } from "@mui/material";
import PageWrapper from "../pageWrapper/pageWrapper";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/authContext";
import {
  ComingSoon,
  CreateOrLogin,
  Counts,
  ExploreFeatures,
  UserCounts,
  Welcome,
  DownloadPWA,
  TodaysWorkouts,
} from "./_components";
import SubTiers from "./_components/subTiers";
import { CheckGoals } from "./_components/checkGoals/checkGoals";
import { TffrsSection } from "./_components/tffrs/tffrs";
import { Motivation } from "./_components/motivation";
import ViewModal from "../../components/viewModal/viewModal";
import Confetti from 'react-confetti';
import CancelIcon from '@mui/icons-material/Cancel';
import { useLocation, useNavigate } from 'react-router-dom';
import useApiService from "../../api/apiService";

function Home() {
  const { token, userType, updateTeam } = useContext(AuthContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { useTeam, queryClient } = useApiService();

  const { data: team } = useTeam();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sub = searchParams.get('sub');
    if (sub === 'success' || sub === 'cancel') {
      setModalOpen(true);
      setIsSuccess(sub === 'success');
      if (sub === 'success') {
        // Refetch team data
        queryClient.invalidateQueries(['team']);
        if (team && team.refetch) {
          team.refetch().then(({ data }) => {
            if (data) {
              updateTeam(data);
            }
          });
        }
      }
      // Remove the 'sub' query parameter from the URL
      navigate(location.pathname, { replace: true });
    }
  }, [location, navigate, updateTeam, team, queryClient]);

  const sections = !token
    ? [
        { component: Welcome },
        { component: Counts },
        { component: ExploreFeatures },
        { component: CreateOrLogin },
        { component: TffrsSection },
        { component: SubTiers },
        { component: ComingSoon },
      ]
    : [
        { component: DownloadPWA, visibleTo: ['coach', 'athlete'] },
        { component: Motivation, visibleTo: ['athlete'] },
        { component: TodaysWorkouts, visibleTo: ['athlete'] },
        { component: CheckGoals, visibleTo: ['coach', 'athlete'] },
        { component: UserCounts, visibleTo: ['coach'] },
        { component: ComingSoon, visibleTo: ['coach'] },
        { component: SubTiers, visibleTo: ['coach'] },
      ];

  const tabs = [];

  return (
    <PageWrapper
      rootUrl="/home"
      sx={{ mt: !token && !isMobile ? 10 : !isMobile ? -10 : 3 }}
      tabs={tabs}
      ttp={true}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          padding: isMobile ? 1 : 10,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: isMobile ? 5 : 10,
            flexGrow: 1,
          }}
        >
          {sections.map(({ component: Section, visibleTo }, index) => (
            (!token || (visibleTo && visibleTo.includes(userType))) && (
              <Box key={index}>
                <Section />
              </Box>
            )
          ))}
        </Box>
      </Box>
      <ViewModal open={modalOpen} onClose={() => setModalOpen(false)}>
        {isSuccess ? (
          <>
            <Confetti />
            <Box sx={{ textAlign: 'center', p: 3 }}>
              <Typography variant="h4" gutterBottom>Subscription Successful!</Typography>
              <Typography variant="body1">Thank you for subscribing to our service. Please log out and log back in to see your changes.</Typography>
            </Box>
          </>
        ) : (
          <Box sx={{ textAlign: 'center', p: 3 }}>
            <CancelIcon sx={{ fontSize: 60, color: 'red' }} />
            <h2>Transaction Canceled</h2>
            <p>Your subscription process was canceled.</p>
          </Box>
        )}
      </ViewModal>
    </PageWrapper>
  );
}

export default Home;

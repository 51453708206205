import React, { useContext } from "react";
import { Box, Typography, CircularProgress, Card, CardActionArea, CardContent, useMediaQuery, useTheme } from "@mui/material";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import PeopleIcon from "@mui/icons-material/People";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import { AuthContext } from "../../../context/authContext";
import useApiService from "../../../api/apiService";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const CountCard = ({ loading, error, data, defaultMessage, countMessage, icon, onClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      transition={{ type: "spring", stiffness: 400, damping: 17 }}
    >
      <Card sx={{ flex: 1, borderRadius: 4, boxShadow: '0 4px 20px rgba(0,0,0,0.1)' }}>
        <CardActionArea onClick={onClick} sx={{ height: '100%' }}>
          <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", p: isMobile ? 2 : 3 }}>
            {React.cloneElement(icon, { sx: { fontSize: isMobile ? 40 : 60, color: "primary.main", mb: isMobile ? 1 : 2 } })}
            {loading ? (
              <CircularProgress size={isMobile ? 20 : 30} thickness={4} />
            ) : error ? (
              <Typography variant={isMobile ? "body2" : "subtitle1"} color="error">{defaultMessage}</Typography>
            ) : !data || data.length === 0 ? (
              <Typography variant={isMobile ? "body2" : "subtitle1"} color="text.secondary">{defaultMessage}</Typography>
            ) : (
              <>
                <Typography variant={isMobile ? "subtitle2" : "h6"} color="text.secondary" gutterBottom>{countMessage}</Typography>
                <Typography variant={isMobile ? "h4" : "h3"} color="primary.main" fontWeight="bold">{data.length}</Typography>
              </>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </motion.div>
  );
};

const UserCounts = () => {
  const { useWorkouts, useAthletes, useAthleteWorkouts } = useApiService();
  const { data: workouts, isLoading: workoutsLoading, isError: workoutsError } = useWorkouts();
  const { data: athletes, isLoading: athletesLoading, isError: athletesError } = useAthletes();
  const { data: athleteWorkouts, isLoading: athleteWorkoutsLoading, isError: athleteWorkoutsError } = useAthleteWorkouts({ date: new Date().toISOString().split('T')[0] });
  const navigate = useNavigate();
  const { token, userType, team } = useContext(AuthContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleNavigate = (path) => {
    navigate(token ? path : "/login");
  };

  return (
    <Box sx={{ 
      display: "flex", 
      flexDirection: isMobile ? "column" : "row", 
      gap: isMobile ? 2 : 4, 
      width: "100%"
    }}>
      {userType !== "athlete" && (
        <CountCard
          loading={workoutsLoading}
          error={workoutsError}
          data={workouts}
          defaultMessage="Create your first workout!"
          countMessage="Your Workouts"
          icon={<FitnessCenterIcon />}
          onClick={() => handleNavigate("/training-plan/workouts")}
        />
      )}
      {userType !== "athlete" ? (
        <CountCard
          loading={athletesLoading}
          error={athletesError}
          data={athletes}
          defaultMessage="Add your first athlete!"
          countMessage="Your Athletes"
          icon={<PeopleIcon />}
          onClick={() => handleNavigate("/roster/athletes")}
        />
      ) : (
        <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          transition={{ type: "spring", stiffness: 400, damping: 17 }}
        >
          <Card sx={{ flex: 1, borderRadius: 4, boxShadow: '0 4px 20px rgba(0,0,0,0.1)' }}>
            <CardActionArea onClick={() => handleNavigate("/roster/athletes")} sx={{ height: '100%' }}>
              <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", p: isMobile ? 2 : 3 }}>
                <PeopleIcon sx={{ fontSize: isMobile ? 40 : 60, color: "primary.main", mb: isMobile ? 1 : 2 }} />
                <Typography variant={isMobile ? "subtitle2" : "h6"} color="text.secondary" gutterBottom>Your Team</Typography>
                <Typography variant={isMobile ? "h4" : "h3"} color="primary.main" fontWeight="bold">{team?.name || "Add your team"}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </motion.div>
      )}
      <CountCard
        loading={athleteWorkoutsLoading}
        error={athleteWorkoutsError}
        data={athleteWorkouts}
        defaultMessage="No workouts for today!"
        countMessage="Today's Workouts"
        icon={<DirectionsRunIcon />}
        onClick={() => handleNavigate("/training-plan/workouts")}
      />
    </Box>
  );
};

export { UserCounts };
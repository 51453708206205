import React from "react";
import { Box } from "@mui/material";
import AthletesPrs from "./athletesPrs";
import ModalWrapper from "../../../../utils/wrappers/modalWrapper";

const PrModal = ({ athlete, open, onClose }) => {
  return (
    <ModalWrapper open={open} handleClose={onClose}>
      <Box>
        <AthletesPrs athlete={athlete} />
      </Box>
    </ModalWrapper>
  );
};

export default PrModal;

import React, { createContext, useState, useContext } from 'react';

const IslandContext = createContext();

export const useIsland = () => useContext(IslandContext);

export const IslandProvider = ({ children, initialValues }) => {
  const [tabs, setTabs] = useState(initialValues?.tabs || []);
  const [rootUrl, setRootUrl] = useState(initialValues?.rootUrl || '');
  const [actions, setActions] = useState(initialValues?.actions || []);
  const [ttp, setTtp] = useState(initialValues?.ttp || false);
  const [activeTab, setActiveTab] = useState(0);
  const [showScrollButtons, setShowScrollButtons] = useState(false);

  const value = {
    tabs,
    setTabs,
    rootUrl,
    setRootUrl,
    actions,
    setActions,
    ttp,
    setTtp,
    activeTab,
    setActiveTab,
    showScrollButtons,
    setShowScrollButtons,
  };

  return (
    <IslandContext.Provider value={value}>
      {children}
    </IslandContext.Provider>
  );
};

export { IslandContext };

import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, CircularProgress, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import useApiService from '../../../../api/apiService';
import { AthleteWorkoutPod } from '../../../trainingPlan/_components/trainingPlanSection/athleteWorkout/athleteWorkoutPod';
import ComponentWrapper from '../../../../components/utils/wrappers/componentWrapper';
import { AuthContext } from '../../../../context/authContext';

const TodaysWorkouts = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { useAthleteWorkouts } = useApiService();
  const [todaysWorkouts, setTodaysWorkouts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { userType } = useContext(AuthContext);

  const today = dayjs().format('YYYY-MM-DD');
  const { data: workouts, isLoading } = useAthleteWorkouts({ date: today });

  useEffect(() => {
    if (!isLoading && workouts) {
      setTodaysWorkouts(workouts);
      setLoading(false);
    }
  }, [isLoading, workouts]);

  if (userType !== 'athlete') {
    return null;
  }

  if (loading) {
    return (
      <ComponentWrapper>
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <CircularProgress color="secondary" />
        </Box>
      </ComponentWrapper>
    );
  }

  if (todaysWorkouts.length === 0) {
    return (
      <ComponentWrapper>
        <Box sx={{ p: 3, textAlign: 'center' }}>
          <Typography variant="h6" color="text.secondary">No workouts scheduled for today</Typography>
        </Box>
      </ComponentWrapper>
    );
  }

  return (
    <ComponentWrapper sx={{ padding: 0}}>
      <Box sx={{ 
        borderRadius: '16px', 
        overflow: 'hidden',
        boxShadow: '0 6px 24px rgba(0,0,0,0.15)',
        background: `linear-gradient(135deg, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
      }}>
        <Typography variant="h5" sx={{ 
          p: 2, 
          color: theme.palette.primary.contrastText,
          fontWeight: 'bold',
          textAlign: 'center',
          textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
        }}>
          {dayjs().format('dddd, MMMM D, YYYY')}
        </Typography>
        <Box sx={{ 
          backgroundColor: theme.palette.background.paper,
          // borderTopLeftRadius: '16px',
          // borderTopRightRadius: '16px',
          overflow: 'hidden',
        }}>
          {todaysWorkouts.map((workout, index) => (
            <Accordion 
              key={workout.id} 
              sx={{ 
                '&:before': { display: 'none' },
                backgroundColor: index % 2 === 0 ? theme.palette.background.default : theme.palette.background.paper,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  '&:hover': { backgroundColor: theme.palette.action.hover },
                  transition: 'background-color 0.3s',
                }}
              >
                <Typography sx={{ fontWeight: 'medium' }}>
                  {workout.workoutData?.name || 'Unnamed Workout'}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: isMobile ? 1 : 2 }}>
                <AthleteWorkoutPod workout={workout} />
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </ComponentWrapper>
  );
};

export { TodaysWorkouts };

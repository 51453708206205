import React from 'react';
import PageWrapper from '../pageWrapper/pageWrapper';
import CalculatorSection from './sections/calculatorSection';

// const tabs = [
//   {
//     label: 'Calculators',
//     path: '/calculators'
//   }
// ];

const CalculatorsPage = () => {
  return (
    <PageWrapper
      title="Calculators"
      tabs={[]}
      ttp={true}
      rootUrl="/calculators"
    >
      <CalculatorSection />
    </PageWrapper>
  );
};

export default CalculatorsPage;

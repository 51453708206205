import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  ListItem,
  Checkbox,
  Menu,
  MenuItem,
  Divider,
  Button,
} from "@mui/material";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SpeedIcon from "@mui/icons-material/Speed";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SchoolIcon from "@mui/icons-material/School";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import LinkIcon from "@mui/icons-material/Link";
import FlagIcon from "@mui/icons-material/Flag";
import FormAthlete from "./_components/formAthlete";
import PaceChart from "./_components/paceChart";
import RacePrediction from "./_components/racePrediction";
import PrModal from "./_components/pr/prModal";
import GoalModal from "./_components/goal/goalModal";
import MenuIcon from "../../utils/menuIcon";
import GradeChip from "./_components/gradeChip";
import { AuthContext } from "../../../context/authContext";
import useApiService from "../../../api/apiService";
import Form from "../../form/form";
import { useNavigate } from "react-router-dom";

const AthletePod = ({
  athlete,
  showFields = ["name", "gender"],
  selectable = false,
  showInvite = true,
  selected = false,
  onSelectChange,
  options = false,
  handleDeleteClick,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [openPaceChartDialog, setOpenPaceChartDialog] = useState(false);
  const [openRacePredictionDialog, setOpenRacePredictionDialog] =
    useState(false);
  const [openPrsDialog, setOpenPrsDialog] = useState(false);
  const [openGoalsDialog, setOpenGoalsDialog] = useState(false);
  const [openInviteDialog, setOpenInviteDialog] = useState(false);
  const [inviteEmail, setInviteEmail] = useState(athlete?.email || "");
  const { userType, user } = useContext(AuthContext);
  const { useUpdateAthlete } = useApiService();
  const updateAthlete = useUpdateAthlete();

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    setOpenFormDialog(true);
    setAnchorEl(null);
  };

  const handlePaceChartClick = () => {
    setOpenPaceChartDialog(true);
    setAnchorEl(null);
  };

  const handleRacePredictionClick = () => {
    setOpenRacePredictionDialog(true);
    setAnchorEl(null);
  };

  const handlePrsClick = () => {
    setOpenPrsDialog(true);
    setAnchorEl(null);
  };

  const handleGoalsClick = () => {
    setOpenGoalsDialog(true);
    setAnchorEl(null);
  };

  const handlePodClick = () => {
    if (selectable) {
      onSelectChange(!selected);
    } else {
      // Only allow navigation if user is a coach or if athlete is viewing their own profile
      if (
        userType === "coach" ||
        (userType === "athlete" && user?.id === athlete.id)
      ) {
        navigate(`/roster/athletes/${athlete.id}`);
      }
    }
  };

  const handleInviteClick = () => {
    setOpenInviteDialog(true);
    setAnchorEl(null);
  };

  const handleInviteSubmit = (formData) => {
    updateAthlete.mutate(
      {
        id: athlete.id,
        data: { email: formData.email },
      },
      {
        onSuccess: () => {
          setOpenInviteDialog(false);
          setInviteEmail("");
        },
      }
    );
  };

  const handleTffrsLinkClick = () => {
    if (athlete?.tffrsLink) {
      window.open(athlete.tffrsLink, "_blank");
    }
  };

  return (
    <ListItem key={athlete?.id || "unknown"} sx={{ p: 0.5 }}>
      <Box
        onClick={handlePodClick}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          pl: 1,
          pr: 1,
          border: (theme) => `1px solid ${theme.palette.border.main}`,
          borderRadius: "20px",
          cursor:
            userType === "coach" ||
            (userType === "athlete" && user?.id === athlete.id)
              ? "pointer"
              : "default",
          backgroundColor: (theme) => theme.palette.background.paperSecondary,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
            {selectable && (
              <Checkbox
                checked={selected}
                onChange={(e) => {
                  e.stopPropagation();
                  onSelectChange(!selected);
                }}
                onClick={(e) => e.stopPropagation()}
                sx={{ mr: 0 }}
              />
            )}
            {options && (
              <MenuIcon open={Boolean(anchorEl)} onClick={handleMenuClick} />
            )}
            {showFields.includes("name") && (
              <Typography
                sx={{ ml: 1, display: { xs: "block", sm: "block" } }}
              >{`${athlete?.firstName || "Unknown"} ${
                athlete?.lastName || "Athlete"
              }`}</Typography>
            )}
            {showFields.includes("grade") && athlete?.grade && (
              <GradeChip
                grade={athlete?.grade}
                sx={{ ml: 2, display: { xs: "none", sm: "block" } }}
              />
            )}
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            {userType === "coach" && !athlete.invited && showInvite && (
              <Button
                variant="outlined"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleInviteClick();
                }}
                sx={{ mr: 1 }}
              >
                Invite
              </Button>
            )}
            {(athlete.invited || !showInvite) &&
              showFields.includes("gender") &&
              (athlete?.gender === "male" ? (
                <MaleIcon
                  sx={{ color: (theme) => theme.palette.gender.male, mr: 1 }}
                />
              ) : (
                <FemaleIcon
                  sx={{ color: (theme) => theme.palette.gender.female, mr: 1 }}
                />
              ))}
          </Box>
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {userType === "coach" && (
          <>
            <MenuItem onClick={handleEditClick}>
              <EditIcon fontSize="small" style={{ marginRight: "8px" }} />
              Edit
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                handleDeleteClick(athlete);
                handleMenuClose();
              }}
            >
              <DeleteIcon fontSize="small" style={{ marginRight: "8px" }} />
              Remove from Team
            </MenuItem>
            <MenuItem disabled>
              <SchoolIcon
                fontSize="small"
                style={{ marginRight: "8px", opacity: 0.5 }}
              />
              Graduate Athlete
            </MenuItem>
            <Divider />
          </>
        )}
        {athlete?.fScore > 0 && (
          <>
            <MenuItem onClick={handlePaceChartClick}>
              <SpeedIcon fontSize="small" style={{ marginRight: "8px" }} />
              View Pace Chart
            </MenuItem>
            <MenuItem onClick={handleRacePredictionClick}>
              <AssessmentIcon fontSize="small" style={{ marginRight: "8px" }} />
              View Race Prediction
            </MenuItem>
          </>
        )}
        <MenuItem onClick={handlePrsClick}>
          <EmojiEventsIcon fontSize="small" style={{ marginRight: "8px" }} />
          PRs
        </MenuItem>
        <MenuItem onClick={handleGoalsClick}>
          <FlagIcon fontSize="small" style={{ marginRight: "8px" }} />
          Goals
        </MenuItem>
        {athlete?.tffrsLink && (
          <MenuItem onClick={handleTffrsLinkClick}>
            <LinkIcon fontSize="small" style={{ marginRight: "8px" }} />
            TFFRS Profile
          </MenuItem>
        )}
      </Menu>

      <FormAthlete
        open={openFormDialog}
        handleClose={() => setOpenFormDialog(false)}
        athlete={athlete}
        onAthleteUpdated={() => setOpenFormDialog(false)}
      />

      {openPaceChartDialog && athlete?.fScore > 0 && (
        <PaceChart
          athlete={athlete}
          open={openPaceChartDialog}
          handleClose={() => setOpenPaceChartDialog(false)}
          sx={{ width: "90%" }}
        />
      )}

      {openRacePredictionDialog && athlete?.fScore > 0 && (
        <RacePrediction
          athlete={athlete}
          open={openRacePredictionDialog}
          handleClose={() => setOpenRacePredictionDialog(false)}
          sx={{ width: "90%" }}
        />
      )}

      <PrModal
        athlete={athlete}
        open={openPrsDialog}
        onClose={() => setOpenPrsDialog(false)}
      />

      <GoalModal
        athlete={athlete}
        open={openGoalsDialog}
        onClose={() => setOpenGoalsDialog(false)}
      />

      <Form
        open={openInviteDialog}
        onClose={() => setOpenInviteDialog(false)}
        fields={[
          {
            name: "email",
            label: "Email",
            type: "email",
          },
          {
            type: "custom",
            render: () => (
              <Box mt={2}>
                <Typography variant="body2" color="textSecondary">
                  Please inform the athlete to check their spam folder and mark
                  the email as safe. This ensures they receive important
                  communications and future notifications.
                </Typography>
              </Box>
            ),
          },
        ]}
        onSubmit={handleInviteSubmit}
        title="Invite Athlete"
        defaultValues={{ email: inviteEmail }}
      />
    </ListItem>
  );
};

export { AthletePod };

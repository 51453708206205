import React, { useState, useContext } from "react";
import { Box, Typography, Grid, Chip, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import useApiService from "../../../../../api/apiService";
import ComponentWrapper from "../../../../../components/utils/wrappers/componentWrapper";
import { useTheme } from "@mui/material/styles";
import { RepForm } from "../../workoutsSection/forms";
import { AuthContext } from "../../../../../context/authContext";
import AreYouSure from "../../../../../components/utils/areYouSure/areYouSure";
import Button from "@mui/material/Button";
import FileChip from "../../../../../components/teamFiles/fileChip";

const repPaceOptions = [
  { value: "recovery", text: "Recovery" },
  { value: "easy", text: "Easy" },
  { value: "moderate", text: "Moderate" },
  { value: "tempo", text: "Tempo" },
  { value: "threshold", text: "Threshold" },
  { value: "cv", text: "CV" },
  { value: "aerobic_power", text: "Aerobic Power" },
  { value: "vo2_max", text: "VO2 Max" },
  { value: "anaerobic_endurance_mile", text: "Anaerobic Endurance Mile" },
  { value: "anaerobic_endurance_1500", text: "Anaerobic Endurance 1500" },
  { value: "anaerobic_power", text: "Anaerobic Power" },
  { value: "speed_endurance", text: "Speed Endurance" },
  { value: "pace_60", text: "60m PR" },
  { value: "pace_100", text: "100m PR" },
  { value: "pace_200", text: "200m PR" },
  { value: "pace_300", text: "300m PR" },
  { value: "pace_400", text: "400m PR" },
  { value: "pace_60H", text: "60H PR" },
  { value: "pace_100H", text: "100H PR" },
  { value: "pace_110H", text: "110H PR" },
  { value: "pace_300H", text: "300H PR" },
  { value: "pace_400H", text: "400H PR" },
  { value: "pace_600", text: "600m PR" },
  { value: "pace_800", text: "800m PR" },
  { value: "pace_1000", text: "1000m PR" },
  { value: "pace_1500", text: "1500m PR" },
  { value: "pace_MILE", text: "Mile PR" },
  { value: "pace_1600", text: "1600m PR" },
  { value: "pace_3000", text: "3000m PR" },
  { value: "pace_3000S", text: "3k Steeplechase PR" },
  { value: "pace_3200", text: "3200m PR" },
  { value: "pace_5000", text: "5000m PR" },
  { value: "pace_10000", text: "10,000m PR" },
  { value: "pace_4K_XC", text: "4K Cross Country PR" },
  { value: "pace_5K_XC", text: "5K Cross Country PR" },
  { value: "pace_6K_XC", text: "6K Cross Country PR" },
  { value: "pace_7K_XC", text: "7K Cross Country PR" },
  { value: "pace_8K_XC", text: "8K Cross Country PR" },
  { value: "pace_10K_XC", text: "10K Cross Country PR" },
  { value: "pace_4_MILE_XC", text: "4 Mile Cross Country PR" },
];

const AthleteWorkoutRep = ({ workoutId, workoutSetId }) => {
  const {
    useAthleteWorkoutReps,
    useUpdateAthleteWorkoutRep,
    useDeleteAthleteWorkoutRep,
    useTeamFiles,
    queryClient,
  } = useApiService();
  const { data: reps } = useAthleteWorkoutReps(workoutId, workoutSetId);
  const { data: teamFiles } = useTeamFiles();
  const updateAthleteWorkoutRepMutation = useUpdateAthleteWorkoutRep(
    workoutId,
    workoutSetId
  );
  const deleteAthleteWorkoutRepMutation = useDeleteAthleteWorkoutRep(
    workoutId,
    workoutSetId
  );
  const theme = useTheme();
  const { userType } = useContext(AuthContext);

  const [openRepDialog, setOpenRepDialog] = useState(false);
  const [selectedRep, setSelectedRep] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [expandedNotes, setExpandedNotes] = useState({});

  const toggleNoteExpansion = (repId) => {
    setExpandedNotes((prev) => ({
      ...prev,
      [repId]: !prev[repId],
    }));
  };

  const handleClickOpenRep = (rep) => {
    if (userType === "coach") {
      setSelectedRep(rep);
      setOpenRepDialog(true);
    }
  };

  const handleCloseRepDialog = () => {
    setOpenRepDialog(false);
    setSelectedRep(null);
  };

  const handleSaveRep = async (repData) => {
    if (userType !== "coach") return;
    try {
      if (selectedRep) {
        await updateAthleteWorkoutRepMutation.mutateAsync({
          id: selectedRep.id,
          data: repData,
        });
      }
      handleCloseRepDialog();
      queryClient.invalidateQueries([
        "athleteWorkoutReps",
        workoutId,
        workoutSetId,
      ]);
    } catch (error) {
      console.error("Error saving rep:", error);
    }
  };

  const handleDeleteRep = async () => {
    if (userType !== "coach") return;
    try {
      await deleteAthleteWorkoutRepMutation.mutateAsync(selectedRep.id);
      handleCloseRepDialog();
      queryClient.invalidateQueries([
        "athleteWorkoutReps",
        workoutId,
        workoutSetId,
      ]);
    } catch (error) {
      console.error("Error deleting rep:", error);
    }
  };

  const convertSecondsToTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = (seconds % 60).toFixed(2);
    return minutes > 0
      ? `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`
      : `${remainingSeconds}`;
  };

  const calculatePace = (distance, timeInSeconds, distanceUnit) => {
    if (distanceUnit === "time") {
      if (distance > 1609.34) {
        const pacePerMile = (timeInSeconds / distance) * 1609.34;
        return `Per mile: ${convertSecondsToTime(pacePerMile)}`;
      } else if (distance > 400) {
        const pacePer400m = (timeInSeconds / distance) * 400;
        return `Per 400m: ${convertSecondsToTime(pacePer400m)}`;
      } else {
        const pacePer200m = (timeInSeconds / distance) * 200;
        return `Per 200m: ${convertSecondsToTime(pacePer200m)}`;
      }
    } else if (distanceUnit === "meters") {
      if (distance > 1609.34) {
        const pacePerMile = (timeInSeconds / distance) * 1609.34;
        return `Per mile: ${convertSecondsToTime(pacePerMile)}`;
      } else if (distance > 400) {
        const pacePer400m = (timeInSeconds / distance) * 400;
        return `Per 400m: ${convertSecondsToTime(pacePer400m)}`;
      } else {
        const pacePer200m = (timeInSeconds / distance) * 200;
        return `Per 200m: ${convertSecondsToTime(pacePer200m)}`;
      }
    } else if (distanceUnit === "kilometers") {
      if (distance > 1.60934) {
        const pacePerMile = (timeInSeconds / (distance * 1000)) * 1609.34;
        return `Per mile: ${convertSecondsToTime(pacePerMile)}`;
      } else if (distance > 0.4) {
        const pacePer400m = (timeInSeconds / (distance * 1000)) * 400;
        return `Per 400m: ${convertSecondsToTime(pacePer400m)}`;
      } else {
        const pacePer200m = (timeInSeconds / (distance * 1000)) * 200;
        return `Per 200m: ${convertSecondsToTime(pacePer200m)}`;
      }
    } else if (distanceUnit === "miles") {
      if (distance > 1) {
        const pacePerMile = timeInSeconds / distance;
        return `Per mile: ${convertSecondsToTime(pacePerMile)}`;
      } else if (distance > 0.25) {
        const pacePer400m = (timeInSeconds / (distance * 1609.34)) * 400;
        return `Per 400m: ${convertSecondsToTime(pacePer400m)}`;
      } else {
        const pacePer200m = (timeInSeconds / (distance * 1609.34)) * 200;
        return `Per 200m: ${convertSecondsToTime(pacePer200m)}`;
      }
    }
    return null;
  };

  const formatRepDetails = (rep) => {
    if (rep.repType === "text") {
      return null;
    } else if (rep.distanceUnit === "time") {
      return `${rep.reps}x ${convertSecondsToTime(rep.timeInSeconds)}`;
    } else {
      return `${rep.reps}x ${rep.distance} ${rep.distanceUnit}`;
    }
  };

  const renderFiles = (teamFileIds) => {
    if (!teamFiles || !teamFileIds?.length) return null;
    
    const files = teamFiles.filter(file => teamFileIds.includes(file.id));
    return (
      <Grid container spacing={1} sx={{ mt: 1, ml: 1, mb: 2 }}>
        {files.map(file => (
          <Grid item key={file.id}>
            <FileChip file={file} />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Box sx={{ ml: 2, mr: 2, pb: 2 }}>
      <Grid container spacing={2}>
        {reps &&
          reps
            .sort((a, b) => a.position - b.position)
            .map((rep, repIndex) => (
              <Grid item xs={12} sm={6} md={6} key={repIndex}>
                <ComponentWrapper
                  sx={{
                    borderBottom: `1px solid ${theme.palette.border.main}`,
                    padding: 0,
                    textAlign: "left",
                    position: "relative",
                  }}
                  onClick={() => handleClickOpenRep(rep)}
                  tooltip={
                    userType === "coach"
                      ? `Edit Rep ${repIndex + 1}`
                      : undefined
                  }
                >
                  <Box
                    sx={{
                      borderBottom: `1px solid ${theme.palette.border.main}`,
                      textAlign: "left",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2" sx={{ ml: 2 }}>
                      {`#${repIndex + 1}`}
                    </Typography>
                    {userType === "coach" && (
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedRep(rep);
                          setIsDeleteDialogOpen(true);
                        }}
                        sx={{ mr: 1 }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Box>
                  <Typography variant="body2" sx={{ ml: 2 }}>
                    {formatRepDetails(rep)}
                  </Typography>
                  {rep.repType !== "text" && (
                    <>
                      {rep.distanceUnit !== 'time' && (
                        <Typography variant="body2" sx={{ ml: 2 }}>
                          Time: {convertSecondsToTime(rep.timeInSeconds)}
                        </Typography>
                      )}
                      {rep.recovery > 0 && (
                        <Typography variant="body2" sx={{ ml: 2 }}>
                          Recovery: {convertSecondsToTime(rep.recovery)}
                        </Typography>
                      )}
                      {calculatePace(
                        rep.distance,
                        rep.timeInSeconds,
                        rep.distanceUnit
                      ) && (
                        <Typography variant="body2" sx={{ ml: 2 }}>
                          {calculatePace(
                            rep.distance,
                            rep.timeInSeconds,
                            rep.distanceUnit
                          )}
                        </Typography>
                      )}
                      <Typography
                        variant="body2"
                        sx={{ ml: 2, display: "flex", alignItems: "center" }}
                      >
                        <Chip
                          label={`${rep.pacePercentage}% of ${
                            repPaceOptions.find(
                              (option) => option.value === rep.pace
                            )?.text || rep.pace
                          }`}
                          size="small"
                          sx={{
                            mb: 1,
                            height: "14px",
                            "& .MuiChip-label": {
                              fontSize: "0.55rem",
                              padding: "0 2px",
                            },
                          }}
                        />
                      </Typography>
                    </>
                  )}
                  {(rep.text) && (
                    <ComponentWrapper
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        mt: 2,
                        p: 2,
                        width: "100%",
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 1 }}>
                        {rep.repType === "text" ? "" : "Note:"}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          whiteSpace: 'pre-wrap',
                          width: '100%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: expandedNotes[rep.id] ? 'unset' : 2,
                          WebkitBoxOrient: 'vertical',
                          textAlign: 'center',
                        }}
                      >
                        {rep.repType === "text" ? rep.text : rep.text}
                      </Typography>
                      {(rep.text && rep.text.split('\n').length > 3) && (
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleNoteExpansion(rep.id);
                          }}
                          sx={{ mt: 1, p: 0 }}
                        >
                          {expandedNotes[rep.id] ? 'Hide' : 'Expand'}
                        </Button>
                      )}
                    </ComponentWrapper>
                  )}
                  {renderFiles(rep.teamFileIds)}
                </ComponentWrapper>
              </Grid>
            ))}
      </Grid>
      {userType === "coach" && selectedRep && (
        <RepForm
          open={openRepDialog}
          onClose={handleCloseRepDialog}
          onSave={handleSaveRep}
          initialData={selectedRep}
          onDelete={() => setIsDeleteDialogOpen(true)}
        />
      )}
      <AreYouSure
        isOpen={isDeleteDialogOpen}
        handleClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={handleDeleteRep}
        itemName="this rep"
      />
    </Box>
  );
};

export default AthleteWorkoutRep;

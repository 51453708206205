import React from 'react';
import { Chip, Tooltip, useTheme, useMediaQuery } from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const FileChip = ({ file }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const truncatedName = file.name.length > (isMobile ? 12 : 12)
    ? file.name.substring(0, isMobile ? 9 : 9) + '...'
    : file.name;

  const handleClick = () => {
    window.open(file.url, '_blank');
  };

  return (
    <Tooltip title={file.name}>
      <Chip
        icon={<InsertDriveFileIcon fontSize="small" />}
        label={truncatedName}
        onClick={handleClick}
        clickable
        size="small"
        sx={{
          maxWidth: isMobile ? '120px' : '120px',
          height: isMobile ? '24px' : '24px',
          '& .MuiChip-label': {
            fontSize: isMobile ? '0.675rem' : '0.675rem',
            padding: isMobile ? '0 6px' : '0 6px'
          },
          '& .MuiChip-icon': {
            marginLeft: isMobile ? '4px' : '4px'
          }
        }}
      />
    </Tooltip>
  );
};

export default FileChip;

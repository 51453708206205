import React, { useState } from "react";
import useApiService from "../../../api/apiService";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Paper,
  Chip,
  ListItemText,
} from "@mui/material";
import { toast } from "sonner";
import AISet from "./aiSet";
import { WorkoutForm } from "../../../pages/trainingPlan/_components/workoutsSection/forms/workoutForm";

const AIWorkout = ({ data }) => {
  const [editedWorkout, setEditedWorkout] = useState(
    data?.workout || {
      name: "",
      description: "",
      workoutType: "",
      sets: [],
    }
  );
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState({ type: null, index: null });
  const [showFullDescription, setShowFullDescription] = useState(false);
  const { useCreateWorkout, useCreateWorkoutSet, useCreateWorkoutRep } =
    useApiService();

  const createWorkoutMutation = useCreateWorkout();
  const createWorkoutSetMutation = useCreateWorkoutSet();
  const createWorkoutRepMutation = useCreateWorkoutRep();

  const handleCreateWorkout = async () => {
    setIsLoading(true);
    try {
      const workoutResponse = await createWorkoutMutation.mutateAsync({
        name: editedWorkout.name,
        description: editedWorkout.description,
        workoutType: editedWorkout.workoutType,
      });
      const workoutId = workoutResponse.data.id;

      for (let [setIndex, set] of editedWorkout.sets.entries()) {
        const setResponse = await createWorkoutSetMutation.mutateAsync({
          workoutId,
          reps: set.repCount,
          recovery: set.recovery,
          position: setIndex,
        });
        const setId = setResponse.data.id;

        for (let [repIndex, rep] of set.reps.entries()) {
          await createWorkoutRepMutation.mutateAsync({
            workoutId,
            workoutSetId: setId,
            ...rep,
            position: repIndex,
          });
        }
      }

      toast.success("Workout created successfully");
    } catch (error) {
      toast.error("Error creating workout");
      console.error("Error creating workout:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderWorkoutDetails = () => (
    <Box sx={{ maxWidth: 800, margin: "auto", padding: 2 }}>
      <Typography variant="h5" gutterBottom>
        Workout Details
      </Typography>
      <Typography
        variant="body2"
        sx={{ mb: 2, fontStyle: "italic", color: "text.secondary" }}
      >
        Click anywhere to edit
      </Typography>
      <Paper
        elevation={0}
        sx={{
          borderRadius: 4,
          overflow: "hidden",
          mb: 2,
          border: (theme) => `1px solid ${theme.palette.border.main}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: (theme) => theme.palette.background.paperDark,
            cursor: "pointer",
            p: 2,
          }}
          onClick={() => setEditMode({ type: "workout", index: null })}
        >
          <ListItemText
            primary={
              <Typography variant="h6">Name: {editedWorkout.name}</Typography>
            }
            secondary={
              <Typography variant="body2">
                Type: {editedWorkout.workoutType}
              </Typography>
            }
          />
          <Chip
            label={editedWorkout.workoutType}
            color="primary"
            size="small"
          />
        </Box>
        <Box sx={{ p: 2 }}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            Description:{" "}
            {showFullDescription
              ? editedWorkout.description
              : `${editedWorkout.description
                  .split("\n")
                  .slice(0, 4)
                  .join("\n")}${
                  editedWorkout.description.split("\n").length > 4 ? "..." : ""
                }`}
          </Typography>
          {editedWorkout.description.split("\n").length > 4 && (
            <Button
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setShowFullDescription(!showFullDescription);
              }}
            >
              {showFullDescription ? "Less" : "More"}
            </Button>
          )}
        </Box>
        <Box sx={{ p: 2 }}>
          {editedWorkout.sets.map((set, setIndex) => (
            <AISet
              key={`${setIndex}-${set.reps.length}`}
              set={set}
              setIndex={setIndex}
              onEditSet={(index) => setEditMode({ type: "set", index })}
              onEditRep={handleEditRep}
            />
          ))}
        </Box>
      </Paper>

      <Button
        variant="contained"
        color="primary"
        onClick={handleCreateWorkout}
        disabled={isLoading}
        sx={{ mt: 2 }}
      >
        {isLoading ? <CircularProgress size={24} /> : "Create Workout"}
      </Button>

      <WorkoutForm
        open={editMode.type === "workout"}
        handleClose={() => setEditMode({ type: null, index: null })}
        selectedWorkout={editMode.type === "workout" ? editedWorkout : null}
        handleSaveWorkout={handleEditSubmit}
      />
    </Box>
  );

  const handleEditSubmit = (formData) => {
    // eslint-disable-next-line
    switch (editMode.type) {
      case "workout":
        setEditedWorkout((prev) => ({ ...prev, ...formData }));
        break;
      case "set":
        setEditedWorkout((prev) => {
          const newSets = [...prev.sets];
          newSets[editMode.index] = { ...newSets[editMode.index], ...formData };
          return { ...prev, sets: newSets };
        });
        break;
    }
    setEditMode({ type: null, index: null });
  };

  const handleEditRep = (setIndex, repIndex, editedRep) => {
    setEditedWorkout((prev) => {
      const newSets = [...prev.sets];
      newSets[setIndex].reps[repIndex] = {
        ...newSets[setIndex].reps[repIndex],
        ...editedRep,
      };
      return { ...prev, sets: newSets };
    });
  };

  return data ? (
    renderWorkoutDetails()
  ) : (
    <Typography>No workout data available</Typography>
  );
};

export default AIWorkout;

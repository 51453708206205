import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  useTheme,
  Grid,
  Card,
  CardContent,
  Skeleton,
  Avatar,
  useMediaQuery,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import SportsIcon from "@mui/icons-material/Sports";
import useApiService from "../../../../api/apiService";
import { motion } from "framer-motion";
import { NumberTicker } from "../../../../components/magicUI/numberTicker/numberTicker";

const Counts = () => {
  const { appCoachCount, appAthleteCount, appAthleteWorkoutsCount, appAthletePrCount } =
    useApiService();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [coachData, setCoachData] = useState({
    count: null,
    loading: true,
    error: false,
  });
  const [athleteData, setAthleteData] = useState({
    count: null,
    loading: true,
    error: false,
  });
  const [workoutData, setWorkoutData] = useState({
    count: null,
    loading: true,
    error: false,
  });
  const [prData, setPrData] = useState({
    count: null,
    loading: true,
    error: false,
  });

  useEffect(() => {
    const fetchCoachCount = async () => {
      try {
        const response = await appCoachCount();
        setCoachData({
          count: response.data.coachCount,
          loading: false,
          error: false,
        });
      } catch (error) {
        console.error("Error fetching coach count:", error);
        setCoachData({ count: null, loading: false, error: true });
      }
    };

    const fetchAthleteCount = async () => {
      try {
        const response = await appAthleteCount();
        setAthleteData({
          count: response.data.athleteCount,
          loading: false,
          error: false,
        });
      } catch (error) {
        console.error("Error fetching athlete count:", error);
        setAthleteData({ count: null, loading: false, error: true });
      }
    };

    const fetchWorkoutCount = async () => {
      try {
        const response = await appAthleteWorkoutsCount();
        setWorkoutData({
          count: response.data.athleteWorkoutsCount,
          loading: false,
          error: false,
        });
      } catch (error) {
        console.error("Error fetching workout count:", error);
        setWorkoutData({ count: null, loading: false, error: true });
      }
    };

    const fetchPrCount = async () => {
      try {
        const response = await appAthletePrCount();
        setPrData({
          count: response.data.athletePrCount,
          loading: false,
          error: false,
        });
      } catch (error) {
        console.error("Error fetching PR count:", error);
        setPrData({ count: null, loading: false, error: true });
      }
    };

    fetchCoachCount();
    fetchAthleteCount();
    fetchWorkoutCount();
    fetchPrCount();
    // eslint-disable-next-line
  }, []);

  const countCards = [
    {
      data: coachData,
      label: "Coaches Leading the Way",
      description: "Join our community of dedicated coaches",
      icon: <PeopleIcon fontSize="large" />,
      color: theme.palette.primary.main,
    },
    {
      data: athleteData,
      label: "Athletes Reaching New Heights",
      description: "Be part of our growing athlete network",
      icon: <SportsIcon fontSize="large" />,
      color: theme.palette.secondary.main,
    },
    {
      data: workoutData,
      label: "Workouts Scheduled",
      description: "Empowering athletes with personalized training",
      icon: <FitnessCenterIcon fontSize="large" />,
      color: theme.palette.success.main,
    },
    {
      data: prData,
      label: "Personal Records",
      description: "Track and celebrate your athlete's achievements",
      icon: <SportsIcon fontSize="large" />,
      color: theme.palette.secondary.main,
    },
  ];

  return (
    <Box sx={{ flexGrow: 1, padding: theme.spacing(2) }}>
      <Grid container spacing={3}>
        {countCards.map((card, index) => {
          const { data, label, description, icon, color } = card;
          return (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                style={{ height: isMobile ? 'auto' : '100%' }}
              >
                <Card
                  sx={{
                    textAlign: "center",
                    padding: theme.spacing(isMobile ? 1 : 2),
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: theme.shadows[3],
                    height: '100%',
                    display: "flex",
                    flexDirection: isMobile ? "row" : "column",
                    justifyContent: "space-between",
                    alignItems: isMobile ? "center" : "stretch",
                    position: "relative",
                    overflow: "hidden",
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      height: "5px",
                      background: `linear-gradient(90deg, ${color}, ${theme.palette.primary.main})`,
                    },
                  }}
                >
                  <CardContent sx={{ 
                    display: 'flex', 
                    flexDirection: isMobile ? 'row' : 'column',
                    alignItems: isMobile ? 'center' : 'stretch',
                    justifyContent: 'space-between',
                    width: '100%',
                    flexGrow: 1,
                  }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: isMobile ? 'flex-start' : 'center' }}>
                      <Avatar
                        sx={{
                          margin: isMobile ? '0 16px 0 0' : 'auto',
                          bgcolor: color,
                          width: isMobile ? 50 : 70,
                          height: isMobile ? 50 : 70,
                          transition: "transform 0.3s ease-in-out",
                          "&:hover": {
                            transform: "scale(1.1)",
                          },
                        }}
                      >
                        {icon}
                      </Avatar>
                      {!isMobile && (
                        <Typography
                          variant="h5"
                          color="text.primary"
                          sx={{ mt: 1, fontWeight: "medium" }}
                        >
                          {label}
                        </Typography>
                      )}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: isMobile ? 'flex-end' : 'center' }}>
                      <Typography 
                        variant={isMobile ? "h4" : "h3"}
                        component="div" 
                        sx={{ 
                          mt: isMobile ? 0 : 2,
                          fontWeight: "bold",
                          background: `-webkit-linear-gradient(45deg, ${color}, ${theme.palette.primary.main})`,
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                        }}
                      >
                        {data.loading ? (
                          <Skeleton width={isMobile ? "40px" : "50%"} sx={{ margin: "auto" }} />
                        ) : data.error ? (
                          "Error"
                        ) : (
                          <NumberTicker value={data.count} />
                        )}
                      </Typography>
                      {isMobile && (
                        <Typography
                          variant="body2"
                          color="text.primary"
                          sx={{ fontWeight: "medium" }}
                        >
                          {label}
                        </Typography>
                      )}
                    </Box>
                  </CardContent>
                  {!isMobile && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 1, fontStyle: "italic", px: 2, pb: 2 }}
                    >
                      {description}
                    </Typography>
                  )}
                  <Box
                    sx={{
                      mt: isMobile ? 0 : 'auto',
                      height: "5px",
                      background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${color})`,
                    }}
                  />
                </Card>
              </motion.div>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export { Counts };

import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import ComponentWrapper from "../../../components/utils/wrappers/componentWrapper";
import { useTheme } from "@mui/material/styles";
import { RepForm } from "../../../pages/trainingPlan/_components/workoutsSection/forms/repForm";

const repPaceOptions = [
  { value: "recovery", text: "Recovery" },
  { value: "easy", text: "Easy" },
  { value: "moderate", text: "Moderate" },
  { value: "tempo", text: "Tempo" },
  { value: "threshold", text: "Threshold" },
  { value: "cv", text: "CV" },
  { value: "aerobic_power", text: "Aerobic Power" },
  { value: "vo2_max", text: "VO2 Max" },
  { value: "anaerobic_endurance_mile", text: "Anaerobic Endurance Mile" },
  { value: "anaerobic_endurance_1500", text: "Anaerobic Endurance 1500" },
  { value: "anaerobic_power", text: "Anaerobic Power" },
  { value: "speed_endurance", text: "Speed Endurance" },
  { value: "pace_60", text: "60m PR" },
  { value: "pace_100", text: "100m PR" },
  { value: "pace_200", text: "200m PR" },
  { value: "pace_300", text: "300m PR" },
  { value: "pace_400", text: "400m PR" },
  { value: "pace_60H", text: "60H PR" },
  { value: "pace_100H", text: "100H PR" },
  { value: "pace_110H", text: "110H PR" },
  { value: "pace_300H", text: "300H PR" },
  { value: "pace_400H", text: "400H PR" },
  { value: "pace_600", text: "600m PR" },
  { value: "pace_800", text: "800m PR" },
  { value: "pace_1000", text: "1000m PR" },
  { value: "pace_1500", text: "1500m PR" },
  { value: "pace_MILE", text: "Mile PR" },
  { value: "pace_1600", text: "1600m PR" },
  { value: "pace_3000", text: "3000m PR" },
  { value: "pace_3000S", text: "3k Steeplechase PR" },
  { value: "pace_3200", text: "3200m PR" },
  { value: "pace_5000", text: "5000m PR" },
  { value: "pace_10000", text: "10,000m PR" },
  { value: "pace_4K_XC", text: "4K Cross Country PR" },
  { value: "pace_5K_XC", text: "5K Cross Country PR" },
  { value: "pace_6K_XC", text: "6K Cross Country PR" },
  { value: "pace_7K_XC", text: "7K Cross Country PR" },
  { value: "pace_8K_XC", text: "8K Cross Country PR" },
  { value: "pace_10K_XC", text: "10K Cross Country PR" },
  { value: "pace_4_MILE_XC", text: "4 Mile Cross Country PR" },
  { value: "goal_60", text: "60m Goal" },
  { value: "goal_100", text: "100m Goal" },
  { value: "goal_200", text: "200m Goal" },
  { value: "goal_300", text: "300m Goal" },
  { value: "goal_400", text: "400m Goal" },
  { value: "goal_60H", text: "60H Goal" },
  { value: "goal_100H", text: "100H Goal" },
  { value: "goal_110H", text: "110H Goal" },
  { value: "goal_300H", text: "300H Goal" },
  { value: "goal_400H", text: "400H Goal" },
  { value: "goal_600", text: "600m Goal" },
  { value: "goal_800", text: "800m Goal" },
  { value: "goal_1000", text: "1000m Goal" },
  { value: "goal_1500", text: "1500m Goal" },
  { value: "goal_MILE", text: "Mile Goal" },
  { value: "goal_1600", text: "1600m Goal" },
  { value: "goal_3000", text: "3000m Goal" },
  { value: "goal_3000S", text: "3k Steeplechase Goal" },
  { value: "goal_3200", text: "3200m Goal" },
  { value: "goal_5000", text: "5000m Goal" },
  { value: "goal_10000", text: "10,000m Goal" },
  { value: "goal_4K_XC", text: "4K Cross Country Goal" },
  { value: "goal_5K_XC", text: "5K Cross Country Goal" },
  { value: "goal_6K_XC", text: "6K Cross Country Goal" },
  { value: "goal_7K_XC", text: "7K Cross Country Goal" },
  { value: "goal_8K_XC", text: "8K Cross Country Goal" },
  { value: "goal_10K_XC", text: "10K Cross Country Goal" },
  { value: "goal_4_MILE_XC", text: "4 Mile Cross Country Goal" },
];

const AIRep = ({ rep, repIndex, onEditRep }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [expandedNotes, setExpandedNotes] = useState(false);
  const theme = useTheme();

  const handleOpenEditModal = () => setIsEditModalOpen(true);
  const handleCloseEditModal = () => setIsEditModalOpen(false);

  const handleSaveRep = (editedRep) => {
    onEditRep(repIndex, editedRep);
    handleCloseEditModal();
  };

  const toggleNoteExpansion = () => setExpandedNotes(!expandedNotes);

  const convertSecondsToTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = (seconds % 60).toFixed(2);
    const formattedSeconds = remainingSeconds.endsWith(".00")
      ? Math.floor(remainingSeconds)
      : remainingSeconds;
    return minutes > 0
      ? `${minutes}:${formattedSeconds < 10 ? "0" : ""}${formattedSeconds}`
      : `${formattedSeconds}`;
  };

  const formatRepDetails = () => {
    if (rep.repType === "text") {
      return null;
    } else if (rep.distanceUnit === "time") {
      return `${rep.reps}x ${convertSecondsToTime(rep.timeInSeconds)}`;
    } else {
      return `${rep.reps}x ${rep.distance} ${rep.distanceUnit}`;
    }
  };

  return (
    <>
      <ComponentWrapper
        sx={{
          borderBottom: `1px solid ${theme.palette.border.main}`,
          padding: 0,
          position: "relative",
          transition: "box-shadow 0.3s, transform 0.2s",
          "&:hover": {
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            transform: "translateY(-2px)",
          },
        }}
        onClick={handleOpenEditModal}
        tooltip={`Edit Rep ${repIndex + 1}`}
      >
        <Box
          sx={{
            borderBottom: `1px solid ${theme.palette.border.main}`,
            textAlign: "center",
            borderRadius: "24px 24px 0 0",
            backgroundColor: (theme) => theme.palette.background.paperDark,
          }}
        >
          <Typography variant="body2">
            {rep.reps > 1 ? `Reps ${repIndex + 1}` : `Rep ${repIndex + 1}`}
          </Typography>
        </Box>
        <Typography variant="body2" sx={{ ml: 2 }}>
          {formatRepDetails()}
        </Typography>
        {rep.repType !== "text" && (
          <>
            <Typography variant="body2" sx={{ ml: 2 }}>
              {repPaceOptions.find((option) => option.value === rep.pace)
                ?.text || rep.pace}
              {rep.pacePercentage !== 100 && ` (${rep.pacePercentage}%)`}
            </Typography>
            <Typography variant="body2" sx={{ ml: 2 }}>
              Recovery: {convertSecondsToTime(rep.recovery)}
            </Typography>
          </>
        )}
        {(rep.repType === "text" || rep.text) && (
          <ComponentWrapper sx={{ mt: 2, p: 2, width: "100%" }}>
            <Typography variant="body2" sx={{ fontWeight: "bold", mb: 1 }}>
              {rep.repType === "text" ? "Rep:" : "Note:"}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                whiteSpace: "pre-wrap",
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: expandedNotes ? "unset" : 2,
                WebkitBoxOrient: "vertical",
                textAlign: "center",
              }}
            >
              {rep.repType === "text" ? rep.text : rep.text}
            </Typography>
            {rep.text && rep.text.split("\n").length > 3 && (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  toggleNoteExpansion();
                }}
                sx={{ mt: 1, p: 0 }}
              >
                {expandedNotes ? "Hide" : "Expand"}
              </Button>
            )}
          </ComponentWrapper>
        )}
      </ComponentWrapper>
      <RepForm
        open={isEditModalOpen}
        onClose={handleCloseEditModal}
        onSave={handleSaveRep}
        initialData={rep}
      />
    </>
  );
};

export default AIRep;

import { Card, CardContent, Button, Stack, Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const CreateOrLogin = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6, ease: "easeOut" }}
      sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Card variant="outlined" sx={{ width: '100%', boxShadow: 3 }}>
        <CardContent sx={{ textAlign: 'center' }}>
          <Typography variant={isDesktop ? 'h4' : 'h5'} color="primary" gutterBottom>
            Ready to Elevate Your Team?
          </Typography>
          <Typography variant={isDesktop ? 'h6' : 'body1'} color="textSecondary" gutterBottom sx={{ mb: isDesktop ? 4 : 2 }}>
            Join our community of coaches and athletes striving for excellence.
          </Typography>
          <Stack direction={isDesktop ? 'row' : 'column'} spacing={isDesktop ? 4 : 2} justifyContent="center" mt={isDesktop ? 4 : 3}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/login/create-account')}
              fullWidth
              component={motion.button}
              whileHover={{ scale: 1.05 }}
              size={isDesktop ? 'large' : 'medium'}
            >
              Create New Team
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate('/login')}
              fullWidth
              component={motion.button}
              whileHover={{ scale: 1.05 }}
              size={isDesktop ? 'large' : 'medium'}
            >
              Login to Existing Team
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};

export { CreateOrLogin };
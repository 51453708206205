import { toast } from 'sonner';

/**
 * @typedef {Object} ApiV2Response
 * @property {number} status
 * @property {any} data
 */

/**
 * Uploads a file to Amazon S3 using a presigned URL
 * @param {Object} params
 * @param {File} params.file - The file to upload
 * @param {string|undefined} params.presignedUrl - The presigned URL for the upload
 * @returns {Promise<ApiV2Response|void>}
 */

export async function uploadFile({ file, presignedUrl }) {
  if (!presignedUrl) {
    console.error('No presigned URL provided');
    toast.error('No presigned URL provided');
    return;
  }

  const ACTION = 'File Upload';

  const data = new FormData();
  data.append('file', file);

  try {
    const response = await fetch(presignedUrl, {
      method: 'PUT',
      body: data.get('file'),
    });

    if (response.status === 200) {
      /** @type {ApiV2Response} */
      const returnedResponse = {
        status: 200,
        data: null,
      };
      return returnedResponse;
    } else {
      console.error(`${ACTION} Failed:`, response);
      toast.error(`${ACTION} Failed: ${response.status}`);

      /** @type {ApiV2Response} */
      const returnedResponse = {
        status: response.status,
        data: null,
      };
      return returnedResponse;
    }
  } catch (error) {
    console.error(`${ACTION} Error:`, error);

    if (typeof error === 'string') {
      toast.error(`${ACTION} Error: ${error}`);
    } else if (error instanceof Error) {
      toast.error(`${ACTION} Error: ${error.message}`);
    }
  }
}

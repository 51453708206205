import React, { useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Avatar,
  IconButton,
  Button,
  useTheme,
  useMediaQuery,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useApiService from "../../../../../api/apiService";
import AthletesPrs from "../../../../../components/pods/athletePod/_components/pr/athletesPrs";
import AthletesGoals from "../../../../../components/pods/athletePod/_components/goal/athletesGoals";
import ComponentWrapper from "../../../../../components/utils/wrappers/componentWrapper";
import FormAthlete from "../../../../../components/pods/athletePod/_components/formAthlete";
import { CheckGoals } from "../../../../../pages/home/_components/checkGoals/checkGoals";
import { AuthContext } from "../../../../../context/authContext";
import Form from "../../../../../components/form/form";

const AthletePage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { athleteId } = useParams();
  const { useAthlete, useUpdateAthlete } = useApiService();
  const [editOpen, setEditOpen] = React.useState(false);
  const [openInviteDialog, setOpenInviteDialog] = React.useState(false);
  const { userType } = useContext(AuthContext);

  const { data: athlete, isLoading, error, refetch } = useAthlete(athleteId);
  const updateAthlete = useUpdateAthlete();

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <Typography color="error">Error Loading Athlete Data</Typography>
      </Box>
    );
  }

  const isDistanceAthlete = athlete?.athleteTypes?.includes("distance");

  const handleInviteSubmit = (formData) => {
    updateAthlete.mutate(
      {
        id: athlete.id,
        data: { email: formData.email },
      },
      {
        onSuccess: () => {
          setOpenInviteDialog(false);
          refetch();
        },
      }
    );
  };

  const formatLastSeen = (lastSeen) => {
    if (!lastSeen) return "Never";
    const date = new Date(lastSeen);
    return date.toLocaleDateString();
  };

  const convertSecondsToTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = (seconds % 60).toFixed(2);
    return minutes > 0
      ? `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`
      : `${remainingSeconds}`;
  };

  return (
    <Box sx={{ p: { xs: 1, sm: 2, md: 3 } }}>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate("/roster/athletes")}
        sx={{
          mb: 2,
          color: "text.secondary",
          "&:hover": {
            bgcolor: "action.hover",
          },
          fontSize: "0.875rem",
          alignSelf: "flex-start"
        }}
      >
        Back to Roster
      </Button>
      <Grid container spacing={{ xs: 2, sm: 3 }}>
        {/* Athlete Info Card */}
        <Grid item xs={12}>
          <Paper
            elevation={2}
            sx={{
              p: { xs: 2, sm: 3 },
              borderRadius: 2,
              background: theme.palette.background.paper,
              position: "relative",
            }}
          >
            <IconButton
              onClick={() => setEditOpen(true)}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                bgcolor: "background.default",
                "&:hover": {
                  bgcolor: "action.hover",
                },
              }}
              aria-label="Edit Athlete"
            >
              <EditIcon />
            </IconButton>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "center", md: "flex-start" },
                gap: { xs: 2, sm: 3 },
              }}
            >
              {/* Profile Section */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Avatar
                  sx={{
                    width: { xs: 120, sm: 150 },
                    height: { xs: 120, sm: 150 },
                    fontSize: { xs: "2.5rem", sm: "3rem" },
                    bgcolor: "primary.main",
                  }}
                >
                  {athlete?.firstName?.[0]}
                  {athlete?.lastName?.[0]}
                </Avatar>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 500, textAlign: "center" }}
                >
                  {athlete?.firstName} {athlete?.lastName}
                </Typography>
              </Box>

              {/* Info Grid */}
              <Grid
                container
                spacing={3}
                sx={{ flex: 1, mt: { xs: 2, md: 0 } }}
              >
                <Grid item xs={12} sm={6} md={4}>
                  <InfoSection
                    label="Email"
                    value={athlete?.email}
                    action={
                      userType === "coach" &&
                      !athlete.invited &&
                      !athlete?.email && (
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => setOpenInviteDialog(true)}
                          sx={{ mt: 1 }}
                        >
                          Invite Athlete
                        </Button>
                      )
                    }
                  />
                  <InfoSection label="Phone" value={athlete?.phone} />
                  {userType === "coach" && (
                    <InfoSection
                      label="Last Seen"
                      value={formatLastSeen(athlete?.lastSeen)}
                    />
                  )}
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <InfoSection label="Birth Date" value={athlete?.birthDate} />
                  <InfoSection label="Grade" value={athlete?.grade} />
                  <InfoSection label="Gender" value={athlete?.gender} />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <InfoSection
                    label="Athlete Types"
                    value={
                      athlete?.athleteTypes?.length > 0
                        ? athlete.athleteTypes.join(", ")
                        : null
                    }
                  />
                  {athlete?.tffrsLink && (
                    <Box sx={{ mt: 2 }}>
                      <Button
                        variant="outlined"
                        size="small"
                        href={athlete.tffrsLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        fullWidth={isMobile}
                      >
                        View TFFRS Profile
                      </Button>
                    </Box>
                  )}
                </Grid>

                {isDistanceAthlete && (
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        mt: 2,
                        p: 2,
                        bgcolor: "background.default",
                        borderRadius: 2,
                        display: "flex",
                        justifyContent: "center",
                        gap: 4,
                      }}
                    >
                      <Box sx={{ textAlign: "center" }}>
                        <Typography variant="h4" color="primary.main">
                          {athlete?.fScore || "-"}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                          F Score
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Paper>
        </Grid>

        {/* Stats Sections */}
        <Grid item xs={12} md={6}>
          <ComponentWrapper>
            <Typography variant="h6" sx={{ mb: 3, fontWeight: 500 }}>
              Personal Records
            </Typography>
            <AthletesPrs athlete={athlete} />
          </ComponentWrapper>
        </Grid>

        <Grid item xs={12} md={6}>
          <ComponentWrapper>
            <Typography variant="h6" sx={{ mb: 3, fontWeight: 500 }}>
              Performance Goals
            </Typography>
            <AthletesGoals athlete={athlete} />
          </ComponentWrapper>
        </Grid>

        <Grid item xs={12}>
          <ComponentWrapper>
            <Typography variant="h6" sx={{ mb: 3, fontWeight: 500 }}>
              Process Goals
            </Typography>
            <CheckGoals athleteId={athleteId} />
          </ComponentWrapper>
        </Grid>

        {isDistanceAthlete && athlete?.fScore && athlete?.paceChart && (
          <>
            <Grid item xs={12} md={6}>
              <ComponentWrapper>
                <Typography variant="h6" sx={{ mb: 3, fontWeight: 500 }}>
                  Race Predictions
                </Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Distance</TableCell>
                        <TableCell>Time</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(athlete.paceChart.prediction).map(
                        ([distance, time]) => (
                          <TableRow key={distance}>
                            <TableCell>{distance}</TableCell>
                            <TableCell>{convertSecondsToTime(time)}</TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </ComponentWrapper>
            </Grid>

            <Grid item xs={12} md={6}>
              <ComponentWrapper>
                <Typography variant="h6" sx={{ mb: 3, fontWeight: 500 }}>
                  Pace Chart
                </Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Pace Type</TableCell>
                        {[
                          "mile",
                          "1600",
                          "1000",
                          "800",
                          "600",
                          "400",
                          "300",
                          "200",
                          "100",
                        ].map((distance) => (
                          <TableCell key={distance}>{distance}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(athlete.paceChart.training).map(
                        ([paceType, data]) => (
                          <TableRow key={paceType}>
                            <TableCell sx={{ textTransform: "capitalize" }}>
                              {paceType.replace(/_/g, " ")}
                            </TableCell>
                            {[
                              "mile",
                              "1600",
                              "1000",
                              "800",
                              "600",
                              "400",
                              "300",
                              "200",
                              "100",
                            ].map((distance) => (
                              <TableCell key={distance}>
                                {convertSecondsToTime(data.values[distance])}
                              </TableCell>
                            ))}
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </ComponentWrapper>
            </Grid>
          </>
        )}
      </Grid>

      <FormAthlete
        open={editOpen}
        handleClose={() => setEditOpen(false)}
        athlete={athlete}
        refetch={refetch}
      />

      <Form
        open={openInviteDialog}
        onClose={() => setOpenInviteDialog(false)}
        fields={[
          {
            name: "email",
            label: "Email",
            type: "email",
          },
          {
            type: "custom",
            render: () => (
              <Box mt={2}>
                <Typography variant="body2" color="text.secondary">
                  Please inform the athlete to check their spam folder and mark
                  the email as safe. This ensures they receive important
                  communications and future notifications.
                </Typography>
              </Box>
            ),
          },
        ]}
        onSubmit={handleInviteSubmit}
        title="Invite Athlete"
        defaultValues={{ email: athlete?.email || "" }}
      />
    </Box>
  );
};

const InfoSection = ({ label, value, action }) => (
  <Box sx={{ mb: 2 }}>
    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
      {label}
    </Typography>
    <Typography variant="body1">{value || "Not Provided"}</Typography>
    {action}
  </Box>
);

export default AthletePage;

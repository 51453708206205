import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import useApiService from "../../../../../api/apiService";
import { ComponentWrapper } from "../../../../utils";
import Form from "../../../../form/form";

const eventOptions = {
  // Sprints
  goal_60: '60',
  goal_100: '100',
  goal_200: '200',
  goal_300: '300',
  goal_400: '400',

  // Hurdles
  goal_60H: '60H',
  goal_100H: '100H',
  goal_110H: '110H',
  goal_300H: '300H',
  goal_400H: '400H',

  // Middle Distance
  goal_600: '600',
  goal_800: '800',
  goal_1000: '1000',
  goal_1500: '1500',
  goal_MILE: 'MILE',
  goal_1600: '1600',

  // Long Distance
  goal_3000: '3000',
  goal_3000S: '3000S',
  goal_3200: '3200',
  goal_5000: '5000',
  goal_10000: '10,000',

  // Cross Country
  goal_4K_XC: '4K (XC)',
  goal_5K_XC: '5K (XC)',
  goal_6K_XC: '6K (XC)',
  goal_7K_XC: '7K (XC)',
  goal_8K_XC: '8K (XC)',
  goal_10K_XC: '10K (XC)',
  goal_4_MILE_XC: '4 MILE (XC)',

  // Field Events
  goal_HJ: 'HJ',
  goal_PV: 'PV',
  goal_LJ: 'LJ',
  goal_TJ: 'TJ',
  goal_SP: 'SP',
  goal_HT: 'HT',
  goal_JT: 'JT',

  // Combined Events
  goal_PENT: 'PENT',
  goal_HEP: 'HEP',
  goal_DEC: 'DEC'
};

const AthletesGoals = ({ athlete }) => {
  const [goals, setGoals] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const {
    useAthletesGoals,
    useCreateAthleteGoal,
    useUpdateAthleteGoal,
    useDeleteAthleteGoal,
  } = useApiService();
  const { data: athleteGoals, refetch: refetchGoals } = useAthletesGoals(athlete.id);
  const createGoal = useCreateAthleteGoal();
  const updateGoal = useUpdateAthleteGoal();
  const deleteGoal = useDeleteAthleteGoal();

  useEffect(() => {
    setGoals(athleteGoals || []);
  }, [athleteGoals]);

  const handleMenuClick = (event, goal) => {
    setAnchorEl(event.currentTarget);
    setSelectedGoal(goal);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = (goal = null) => {
    setSelectedGoal(goal);
    setOpenDialog(true);
    handleMenuClose();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedGoal(null);
  };

  const handleSubmit = (formData) => {
    const goalData = {
      athleteId: athlete.id,
      event: formData.event,
      unitType: formData.unitType,
    };

    if (formData.unitType === "time") {
      goalData.time = convertTimeToSeconds(formData.time);
    } else {
      goalData.distance = parseFloat(formData.distance);
    }

    if (selectedGoal) {
      updateGoal.mutate(
        { id: selectedGoal.id, data: goalData, athleteId: athlete.id },
        {
          onSuccess: () => {
            refetchGoals();
            handleCloseDialog();
          },
        }
      );
    } else {
      createGoal.mutate(
        { ...goalData, athleteId: athlete.id },
        {
          onSuccess: () => {
            refetchGoals();
            handleCloseDialog();
          },
        }
      );
    }
  };

  const handleDelete = () => {
    if (selectedGoal) {
      deleteGoal.mutate(
        { athleteId: athlete.id, id: selectedGoal.id },
        {
          onSuccess: () => {
            refetchGoals();
            handleMenuClose();
          },
        }
      );
    }
  };

  const formatDisplayValue = (goal) => {
    if (goal.unitType === "time") {
      return formatSecondsToTime(goal.time);
    } else if (goal.unitType === "distance" && goal.distance !== null) {
      return `${goal.distance.toFixed(2)} meters`;
    } else {
      return "N/A";
    }
  };

  const formatSecondsToTime = (seconds) => {
    if (seconds === null || isNaN(seconds)) return "N/A";

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = (seconds % 60).toFixed(2);

    if (hours === 0 && minutes === 0) {
      return `${remainingSeconds}`;
    } else if (hours === 0) {
      return `${minutes}:${remainingSeconds.padStart(5, "0")}`;
    } else {
      return `${hours}:${minutes.toString().padStart(2, "0")}:${remainingSeconds.padStart(5, "0")}`;
    }
  };

  const convertTimeToSeconds = (timeString) => {
    if (!timeString) return null;
    const parts = timeString.split(":").map(Number);
    let seconds = 0;
    if (parts.length === 3) {
      seconds = parts[0] * 3600 + parts[1] * 60 + parts[2];
    } else if (parts.length === 2) {
      seconds = parts[0] * 60 + parts[1];
    } else if (parts.length === 1) {
      seconds = parts[0];
    }
    return isNaN(seconds) ? null : seconds;
  };

  const formFields = [
    {
      name: "event",
      label: "Event",
      type: "singleSelect",
      options: Object.entries(eventOptions).map(([value, label]) => ({ value, label })),
    },
    {
      name: "unitType",
      label: "Unit Type",
      type: "singleSelect",
      options: [
        { value: "time", label: "Time" },
        { value: "distance", label: "Distance" },
      ],
    },
    {
      name: "time",
      label: "Time (HH:MM:SS.ss)",
      type: "text",
      placeholder: "00:00:00.00",
      conditional: (formData) => formData.unitType === "time",
    },
    {
      name: "distance",
      label: "Distance (meters)",
      type: "number",
      conditional: (formData) => formData.unitType === "distance",
    },
  ];

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Goals
        <IconButton
          onClick={() => handleOpenDialog()}
          size="small"
          sx={{ ml: 1 }}
        >
          <AddIcon />
        </IconButton>
      </Typography>
      {goals.map((goal) => (
        <ComponentWrapper key={goal.id} sx={{ mb: 1, p: 0 }}>
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <IconButton onClick={(e) => handleMenuClick(e, goal)}>
              <MoreVertIcon />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                mr: 2,
              }}
            >
              <Typography>{eventOptions[goal.event] || goal.event}</Typography>
              <Typography>{formatDisplayValue(goal)}</Typography>
            </Box>
          </Box>
        </ComponentWrapper>
      ))}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleOpenDialog(selectedGoal)}>
          <EditIcon fontSize="small" sx={{ mr: 1 }} />
          Edit
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
          Delete
        </MenuItem>
      </Menu>
      <Form
        open={openDialog}
        onClose={handleCloseDialog}
        fields={formFields}
        onSubmit={handleSubmit}
        title={selectedGoal ? "Edit Goal" : "Add New Goal"}
        defaultValues={
          selectedGoal
            ? {
                event: selectedGoal.event,
                unitType: selectedGoal.unitType,
                time:
                  selectedGoal.unitType === "time"
                    ? formatSecondsToTime(selectedGoal.time)
                    : "",
                distance:
                  selectedGoal.unitType === "distance" ? selectedGoal.distance : "",
              }
            : {
                event: "",
                unitType: "time",
                time: "",
                distance: "",
              }
        }
      />
    </Box>
  );
};

export default AthletesGoals;

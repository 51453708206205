import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  Paper,
  Chip,
  Button,
  Stack,
} from "@mui/material";
import { Link } from "react-router-dom";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import ComponentWrapper from "../../../components/utils/wrappers/componentWrapper";
import { AuthContext } from "../../../context/authContext";
import ViewModal from "../../../components/viewModal/viewModal";
import { AltitudeCalc } from "./components/AltitudeCalc";
import { DistantEquCalc } from "./components/DistantEquCalc";
import { EffortCalc } from "./components/EffortCalc";
import { InclineCalc } from "./components/InclineCalc";
// import { MeetSim } from './components/MeetSim';
import { MphToMiles } from "./components/MphToMiles";
import { PaceCalc } from "./components/PaceCalcs";
import { SplitCalc } from "./components/SplitCalc";
import { SuperShoeCalc } from "./components/SuperShoeCalc";
import { TffrsSim } from "./components/TffrsSim";
import { TimeCalc } from "./components/TimeCalc";
import { FScoreCalc } from "./components/findFscoreCalc";
import { FScorePaces } from "./components/fscorePaces";
// import { XCMeetSim } from './components/XCMeetSim';

const calculatorGroups = {
  "Pace & Time": {
    icon: <DirectionsRunIcon sx={{ color: "primary.main", fontSize: 32 }} />,
    calculators: [
      {
        name: "Pace Calculator",
        component: PaceCalc,
        description:
          "Calculate running pace, distance and time for any race distance from 100m to marathon",
      },
      {
        name: "Split Calculator",
        component: SplitCalc,
        description:
          "Generate precise lap splits and race strategies for track events and road races",
      },
      {
        name: "Race Time Calculator",
        component: TimeCalc,
        description: "Calculate your race finish time from split times",
      },
      {
        name: "Effort Calculator",
        component: EffortCalc,
        description:
          "Adjust your target pace based on perceived effort level and training intensity",
      },
    ],
  },
  "Treadmill & Training": {
    icon: <FitnessCenterIcon sx={{ color: "secondary.main", fontSize: 32 }} />,
    calculators: [
      {
        name: "MPH to Mile Pace",
        component: MphToMiles,
        description:
          "Convert treadmill speeds (MPH) to running pace per mile and kilometer",
      },
      {
        name: "Incline Calculator",
        component: InclineCalc,
        description:
          "Calculate effort adjustments for treadmill incline and outdoor hill training",
      },
    ],
  },
  "Meet Simulation": {
    icon: <EmojiEventsIcon sx={{ color: "warning.main", fontSize: 32 }} />,
    calculators: [
      {
        name: "TFFRS Simulator",
        component: TffrsSim,
        description:
          "Simulate track & field meet results using TFFRS data and performance predictions",
      },
    ],
  },
  "Conversions & Adjustments": {
    icon: <CompareArrowsIcon sx={{ color: "info.main", fontSize: 32 }} />,
    calculators: [
      {
        name: "Distance Equivalency",
        component: DistantEquCalc,
        description:
          "Convert running performances between different race distances and predict equivalent times",
      },
      {
        name: "Altitude Calculator",
        component: AltitudeCalc,
        description:
          "Adjust running times for altitude effects from sea level to high elevation",
      },
      {
        name: "Super Shoe Calculator",
        component: SuperShoeCalc,
        description:
          "Calculate performance differences between traditional and carbon-plated super shoes",
      },
      {
        name: "Find F-Score",
        component: FScoreCalc,
        description:
          "Calculate your F-Score, a fitness metric for distance runners that determines optimal training paces and predicts race performances",
        requiresAuth: true,
      },
      {
        name: "F-Score Paces",
        component: FScorePaces,
        description: "View your F-Score pace chart and predicted race times",
        requiresAuth: true,
      },
    ],
  },
};

const CalculatorSection = () => {
  const { user } = useContext(AuthContext);
  const [openCalc, setOpenCalc] = useState(null);
  const [showAuthModal, setShowAuthModal] = useState(false);

  const handleOpen = (calc) => {
    if (calc.requiresAuth && !user) {
      setShowAuthModal(true);
    } else {
      setOpenCalc(calc);
    }
  };

  const handleClose = () => {
    setOpenCalc(null);
    setShowAuthModal(false);
  };

  return (
    <ComponentWrapper sx={{ p: 2, mt: 4 }}>
      <Box>
        <Typography
          variant="caption"
          align="center"
          color="text.secondary"
          sx={{ mb: 4 }}
        >
          The most extensive suite of calculators for track and field & road
          racing on the web. See something missing? Contact us at the bottom of
          the page!
        </Typography>

        {Object.entries(calculatorGroups).map(
          ([groupName, { icon, calculators }]) => (
            <Paper
              key={groupName}
              elevation={0}
              sx={{
                mb: 4,
                p: 3,
                backgroundColor: "background.paperSecondary",
                borderRadius: 3,
              }}
            >
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 2, mb: 3 }}
              >
                {icon}
                <Typography variant="h5" sx={{ fontWeight: 500 }}>
                  {groupName}
                </Typography>
                {groupName === "Meet Simulation" && (
                  <Chip label="Beta" color="warning" size="small" />
                )}
              </Box>

              <Grid container spacing={2}>
                {calculators.map((calc) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={calc.name}>
                    <Card
                      sx={{
                        transition: "transform 0.2s, box-shadow 0.2s",
                        "&:hover": {
                          transform: "translateY(-4px)",
                          boxShadow: "0 8px 16px 0 rgba(0,0,0,0.1)",
                        },
                        border: 1,
                        borderColor: "border.main",
                      }}
                    >
                      <CardActionArea onClick={() => handleOpen(calc)}>
                        <CardContent>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
                            <Typography variant="h6" component="div">
                              {calc.name}
                            </Typography>
                            {calc.requiresAuth && !user && (
                              <Chip 
                                label="Members Only" 
                                color="primary" 
                                size="small"
                                sx={{ ml: 1 }}
                              />
                            )}
                          </Box>
                          <Typography
                            variant="caption"
                            color="text.secondary"
                          >
                            {calc.description}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          )
        )}

        {openCalc && (
          <openCalc.component open={Boolean(openCalc)} onClose={handleClose} />
        )}

        <ViewModal open={showAuthModal} onClose={handleClose}>
          <Stack spacing={3} sx={{ p: 3, textAlign: "center" }}>
            <Typography variant="h6">
              Create a Free Account or Login
            </Typography>
            <Typography>
              This calculator requires a free account to use. Please create an account or login to continue.
            </Typography>
            <Button
              component={Link}
              to="/login"
              variant="contained"
              color="primary"
              onClick={handleClose}
            >
              Login / Create Account
            </Button>
          </Stack>
        </ViewModal>
      </Box>
    </ComponentWrapper>
  );
};

export default CalculatorSection;

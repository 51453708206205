import React, { useState } from "react";
import { Typography, Box, Chip } from "@mui/material";
import useApiService from "../../../../../api/apiService";
import Form from "../../../../../components/form/form";

const AthleteWorkoutForm = ({ date, dates, isFormOpen, handleClose }) => {
  const {
    useAthletes,
    useEventGroups,
    useWorkouts,
    useTeamLocations,
    useCreateAthleteWorkout,
    queryClient,
  } = useApiService();
  const { data: athletes } = useAthletes();
  const { data: eventGroups } = useEventGroups();
  const { data: workouts, isLoading: isWorkoutsLoading } = useWorkouts();
  const { data: teamLocations } = useTeamLocations();
  const createAthleteWorkout = useCreateAthleteWorkout();

  const [formData, setFormData] = useState({
    selectedWorkout: "",
    selectedAthletes: [],
    selectedEventGroups: [],
    selectedLocation: "",
    time: ""
  });

  const isBulkMode = Array.isArray(dates);

  const handleAddWorkout = (data) => {
    const athletesToAdd = new Set(
      data.selectedEventGroups.length > 0
        ? data.selectedEventGroups.flatMap((groupId) => {
            const group = eventGroups?.find((group) => group.id === groupId);
            return group ? group.athletes.map(athlete => athlete.id) : [];
          })
        : data.selectedAthletes
    );

    if (isBulkMode) {
      dates.forEach((dateString) => {
        athletesToAdd.forEach((athleteId) => {
          createAthleteWorkout.mutate({
            workout_id: data.selectedWorkout,
            athlete_id: athleteId,
            date: dateString,
            team_location_ids: data.selectedLocation ? [data.selectedLocation] : [],
            time: data.time
          });
        });
      });
    } else {
      athletesToAdd.forEach((athleteId) => {
        createAthleteWorkout.mutate({
          workout_id: data.selectedWorkout,
          athlete_id: athleteId,
          date: date.format("YYYY-MM-DD"),
          team_location_ids: data.selectedLocation ? [data.selectedLocation] : [],
          time: data.time
        });
      });
    }

    handleClose();
    setTimeout(() => {
      queryClient.invalidateQueries("calandar");
    }, 2000);
  };

  const handleEventGroupChange = (selectedGroups) => {
    const newSelectedAthletes = selectedGroups.flatMap((groupId) => {
      const group = eventGroups?.find((group) => group.id === groupId);
      return group ? group.athletes.map((athlete) => athlete.id) : [];
    });
    setFormData(prevData => ({
      ...prevData,
      selectedEventGroups: selectedGroups,
      selectedAthletes: newSelectedAthletes,
    }));
  };

  if (isWorkoutsLoading) {
    return <Typography>Loading workouts...</Typography>;
  }

  const fields = [
    {
      name: "selectedEventGroups",
      label: "Select Event Groups",
      type: "custom",
      render: (data, onChange) => (
        <Box sx={{ mb: 2, zIndex: 1302}}>
          <Typography variant="body2" sx={{ mb: 1 }}>Select Event Groups</Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {eventGroups && eventGroups.map((group) => (
              <Chip
                key={group.id}
                label={group.name}
                onClick={() => {
                  const newGroups = data.selectedEventGroups.includes(group.id)
                    ? data.selectedEventGroups.filter(id => id !== group.id)
                    : [...data.selectedEventGroups, group.id];
                  onChange("selectedEventGroups", newGroups);
                  handleEventGroupChange(newGroups);
                }}
                color={data.selectedEventGroups.includes(group.id) ? "primary" : "default"}
              />
            ))}
          </Box>
        </Box>
      ),
    },
    {
      name: "selectedAthletes",
      label: "Select Athletes",
      type: "multiSelect",
      options: athletes ? athletes.map(athlete => ({
        value: athlete.id,
        label: `${athlete.firstName} ${athlete.lastName}`,
      })) : [],
    },
    {
      name: "selectedWorkout",
      label: "Select Workout",
      type: "singleSelect",
      options: workouts ? workouts.map(workout => ({
        value: workout.id,
        label: workout.name,
      })) : [],
    },
    {
      name: "selectedLocation",
      label: "Select Location",
      type: "singleSelect",
      options: teamLocations ? teamLocations.map(location => ({
        value: location.id,
        label: location.name,
      })) : [],
    },
    {
      name: "time",
      label: "Time",
      type: "text",
      defaultValue: "3:00 PM"
    }
  ];

  return (
    <Form
      open={isFormOpen}
      onClose={handleClose}
      fields={fields}
      onSubmit={handleAddWorkout}
      title={isBulkMode ? "Add Bulk Workouts" : "Add Workout"}
      defaultValues={formData}
    />
  );
};

export default AthleteWorkoutForm;

import React, { useState } from "react";
import { Button } from "@mui/material";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { AiChat } from "./aiChat";
import AIWorkout from "./aiWorkout/aiWorkout";
import useApiService from "../../api/apiService";

export const AiButtonCreate = ({ onClick, children, sx }) => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const apiService = useApiService();

  const handleButtonClick = () => {
    setIsChatOpen(true);
    if (onClick) onClick();
  };

  const handleCloseChat = () => {
    setIsChatOpen(false);
  };

  const exampleWorkouts = [
    "Create 25x400m intervals at goal 10k pace with 75s jog recovery",
    "Create a medball routine focusing on core strength",
    "Generate a throws session include a warmup, and technical drills to perform",
  ];

  return (
    <>
      <Button
        variant="contained"
        onClick={handleButtonClick}
        startIcon={children ? <AutoFixHighIcon /> : null}
        sx={{
          background: (theme) =>
            `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
          "&:hover": {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light}, ${theme.palette.secondary.light})`,
            boxShadow: (theme) => `0 0 5px ${theme.palette.primary.main}`,
          },
          fontWeight: "bold",
          textShadow: "0 0 5px rgba(0,255,255,0.5)",
          letterSpacing: "1px",
          ...(children ? {} : { maxWidth: "50px" }),
          ml: 1,
          mr: 1,
          ...sx,
        }}
      >
        {children || <AutoFixHighIcon sx={{ fontSize: "1rem" }} />}
      </Button>
      <AiChat
        open={isChatOpen}
        onClose={handleCloseChat}
        component={AIWorkout}
        exampleMessages={exampleWorkouts}
        endpoint={apiService.createAiWorkout}
      />
    </>
  );
};

import React, { useState, useEffect } from "react";
import { TextField, Collapse, Stack, Chip, Typography } from "@mui/material";
import ViewModal from "../../../../components/viewModal/viewModal";

export const InclineCalc = ({ open, onClose }) => {
  const [baseSpeed, setBaseSpeed] = useState(12);
  const [targetSpeed, setTargetSpeed] = useState(15);
  const [incline, setIncline] = useState(11.17);
  const [showResult, setShowResult] = useState(false);

  useEffect(() => {
    // this is somewhat made up, somewhat based on experience
    const calculateIncline = () => {
      // Constants for incline calculation
      const a = 0.228887; // Base incline factor
      const b = 0.013333; // Speed adjustment factor
      // Calculate the incline factor based on base speed
      const inclineFactor = a * Math.exp(b * baseSpeed);
      // Determine the required incline to achieve the target speed
      const inclineResult = (targetSpeed - baseSpeed) / inclineFactor;
      // Ensure the incline result is non-negative and rounded to two decimal places
      return inclineResult > 0 ? inclineResult.toFixed(2) : 0;
    };

    // Calculate incline when both base and target speeds are provided
    if (baseSpeed && targetSpeed) {
      const calculatedIncline = calculateIncline();
      setIncline(calculatedIncline);
      setShowResult(true);
    } else {
      setShowResult(false);
    }
  }, [baseSpeed, targetSpeed]);

  return (
    <ViewModal open={open} onClose={onClose}>
      <Typography
        variant="h5"
        component="h2"
        sx={{ width: "100%", my: 2, fontWeight: "bold" }}
      >
        Treadmill Incline Calculator
      </Typography>
      <Stack direction="column" spacing={2}>
        <TextField
          label="Treadmill Max MPH"
          type="number"
          value={baseSpeed}
          onChange={(e) => setBaseSpeed(parseFloat(e.target.value))}
          variant="outlined"
          helperText="Enter your treadmill's max speed"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="Target Speed MPH"
          type="number"
          value={targetSpeed}
          onChange={(e) => setTargetSpeed(parseFloat(e.target.value))}
          variant="outlined"
          helperText="Enter the desired speed you aim to simulate"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Stack>
      <Collapse in={showResult}>
        <Chip label={`Gradient: ${incline}%`} variant="outlined" />
      </Collapse>
    </ViewModal>
  );
};

import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
  Button,
  Menu,
  MenuItem,
  Badge,
  Paper,
  Tooltip,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FilterListIcon from "@mui/icons-material/FilterList";
import dayjs from "dayjs";
import { Day } from "./_components";
import useApiService from "../../api/apiService";
import { SelectAthlete } from "../../pages/roster/_components/eventGroupSection/_components/selectAthlete";
import { SelectEventGroup } from "./_components/selectEventGroup";
import DesktopNav from "./_components/desktopNav";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

const Calander = ({ ModalComponent, DisplayModels }) => {
  const queryClient = useQueryClient();
  const [currentMonth, setCurrentMonth] = useState(dayjs().startOf("month"));
  const [selectedDate, setSelectedDate] = useState(null);
  const [isBulkSelectMode, setIsBulkSelectMode] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSelectAthleteOpen, setIsSelectAthleteOpen] = useState(false);
  const [isSelectEventGroupOpen, setIsSelectEventGroupOpen] = useState(false);
  const [selectedAthleteIds, setSelectedAthleteIds] = useState([]);
  const [selectedEventGroupIds, setSelectedEventGroupIds] = useState([]);
  const [isSideNavCollapsed, setIsSideNavCollapsed] = useState(true);
  const [isDayExpanded, setIsDayExpanded] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    useCalanders,
    useAthletes,
    useEventGroups,
    useUpdateManyAthleteWorkouts,
  } = useApiService();
  const start_date = currentMonth.startOf("month").format("YYYY-MM-DD");
  const end_date = currentMonth.endOf("month").format("YYYY-MM-DD");
  const queryParams = {
    startDate: start_date,
    endDate: end_date,
    modelNames: DisplayModels,
    ids: selectedAthleteIds,
  };
  const { data: calanderData, isLoading: calanderDataLoading } = useCalanders(queryParams);
  const { data: athletes } = useAthletes();
  const { data: eventGroups } = useEventGroups();
  const { mutate: updateManyAthleteWorkouts } = useUpdateManyAthleteWorkouts();

  const handlePrevMonth = () => {
    setCurrentMonth(currentMonth.subtract(1, "month"));
  };

  const handleNextMonth = () => {
    setCurrentMonth(currentMonth.add(1, "month"));
  };

  const handleDayClick = (day) => {
    if (isBulkSelectMode) {
      const dateString = day.format("YYYY-MM-DD");
      setSelectedDates((prev) =>
        prev.includes(dateString)
          ? prev.filter((d) => d !== dateString)
          : [...prev, dateString]
      );
    } else {
      setSelectedDate(day);
      setIsModalOpen(true);
    }
  };

  const handleBulkSelectComplete = () => {
    if (selectedDates.length > 0) {
      setIsModalOpen(true);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleBulkSelectToggle = () => {
    setIsBulkSelectMode(!isBulkSelectMode);
    if (isBulkSelectMode) {
      setSelectedDates([]);
    }
    handleMenuClose();
  };

  const handleCancelBulkAdd = () => {
    setIsBulkSelectMode(false);
    setSelectedDates([]);
  };

  const handleViewAsClick = () => {
    setIsSelectAthleteOpen(true);
    handleMenuClose();
  };

  const handleViewAsEventGroupClick = () => {
    setIsSelectEventGroupOpen(true);
    handleMenuClose();
  };

  const handleToggleAthlete = (athleteId) => {
    setSelectedAthleteIds((prev) =>
      prev.includes(athleteId)
        ? prev.filter((id) => id !== athleteId)
        : [...prev, athleteId]
    );
  };

  const handleSaveSelectedAthletes = () => {
    setIsSelectAthleteOpen(false);
  };

  const handleSaveSelectedEventGroups = (eventGroupIds) => {
    setSelectedEventGroupIds(eventGroupIds);
    const selectedGroups = eventGroups.filter((group) =>
      eventGroupIds.includes(group.id)
    );
    const athleteIds = new Set();
    selectedGroups.forEach((group) => {
      group.athletes.forEach((athlete) => athleteIds.add(athlete.id));
    });
    setSelectedAthleteIds(Array.from(athleteIds));
    setIsSelectEventGroupOpen(false);
  };

  const generateDaysInMonth = () => {
    const startOfMonth = currentMonth.startOf("month");
    const endOfMonth = currentMonth.endOf("month");
    const days = [];
    let day = startOfMonth.startOf("week");

    while (day <= endOfMonth.endOf("week")) {
      days.push(day);
      day = day.add(1, "day");
    }

    return days;
  };

  const daysInMonth = generateDaysInMonth();

  const handleCreateWorkout = () => {
    setSelectedDate(dayjs());
    setIsModalOpen(true);
  };

  const toggleSideNav = () => {
    setIsSideNavCollapsed(!isSideNavCollapsed);
  };

  const handleWorkoutMove = (day, item) => {
    const { workouts } = item;
    const targetDate = day.format("YYYY-MM-DD");

    let updatedWorkouts;
    if (Array.isArray(workouts)) {
      updatedWorkouts = workouts.map((workout) => ({
        id: workout.id,
        date: targetDate,
      }));
    } else if (workouts) {
      updatedWorkouts = [
        {
          id: workouts.id,
          date: targetDate,
        },
      ];
    } else {
      updatedWorkouts = [
        {
          id: item.workout.id,
          date: targetDate,
        },
      ];
    }

    updateManyAthleteWorkouts(
      { athlete_workouts: updatedWorkouts },
      {
        onSuccess: () => {
          console.log(`Workouts moved successfully to ${targetDate}`);
        },
        onError: (error) => {
          console.error("Error moving workouts:", error);
          toast.error("Failed to move workouts. Please try again.");
        },
      }
    );

    queryClient.invalidateQueries(["calander"]);
  };

  const handleExpandToggle = () => {
    setIsDayExpanded(!isDayExpanded);
    handleMenuClose();
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box display="flex">
        <Box
          flexGrow={1}
          sx={{
            marginRight: isMobile ? 0 : isSideNavCollapsed ? "64px" : "180px",
            mt: isMobile ? 0 : -10,
          }}
        >
          <Paper
            elevation={3}
            sx={{
              borderRadius: 2,
              overflow: "hidden",
              bgcolor: theme.palette.background.paper,
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              bgcolor={theme.palette.primary.main}
              color={theme.palette.primary.contrastText}
              p={1.5}
              sx={{
                boxShadow: "0 3px 5px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box display="flex" alignItems="center">
                <CalendarTodayIcon sx={{ mr: 1.5 }} />
                <Typography
                  variant={isMobile ? "subtitle1" : "h6"}
                  fontWeight="bold"
                  sx={{ letterSpacing: 0.5 }}
                >
                  {currentMonth.format("MMMM YYYY")}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Tooltip title="Previous Month">
                  <IconButton
                    onClick={handlePrevMonth}
                    size="small"
                    color="inherit"
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                      },
                    }}
                  >
                    <ArrowBackIosNewIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Next Month">
                  <IconButton
                    onClick={handleNextMonth}
                    size="small"
                    color="inherit"
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                      },
                    }}
                  >
                    <ArrowForwardIosIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                {isMobile && (
                  <Tooltip title="Options">
                    <IconButton
                      onClick={handleMenuOpen}
                      size="small"
                      color="inherit"
                      sx={{
                        "&:hover": {
                          backgroundColor: "rgba(255, 255, 255, 0.1)",
                        },
                      }}
                    >
                      <FilterListIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </Box>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleBulkSelectToggle}>
                {isBulkSelectMode
                  ? "Disable Bulk Select"
                  : "Enable Bulk Select"}
              </MenuItem>
              <MenuItem onClick={handleViewAsClick}>View as athlete</MenuItem>
              <MenuItem onClick={handleViewAsEventGroupClick}>
                View as Event Group
              </MenuItem>
              <MenuItem onClick={handleExpandToggle}>
                {isDayExpanded ? "Collapse Day View" : "Expand Day View"}
              </MenuItem>
            </Menu>
            {isBulkSelectMode && (
              <Box
                display="flex"
                justifyContent="center"
                bgcolor={theme.palette.background.default}
                p={1.5}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleBulkSelectComplete}
                  disabled={selectedDates.length === 0}
                  size="small"
                  sx={{
                    mr: 1,
                    fontSize: isMobile ? "0.65rem" : "0.75rem",
                    boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                    "&:hover": {
                      boxShadow: "0 2px 4px rgba(0,0,0,0.15)",
                    },
                  }}
                >
                  Add Workout
                  <Badge
                    badgeContent={selectedDates.length}
                    color="secondary"
                    sx={{ ml: 1 }}
                  />
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCancelBulkAdd}
                  size="small"
                  sx={{
                    fontSize: isMobile ? "0.65rem" : "0.75rem",
                    borderColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.main,
                    "&:hover": {
                      backgroundColor: theme.palette.secondary.light,
                    },
                  }}
                >
                  Cancel Bulk Add
                </Button>
              </Box>
            )}
            <Box>
              <Grid container spacing={0}>
                {["S", "M", "T", "W", "T", "F", "S"].map((dayName) => (
                  <Grid item xs={12 / 7} key={dayName}>
                    <Typography
                      variant={isMobile ? "caption" : "body2"}
                      align="center"
                      fontWeight="bold"
                      sx={{
                        borderBottom: 1,
                        borderColor: theme.palette.divider,
                        py: 0.75,
                      }}
                    >
                      {dayName}
                    </Typography>
                  </Grid>
                ))}
                {daysInMonth.map((day, index) => {
                  const dateString = day.format("YYYY-MM-DD");
                  return (
                    <Grid item xs={12 / 7} key={dateString}>
                      <Box
                        sx={{
                          border: 1,
                          borderColor: theme.palette.divider,
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Day
                          day={day}
                          currentMonth={currentMonth}
                          onClick={() => handleDayClick(day)}
                          data={calanderData ? calanderData[dateString] : null}
                          isBulkSelected={selectedDates.includes(dateString)}
                          isMobile={isMobile}
                          onMoveWorkout={handleWorkoutMove}
                          expanded={isDayExpanded}
                          isLoading={calanderDataLoading}
                        />
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Paper>
        </Box>
        {!isMobile && (
          <DesktopNav
            isSideNavCollapsed={isSideNavCollapsed}
            toggleSideNav={toggleSideNav}
            isBulkSelectMode={isBulkSelectMode}
            handleBulkSelectToggle={handleBulkSelectToggle}
            handleViewAsClick={handleViewAsClick}
            handleViewAsEventGroupClick={handleViewAsEventGroupClick}
            handleCreateWorkout={handleCreateWorkout}
            theme={theme}
            expanded={isDayExpanded}
            handleExpandToggle={handleExpandToggle}
          />
        )}
        {selectedDate && !isBulkSelectMode && isModalOpen && (
          <ModalComponent
            date={selectedDate}
            onClose={() => {
              setSelectedDate(null);
              setIsModalOpen(false);
            }}
          />
        )}
        {isBulkSelectMode && isModalOpen && (
          <ModalComponent
            dates={selectedDates}
            onClose={() => {
              setSelectedDates([]);
              setIsBulkSelectMode(false);
              setIsModalOpen(false);
            }}
          />
        )}
        {isSelectAthleteOpen && (
          <SelectAthlete
            open={isSelectAthleteOpen}
            onClose={() => setIsSelectAthleteOpen(false)}
            allAthletes={athletes || []}
            selectedAthletes={selectedAthleteIds}
            onToggleAthlete={handleToggleAthlete}
            onSave={handleSaveSelectedAthletes}
          />
        )}
        {isSelectEventGroupOpen && (
          <SelectEventGroup
            open={isSelectEventGroupOpen}
            onClose={() => setIsSelectEventGroupOpen(false)}
            eventGroups={eventGroups || []}
            selectedEventGroupIds={selectedEventGroupIds}
            onSave={handleSaveSelectedEventGroups}
          />
        )}
      </Box>
    </DndProvider>
  );
};

export default Calander;

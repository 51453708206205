import React, { createContext, useState, useEffect, useCallback } from 'react';
import { openDB } from 'idb';

const AuthContext = createContext();

// Initialize IndexedDB
const initDB = async () => {
  return openDB('auth-store', 1, {
    upgrade(db) {
      db.createObjectStore('auth', { keyPath: 'id' });
    },
  });
};

const saveToIndexedDB = async (key, value) => {
  const db = await initDB();
  await db.put('auth', { id: key, value });
};

const getFromIndexedDB = async (key) => {
  const db = await initDB();
  const item = await db.get('auth', key);
  return item?.value;
};

const removeFromIndexedDB = async (key) => {
  const db = await initDB();
  await db.delete('auth', key);
};

const AuthProvider = ({ children }) => {
  // Fallback state values if storage fails
  const [fallbackUser, setFallbackUser] = useState(null);
  const [fallbackToken, setFallbackToken] = useState(null);
  const [fallbackUserType, setFallbackUserType] = useState(null);
  const [fallbackTeam, setFallbackTeam] = useState(null);

  const [user, setUser] = useState(() => {
    try {
      const savedUser = localStorage.getItem('user');
      return savedUser ? JSON.parse(savedUser) : fallbackUser;
    } catch (error) {
      console.error('Error reading user from localStorage:', error);
      return fallbackUser;
    }
  });

  const [token, setToken] = useState(() => {
    try {
      return localStorage.getItem('token') || fallbackToken;
    } catch (error) {
      // If localStorage fails, show iOS PWA installation instructions
      if (error.name === 'SecurityError' || error.name === 'QuotaExceededError') {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        if (isIOS) {
          alert(
            "To install this app on your iPhone:\n\n" +
            "1. Tap the Share button at the bottom of your screen\n" +
            "2. Scroll down and tap 'Add to Home Screen'\n" +
            "3. Tap 'Add' in the top right corner\n\n" +
            "This will allow the app to work offline and save your data!"
          );
        }
      }
      console.error('Error reading token from localStorage:', error);
      return fallbackToken;
    }
  });

  const [userType, setUserType] = useState(() => {
    try {
      return localStorage.getItem('userType') || fallbackUserType;
    } catch (error) {
      console.error('Error reading userType from localStorage:', error);
      return fallbackUserType;
    }
  });

  const [team, setTeam] = useState(() => {
    try {
      const savedTeam = localStorage.getItem('team');
      return savedTeam ? JSON.parse(savedTeam) : fallbackTeam;
    } catch (error) {
      console.error('Error reading team from localStorage:', error);
      return fallbackTeam;
    }
  });

  // Initialize data from IndexedDB on mount
  useEffect(() => {
    const initializeFromIndexedDB = async () => {
      try {
        const [storedUser, storedToken, storedUserType, storedTeam] = await Promise.all([
          getFromIndexedDB('user'),
          getFromIndexedDB('token'),
          getFromIndexedDB('userType'),
          getFromIndexedDB('team')
        ]);

        if (storedUser && !user) {
          setUser(storedUser);
          setFallbackUser(storedUser);
        }
        if (storedToken && !token) {
          setToken(storedToken);
          setFallbackToken(storedToken);
        }
        if (storedUserType && !userType) {
          setUserType(storedUserType);
          setFallbackUserType(storedUserType);
        }
        if (storedTeam && !team) {
          setTeam(storedTeam);
          setFallbackTeam(storedTeam);
        }
      } catch (error) {
        console.error('Error initializing from IndexedDB:', error);
      }
    };

    initializeFromIndexedDB();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    try {
      // Attempt to store in localStorage
      if (user) {
        localStorage.setItem('user', JSON.stringify(user));
        saveToIndexedDB('user', user).catch(() => setFallbackUser(user));
      } else {
        localStorage.removeItem('user');
        removeFromIndexedDB('user').catch(() => setFallbackUser(null));
      }

      if (token) {
        localStorage.setItem('token', token);
        saveToIndexedDB('token', token).catch(() => setFallbackToken(token));
      } else {
        localStorage.removeItem('token');
        removeFromIndexedDB('token').catch(() => setFallbackToken(null));
      }

      if (userType) {
        localStorage.setItem('userType', userType);
        saveToIndexedDB('userType', userType).catch(() => setFallbackUserType(userType));
      } else {
        localStorage.removeItem('userType');
        removeFromIndexedDB('userType').catch(() => setFallbackUserType(null));
      }

      if (team) {
        localStorage.setItem('team', JSON.stringify(team));
        saveToIndexedDB('team', team).catch(() => setFallbackTeam(team));
      } else {
        localStorage.removeItem('team');
        removeFromIndexedDB('team').catch(() => setFallbackTeam(null));
      }
    } catch (error) {
      if (error.name === 'SecurityError' || error.name === 'QuotaExceededError') {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        if (isIOS) {
          alert(
            "You must install this app on your iPhone to stay logged in:\n" +
            "1. Tap the Share button at the bottom of your screen\n" +
            "2. Scroll down and tap 'Add to Home Screen'\n" +
            "3. Tap 'Add' in the top right corner\n\n"
          );
        }
      }
      console.error('Error storing data:', error);
      // Update fallback state if storage fails
      setFallbackUser(user);
      setFallbackToken(token);
      setFallbackUserType(userType);
      setFallbackTeam(team);
    }
  }, [user, token, userType, team]);

  const login = (userData, token, type, teamData) => {
    setUser(userData);
    setToken(token);
    setUserType(type);
    setTeam(teamData);
    // Also set fallback values
    setFallbackUser(userData);
    setFallbackToken(token);
    setFallbackUserType(type);
    setFallbackTeam(teamData);
  };

  const updateUser = (userData) => {
    setUser(prevUser => {
      const newUser = { ...prevUser, ...userData };
      setFallbackUser(newUser);
      return newUser;
    });
  };

  const updateTeam = (teamData) => {
    setTeam(teamData);
    setFallbackTeam(teamData);
  };

  const logout = useCallback(() => {
    setUser(null);
    setToken(null);
    setUserType(null);
    setTeam(null);
    
    // Reset fallback states
    setFallbackUser(null);
    setFallbackToken(null);
    setFallbackUserType(null);
    setFallbackTeam(null);
    
    try {
      // Clear localStorage
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('userType');
      localStorage.removeItem('team');
      
      // Clear IndexedDB
      Promise.all([
        removeFromIndexedDB('user'),
        removeFromIndexedDB('token'), 
        removeFromIndexedDB('userType'),
        removeFromIndexedDB('team')
      ]).catch(error => console.error('Error clearing IndexedDB:', error));
    } catch (error) {
      console.error('Error during logout:', error);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, token, userType, team, login, logout, updateTeam, updateUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Box,
  Chip,
  CircularProgress,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ViewModal from "../../components/viewModal/viewModal";
import useApiService from "../../api/apiService";
import { uploadFile } from "../../api/amazonS3";
import { useNavigate } from "react-router-dom";

const FileUploadForm = ({ isOpen, onClose }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [fileCategories, setFileCategories] = useState([]);
  const [error, setError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const { useCreateTeamFile, useGeneratePresignedUrl, useFileCategories } =
    useApiService();

  const createTeamFile = useCreateTeamFile();
  const generatePresignedUrl = useGeneratePresignedUrl();
  const { data: categories } = useFileCategories();
  const navigate = useNavigate();

  useEffect(() => {
    if (categories) setFileCategories(categories);
  }, [categories]);

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    const validFiles = files.filter(file => file.size <= 1024 * 1024 * 1024); // 1GB in bytes
    if (validFiles.length !== files.length) {
      setError("Some files were not added because they exceed the 1GB size limit.");
    } else {
      setError(null);
    }
    setSelectedFiles(validFiles);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const validFiles = files.filter(file => file.size <= 1024 * 1024 * 1024); // 1GB in bytes
    if (validFiles.length !== files.length) {
      setError("Some files were not added because they exceed the 1GB size limit.");
    } else {
      setError(null);
    }
    setSelectedFiles(validFiles);
  };

  const handleUpload = async () => {
    setIsUploading(true);
    for (let file of selectedFiles) {
      try {
        const presignedUrlResponse = await generatePresignedUrl.mutateAsync({
          fileType: file.type,
        });

        if (presignedUrlResponse && presignedUrlResponse.data.presignedUrl) {
          const uploadResponse = await uploadFile({
            file: file,
            presignedUrl: presignedUrlResponse.data.presignedUrl,
          });

          if (uploadResponse && uploadResponse.status === 200) {
            await createTeamFile.mutateAsync({
              name: file.name,
              nameOriginal: file.name,
              fileType: file.type,
              size: file.size,
              fileCategoryId: selectedCategory,
              s3Key: presignedUrlResponse.data.s3Key,
            });
          }
        }
      } catch (error) {
        console.error("Upload error:", error);
      }
    }
    setIsUploading(false);
    onClose();
    setSelectedFiles([]);
    setSelectedCategory(null);
    setError(null);
  };

  if (fileCategories.length === 0) {
    return (
      <ViewModal open={isOpen} onClose={onClose}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          You need to create at least one file category to upload your first file.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onClose();
            navigate("/team-file/categories");
          }}
        >
          Create File Category
        </Button>
      </ViewModal>
    );
  }

  return (
    <ViewModal open={isOpen} onClose={onClose}>
      <Box
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        sx={{
          border: "2px dashed grey",
          borderRadius: "4px",
          padding: "20px",
          textAlign: "center",
          cursor: "pointer",
        }}
      >
        <input
          accept="*/*"
          style={{ display: "none" }}
          id="raised-button-file"
          multiple
          type="file"
          onChange={handleFileSelect}
        />
        <label htmlFor="raised-button-file">
          <Button
            variant="contained"
            component="span"
            startIcon={<CloudUploadIcon />}
          >
            Choose Files
          </Button>
        </label>
        <Typography variant="body2" style={{ marginTop: "8px" }}>
          or drag and drop files here (max 1GB per file)
        </Typography>
        {selectedFiles.length > 0 && (
          <Typography variant="body2" style={{ marginTop: "8px" }}>
            {selectedFiles.length} file(s) selected
          </Typography>
        )}
        {error && (
          <Typography variant="body2" color="error" style={{ marginTop: "8px" }}>
            {error}
          </Typography>
        )}
      </Box>
      <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>
        Select a file category:
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {fileCategories.map((category) => (
          <Chip
            key={category.id}
            label={category.name}
            onClick={() => setSelectedCategory(category.id)}
            color={selectedCategory === category.id ? "primary" : "default"}
            variant={selectedCategory === category.id ? "filled" : "outlined"}
          />
        ))}
      </Box>
      <Box sx={{ position: 'relative', display: 'inline-block', mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={selectedFiles.length === 0 || !selectedCategory || isUploading}
        >
          Upload
        </Button>
        {isUploading && (
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Box>
    </ViewModal>
  );
};

export default FileUploadForm;

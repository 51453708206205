import React, { useState, useEffect, useCallback } from "react";
import {
  TextField,
  Stack,
  Divider,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Collapse,
  Button,
} from "@mui/material";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { TimeInputFields } from "./common";
import ViewModal from "../../../../components/viewModal/viewModal";

export const SplitCalc = ({ open, onClose }) => {
  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();
  const [distance, setDistance] = useState();
  const [distanceUnit, setDistanceUnit] = useState("meters"); // Default to meters
  const [split, setSplit] = useState();
  const [splitUnit, setSplitUnit] = useState("meters"); // Default to meters
  const [splitTimes, setSplitTimes] = useState([]);

  const calculateSplits = useCallback(() => {
    const totalTimeSeconds =
      (parseInt(hours) * 3600 || 0) +
      (parseInt(minutes) * 60 || 0) +
      (parseInt(seconds) || 0);
    const distanceInMeters =
      distanceUnit === "miles"
        ? distance * 1609.34
        : distanceUnit === "kilometers"
        ? distance * 1000
        : parseFloat(distance);
    const splitInMeters =
      splitUnit === "miles"
        ? split * 1609.34
        : splitUnit === "kilometers"
        ? split * 1000
        : parseFloat(split);
    const pacePerSplit = totalTimeSeconds / (distanceInMeters / splitInMeters);
    const splits = [];
    for (let i = splitInMeters; i <= distanceInMeters; i += splitInMeters) {
      const time = pacePerSplit * (i / splitInMeters);
      const minutes = Math.floor(time / 60);
      const seconds = Math.round(time % 60);
      splits.push({
        distance: i,
        time: `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`,
      });
    }
    setSplitTimes(splits);
  }, [hours, minutes, seconds, distance, distanceUnit, split, splitUnit]);

  useEffect(() => {
    if (
      (hours !== "" || minutes !== "" || seconds !== "") &&
      distance !== "" &&
      split >= 100
    ) {
      calculateSplits();
    }
  }, [
    hours,
    minutes,
    seconds,
    distance,
    distanceUnit,
    split,
    splitUnit,
    calculateSplits,
  ]);

  const handleDistanceChange = (event) => {
    setDistance(event.target.value);
  };

  const handleDistanceUnitChange = (event, newUnit) => {
    if (newUnit !== null) {
      setDistanceUnit(newUnit);
      // Convert existing distance to new unit
      let conversionFactor = 1;
      if (newUnit === "miles" && distanceUnit === "meters")
        conversionFactor = 1 / 1609.34;
      else if (newUnit === "meters" && distanceUnit === "miles")
        conversionFactor = 1609.34;
      else if (newUnit === "kilometers" && distanceUnit === "meters")
        conversionFactor = 1 / 1000;
      else if (newUnit === "meters" && distanceUnit === "kilometers")
        conversionFactor = 1000;
      setDistance((prevDistance) =>
        (prevDistance * conversionFactor).toFixed(newUnit === "meters" ? 0 : 2)
      );
    }
  };

  const handleSplitChange = (event) => {
    setSplit(event.target.value);
  };

  const handleSplitUnitChange = (event, newUnit) => {
    if (newUnit !== null) {
      setSplitUnit(newUnit);
      // Convert existing split to new unit
      let conversionFactor = 1;
      if (newUnit === "miles" && splitUnit === "meters")
        conversionFactor = 1 / 1609.34;
      else if (newUnit === "meters" && splitUnit === "miles")
        conversionFactor = 1609.34;
      else if (newUnit === "kilometers" && splitUnit === "meters")
        conversionFactor = 1 / 1000;
      else if (newUnit === "meters" && splitUnit === "kilometers")
        conversionFactor = 1000;
      setSplit((prevSplit) =>
        (prevSplit * conversionFactor).toFixed(newUnit === "meters" ? 0 : 2)
      );
    }
  };

  const downloadSplitsAsPDF = () => {
    const doc = new jsPDF();
    autoTable(doc, {
      head: [["Distance", "Time"]],
      body: splitTimes.map((split) => [split.distance, split.time]),
      didDrawPage: function (data) {
        doc.text("Split Plan", 14, 16);
        doc.text(`Distance: ${distance} ${distanceUnit}`, 14, 22);
        doc.text("trackcoachPro.com", 14, doc.internal.pageSize.height - 10);
      },
      margin: { top: 30 },
    });
    doc.save("split-plan.pdf");
  };

  return (
    <ViewModal open={open} onClose={onClose}>
      <Stack direction="column" spacing={2} sx={{ p: 2 }}>
        <Typography
          variant="h5"
          component="h2"
          sx={{ width: "100%", my: 2, fontWeight: "bold" }}
        >
          Split Plan
        </Typography>
        <Divider
          sx={{ width: "100%", my: 2, fontWeight: "bold" }}
          component="div"
        >
          Goal Time
        </Divider>
        <TimeInputFields
          hours={hours}
          setHours={setHours}
          minutes={minutes}
          setMinutes={setMinutes}
          seconds={seconds}
          setSeconds={setSeconds}
        />
        <Divider
          sx={{ width: "100%", my: 2, fontWeight: "bold" }}
          component="div"
        >
          Event Distance
        </Divider>
        <Stack direction="row" spacing={2}>
          <TextField
            label={`Distance (${distanceUnit})`}
            type="number"
            value={distance}
            onChange={handleDistanceChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <ToggleButtonGroup
            color="primary"
            value={distanceUnit}
            exclusive
            onChange={handleDistanceUnitChange}
            aria-label="distance unit"
          >
            <ToggleButton value="meters">Meters</ToggleButton>
            <ToggleButton value="miles">Miles</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <Divider
          sx={{ width: "100%", my: 2, fontWeight: "bold" }}
          component="div"
        >
          Split Distance
        </Divider>
        <Stack direction="row" spacing={2}>
          <TextField
            label={`Split Distance (${splitUnit})`}
            type="number"
            value={split}
            onChange={handleSplitChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <ToggleButtonGroup
            color="primary"
            value={splitUnit}
            exclusive
            onChange={handleSplitUnitChange}
            aria-label="split unit"
          >
            <ToggleButton value="meters">Meters</ToggleButton>
            <ToggleButton value="miles">Miles</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <Collapse in={splitTimes.length > 0}>
          <Button
            onClick={downloadSplitsAsPDF}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Download Splits as PDF
          </Button>
          <TableContainer sx={{ maxHeight: 240, overflowY: "scroll" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Distance (m)</TableCell>
                  <TableCell align="right">Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {splitTimes.map((split, index) => (
                  <TableRow key={index}>
                    <TableCell>{split.distance}</TableCell>
                    <TableCell align="right">{split.time}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
      </Stack>
    </ViewModal>
  );
};

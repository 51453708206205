import Home from '../../pages/home/home';
import React from 'react';
import { Route } from 'react-router-dom';
import PrivacyPolicy from '../../pages/pageWrapper/privacyPolicy/privacyPolicy';
import CalculatorsPage from '../../pages/calculators/calculatorsPage';
import ArticlesPage from '../../pages/articles/articlesPage';
import LocationPage from '../../pages/locations/locationPage';

const routesLoggedInOrOut = () => {
  return (
    <>
      <Route path="/home" element={<Home />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy page={true} />} />
      <Route path="/calculators" element={<CalculatorsPage />} />
      <Route path="/articles" element={<ArticlesPage />} />
      <Route path="/locations/*" element={<LocationPage />} />
    </>
  );
};

export default routesLoggedInOrOut;

import React, { useContext, useState } from 'react';
import { Box, Typography, Avatar, IconButton, Menu, MenuItem } from '@mui/material';
import RosterIcon from '@mui/icons-material/People';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AuthContext } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';
import FormTeam from '../teamUtils/formTeam';
import { FormUser, FormPassword } from '../user';

const UserCard = () => {
  const { userType, user, team } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isTeamFormOpen, setIsTeamFormOpen] = useState(false);
  const [isUserFormOpen, setIsUserFormOpen] = useState(false);
  const [isPasswordFormOpen, setIsPasswordFormOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleUserCardClick = () => {
    if (userType === "athlete") {
      navigate(`/roster/athletes/${user.id}`);
    }
  };

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleTeamFormOpen = () => {
    setIsTeamFormOpen(true);
    handleMenuClose();
  };

  const handleTeamFormClose = () => {
    setIsTeamFormOpen(false);
  };

  const handleUserFormOpen = () => {
    setIsUserFormOpen(true);
    handleMenuClose();
  };

  const handleUserFormClose = () => {
    setIsUserFormOpen(false);
  };

  const handlePasswordFormOpen = () => {
    setIsPasswordFormOpen(true);
    handleMenuClose();
  };

  const handlePasswordFormClose = () => {
    setIsPasswordFormOpen(false);
  };

  const handleTeamNameClick = (event) => {
    event.stopPropagation();
    if (userType === "coach") {
      setIsTeamFormOpen(true);
    }
  };

  return (
    <>
      <Box
        onClick={handleUserCardClick}
        sx={{
          mx: 1,
          p: 1.5,
          mb: 1,
          borderRadius: 2,
          cursor: userType === "athlete" ? "pointer" : "default",
          '&:hover': userType === "athlete" ? {
            backgroundColor: 'action.hover'
          } : {},
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar 
            src={team?.url}
            sx={{ 
              width: 40, 
              height: 40,
              bgcolor: 'primary.main',
              mr: 1
            }}
          >
            {user?.firstName?.[0]}{user?.lastName?.[0]}
          </Avatar>
          <Box sx={{ 
            flexGrow: 1,
            minWidth: 0 // Enables text truncation
          }}>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  fontWeight: 500,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {user?.firstName} {user?.lastName}
              </Typography>
              <IconButton 
                size="small" 
                onClick={handleMenuClick}
                sx={{ 
                  ml: 1
                }}
              >
                <MoreVertIcon fontSize="small" sx={{ color: 'white' }} />
              </IconButton>
            </Box>
            {team?.name && (
              <Typography 
                variant="caption" 
                onClick={handleTeamNameClick}
                sx={{ 
                  color: 'text.secondary',
                  display: 'flex',
                  alignItems: 'center',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  cursor: userType === "coach" ? "pointer" : "default",
                  '&:hover': userType === "coach" ? {
                    color: 'primary.main'
                  } : {}
                }}
              >
                <RosterIcon sx={{ fontSize: 14, mr: 0.5, flexShrink: 0 }} />
                {team.name}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {userType === "coach" && (
          <MenuItem onClick={handleTeamFormOpen}>Update Team</MenuItem>
        )}
        <MenuItem onClick={handleUserFormOpen}>Update User Info</MenuItem>
        <MenuItem onClick={handlePasswordFormOpen}>Update Password</MenuItem>
      </Menu>

      {userType === "coach" && (
        <FormTeam
          open={isTeamFormOpen}
          handleClose={handleTeamFormClose}
          onTeamUpdated={() => {}}
          team={team}
        />
      )}
      <FormUser open={isUserFormOpen} handleClose={handleUserFormClose} />
      <FormPassword
        open={isPasswordFormOpen}
        handleClose={handlePasswordFormClose}
      />
    </>
  );
};

export default UserCard;

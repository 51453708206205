import React, { useState, useEffect, useCallback } from "react";
import { Typography, Stack, Grid, Link, Slider, Chip } from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { TimeInputFields } from "./common";
import ViewModal from "../../../../components/viewModal/viewModal";

export const SuperShoeCalc = ({ open, onClose }) => {
  const [nonSuperShoeTime, setNonSuperShoeTime] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [superShoeTime, setSuperShoeTime] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [improvementRate, setImprovementRate] = useState(2); // Default improvement rate set to 2%

  const calculateSuperShoeTime = useCallback(
    (time) => {
      const totalSeconds = time.hours * 3600 + time.minutes * 60 + time.seconds;
      const improvedSeconds = totalSeconds * ((100 - improvementRate) / 100); // Dynamic improvement rate
      const improvedHours = Math.floor(improvedSeconds / 3600);
      const improvedMinutes = Math.floor((improvedSeconds % 3600) / 60);
      const improvedSecondsLeft = Math.round(improvedSeconds % 60);
      return {
        hours: improvedHours,
        minutes: improvedMinutes + (improvedSecondsLeft === 60 ? 1 : 0), // Adjust minutes if seconds round to 60
        seconds: improvedSecondsLeft === 60 ? 0 : improvedSecondsLeft, // Reset seconds to 0 if they round to 60
      };
    },
    [improvementRate]
  );

  useEffect(() => {
    setSuperShoeTime(calculateSuperShoeTime(nonSuperShoeTime));
  }, [nonSuperShoeTime, calculateSuperShoeTime]);

  const handleTimeChange = (field, value) => {
    setNonSuperShoeTime((prevTime) => ({
      ...prevTime,
      [field]: value,
    }));
  };

  const handleImprovementRateChange = (event, newValue) => {
    setImprovementRate(newValue);
  };

  return (
    <ViewModal open={open} onClose={onClose}>
      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography
          variant="h6"
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          Super Shoe Conversion Calculator
        </Typography>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={5}>
            <Typography variant="subtitle1">Non Super Shoe Time</Typography>
            <TimeInputFields
              setHours={(value) => handleTimeChange("hours", value)}
              setMinutes={(value) => handleTimeChange("minutes", value)}
              setSeconds={(value) => handleTimeChange("seconds", value)}
              direction="column"
            />
          </Grid>
          {window.innerWidth > 768 && (
            <Grid item>
              <SwapHorizIcon fontSize="large" />
            </Grid>
          )}
          <Grid item xs={5}>
            <Typography variant="subtitle1">Super Shoe Time</Typography>
            <Chip
              label={`${superShoeTime.hours}:${superShoeTime.minutes
                .toString()
                .padStart(2, "0")}:${superShoeTime.seconds
                .toString()
                .padStart(2, "0")}`}
            />
          </Grid>
        </Grid>
        <Typography
          variant="h5"
          component="h2"
          sx={{ width: "100%", my: 2, fontWeight: "bold" }}
        >
          Improvement Rate (%)
        </Typography>
        <Slider
          aria-label="Improvement Rate"
          defaultValue={2}
          valueLabelDisplay="auto"
          step={0.1}
          marks
          min={2}
          max={4}
          valueLabelFormat={(value) => `${value}%`}
          onChange={handleImprovementRateChange}
        />
        <Stack sx={{ overflowY: "auto", mt: 2, gap: 2 }}>
          <Typography variant="body2" sx={{ maxWidth: 400 }}>
            Super shoes can enhance running performance by 2% to 4%. It is
            important to note that track times often see improvements on the
            lower end of this spectrum 2-2.5%, while road running may experience
            benefits closer to the higher end. The actual gains are highly
            individualized, influenced by factors such as running style and
            biomechanics.
          </Typography>
          <Typography variant="body2">Research on Super Shoes:</Typography>
          <Link
            href="https://pubmed.ncbi.nlm.nih.gov/37734742/#:~:text=Conclusions%3A%20There%20was%20a%20physiological,benefit%20from%20wearing%20super%20shoes."
            underline="hover"
            sx={{ fontSize: "small", maxWidth: 400 }}
          >
            Recreational Runners Gain Physiological and Biomechanical Benefits
            From Super Shoes at Marathon Paces
          </Link>
          <Link
            href="https://www.researchgate.net/publication/362850130_Advancements_in_running_shoe_technology_and_their_effects_on_running_economy_and_performance_-_a_current_concepts_overview"
            underline="hover"
            sx={{ fontSize: "small", maxWidth: 400 }}
          >
            Advancements in running shoe technology and their effects on running
            economy and performance – a current concepts overview
          </Link>
          <Link
            href="https://sportrxiv.org/index.php/server/preprint/view/297"
            underline="hover"
            sx={{ fontSize: "small", maxWidth: 400 }}
          >
            Does advanced footwear technology improve track and road racing
            performance? An explorative analysis based on the 100 best yearly
            performances in the world between 2010 and 2022
          </Link>
        </Stack>
      </Stack>
    </ViewModal>
  );
};

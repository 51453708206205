import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Chip,
  Paper,
  Tooltip,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import ViewModal from "../viewModal/viewModal";
import { useMutation } from "@tanstack/react-query";
import useApiService from "../../api/apiService";
import AIWorkout from "./aiWorkout/aiWorkout";

export const AiChat = ({
  open,
  onClose,
  exampleMessages,
  endpoint,
  component = AIWorkout,
}) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const apiService = useApiService();

  const { mutate: sendMessage, isError } = useMutation(
    (message) => apiService.createAiWorkout({ message }),
    {
      onSuccess: (data) => {
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: data.data.message, sender: "ai", component: component },
        ]);
        setIsWaitingForResponse(false);
      },
      onError: (error) => {
        console.error("Error:", error);
        setIsWaitingForResponse(false);
      },
    }
  );

  const handleSendMessage = () => {
    if (input.trim() && !isWaitingForResponse) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: input, sender: "user" },
      ]);
      sendMessage(input);
      setInput("");
      setIsWaitingForResponse(true);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !isWaitingForResponse) {
      handleSendMessage();
    }
  };

  const handleExampleClick = (message) => {
    setInput(message);
  };

  const handleClearChat = () => {
    setMessages([]);
  };

  return (
    <ViewModal open={open} onClose={onClose}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        p={2}
      >
        <Box flexGrow={1} overflow="auto" mb={2}>
          {messages.length === 0 && exampleMessages && (
            <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
              {exampleMessages.map((message, index) => (
                <Chip
                  key={index}
                  label={message}
                  onClick={() => handleExampleClick(message)}
                  size="small"
                  sx={{ fontSize: "0.75rem" }}
                />
              ))}
            </Box>
          )}
          {messages.map((message, index) => (
            <Box
              key={index}
              display="flex"
              justifyContent={
                message.sender === "user" ? "flex-end" : "flex-start"
              }
              mb={1}
            >
              <Paper
                elevation={3}
                sx={{
                  p: 1,
                  maxWidth: "90%",
                  borderRadius: 2,
                  backgroundColor:
                    message.sender === "user"
                      ? "primary.light"
                      : "background.paper",
                }}
              >
                {message.sender === "user" || !message.component ? (
                  <Typography
                    variant="body1"
                    sx={{
                      wordWrap: "break-word",
                      textAlign: "left",
                    }}
                  >
                    {message.text}
                  </Typography>
                ) : (
                  React.createElement(message.component, { data: message.text })
                )}
              </Paper>
            </Box>
          ))}
          {isError && (
            <Box display="flex" justifyContent="flex-start" mb={1}>
              <Paper
                elevation={3}
                sx={{
                  p: 1,
                  maxWidth: "90%",
                  borderRadius: 2,
                  backgroundColor: "error.light",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    wordWrap: "break-word",
                    textAlign: "left",
                  }}
                >
                  Something went wrong. Please try again later.
                </Typography>
              </Paper>
            </Box>
          )}
          {isWaitingForResponse && (
            <Box display="flex" justifyContent="flex-start" mt={2} ml={2}>
              <CircularProgress
                size={24}
                thickness={4}
                sx={{
                  color: (theme) => `${theme.palette.primary.main}`,
                }}
              />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            backgroundColor: "background.paperDark",
            borderRadius: "20px",
            p: 1,
          }}
        >
          <Tooltip title="Clear chat history" arrow>
            <Button
              variant="text"
              color="secondary"
              onClick={handleClearChat}
              sx={{
                borderRadius: "20px",
                minWidth: "40px",
                height: "40px",
                mr: 1,
              }}
            >
              <ClearAllIcon />
            </Button>
          </Tooltip>
          <TextField
            fullWidth
            variant="standard"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Describe a workout..."
            sx={{
              mr: 2,
              flexGrow: 1,
              "& .MuiInput-underline:before": {
                borderBottom: "none",
              },
              "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                borderBottom: "none",
              },
              "& .MuiInput-underline:after": {
                borderBottom: "none",
              },
            }}
            disabled={isWaitingForResponse}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendMessage}
            sx={{
              borderRadius: "20px",
              minWidth: "40px",
              height: "40px",
              boxShadow: 2,
              "&:hover": {
                boxShadow: 4,
              },
            }}
            disabled={isWaitingForResponse}
          >
            <SendIcon />
          </Button>
        </Box>
        <Typography
          variant="caption"
          sx={{
            mt: 1,
            textAlign: "center",
            color: "text.secondary",
          }}
        >
          BETA: Be specific to achieve good results, chat functionality coming soon
        </Typography>
      </Box>
      {/* <Chip
        label="Beta"
        color="secondary"
        size="small"
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          fontSize: "0.75rem",
        }}
      /> */}
    </ViewModal>
  );
};

import React, { useState, useCallback } from "react";
import {
  TextField,
  Stack,
  Divider,
  Typography,
  Button,
  Collapse,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ViewModal from "../../../../components/viewModal/viewModal";
import useApiService from "../../../../api/apiService";

const formatTime = (seconds) => {
  if (seconds >= 3600) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = ((seconds % 3600) % 60).toFixed(1);
    return `${hours}:${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.padStart(4, "0")}`;
  }
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = (seconds % 60).toFixed(1);
  return `${minutes}:${remainingSeconds.padStart(4, "0")}`;
};

export const FScorePaces = ({ open, onClose }) => {
  const [fScore, setFScore] = useState("");
  const [paceChart, setPaceChart] = useState(null);
  const [showResult, setShowResult] = useState(false);

  const { fScorePaces } = useApiService();

  const handleCalculatePaces = useCallback(async () => {
    try {
      const response = await fScorePaces({
        f_score: fScore,
      });
      if (response.data && response.data.paceChart) {
        setPaceChart(response.data.paceChart);
        setShowResult(true);
      }
    } catch (error) {
      console.error("Error calculating paces:", error);
    }
  }, [fScore, fScorePaces]);

  const displayedTrainingPaces = [
    "recovery",
    "easy",
    "moderate",
    "tempo",
    "threshold",
    "cv",
    "aerobic power",
    "vo2 max",
    "anaerobic endurance",
    "anaerobic endurance 1500",
    "anaerobic power",
    "speed endurance",
  ];

  return (
    <ViewModal open={open} onClose={onClose}>
      <Stack direction="column" spacing={2} sx={{ p: 2 }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="h5" component="h2" sx={{ fontWeight: "bold" }}>
            Training Paces Calculator
          </Typography>
          <Tooltip
            title={
              "The F-Score is an advanced fitness metric designed specifically for " +
              "distance runners that combines multiple training calculators and race " +
              "prediction algorithms into a single, high-precision score. This " +
              "system analyzes your current fitness level to generate " +
              "optimized training paces across all intensity zones and predict race " +
              "performances with exceptional accuracy. Unlike traditional calculators " +
              "that use basic formulas, the F-Score leverages sophisticated " +
              "statistical modeling to account for the complex relationships between " +
              "different race distances and training intensities."
            }
            arrow
          >
            <IconButton size="small">
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>

        <Divider />

        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            size="small"
            name="fScore"
            label="F-Score"
            value={fScore}
            onChange={(e) => setFScore(e.target.value)}
            placeholder="Enter F-Score"
            sx={{ flex: 1 }}
          />
          <Button
            onClick={handleCalculatePaces}
            variant="contained"
            color="primary"
            size="small"
          >
            Calculate
          </Button>
        </Stack>

        <Collapse in={showResult}>
          {paceChart && (
            <>
              <Card sx={{ mb: 2 }}>
                <CardContent>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontWeight: "bold" }}
                  >
                    Race Predictions
                  </Typography>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Distance
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Predicted Time
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.entries(paceChart.prediction).map(
                          ([distance, time]) => (
                            <TableRow key={distance}>
                              <TableCell sx={{ textTransform: "capitalize" }}>
                                {distance}
                              </TableCell>
                              <TableCell>{formatTime(time)}</TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>

              <Card>
                <CardContent>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontWeight: "bold" }}
                  >
                    Training Paces
                  </Typography>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Training Type
                          </TableCell>
                          <TableCell align="right" sx={{ fontWeight: "bold" }}>
                            Mile
                          </TableCell>
                          <TableCell align="right" sx={{ fontWeight: "bold" }}>
                            400m
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {displayedTrainingPaces.map((type) => {
                          const data = paceChart.training[type];
                          const range = data.range;
                          const milePace = data.values["mile"];
                          const mileMin = milePace - range;
                          const mileMax = milePace + range;
                          return (
                            <TableRow key={type}>
                              <TableCell>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  <Typography
                                    sx={{ textTransform: "capitalize" }}
                                  >
                                    {type}
                                  </Typography>
                                  <Tooltip title={data.description} arrow>
                                    <IconButton size="small">
                                      <InfoIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </TableCell>
                              <TableCell align="right">
                                <Typography>{formatTime(milePace)}</Typography>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {formatTime(mileMin)} - {formatTime(mileMax)}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                {formatTime(data.values["400"])}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </>
          )}
        </Collapse>
      </Stack>
    </ViewModal>
  );
};

import React from 'react';
import Roster from '../../pages/roster/roster';
import TrainingPlan from '../../pages/trainingPlan/trainingPlan';
import Bulletin from '../../pages/bulletin/bulletin';
import Files from '../../pages/files/pageFiles';
import { Route } from 'react-router-dom';

const routesLoggedIn = () => {
  return (
    <>
      <Route path="/roster/*" element={<Roster />} />
      <Route path="/training-plan/*" element={<TrainingPlan />} />
      <Route path="/bulletin/*" element={<Bulletin />} />
      <Route path="/team-file/*" element={<Files />} />
    </>
  );
};

export default routesLoggedIn;

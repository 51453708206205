import { useState, useEffect } from 'react';
import { Button, Box, Typography, IconButton } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import CloseIcon from '@mui/icons-material/Close';

const DownloadPWA = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallPrompt, setShowInstallPrompt] = useState(true);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    // Check if the user has already hidden the install prompt
    const hasHiddenPrompt = localStorage.getItem('hasHiddenInstallPrompt');
    if (hasHiddenPrompt) {
      setShowInstallPrompt(false);
    }

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
        // Hide the install prompt after user interaction
        setShowInstallPrompt(false);
      });
    }
  };

  const handleHideClick = () => {
    // Set the flag in local storage
    localStorage.setItem('hasHiddenInstallPrompt', 'true');
    // Hide the install prompt
    setShowInstallPrompt(false);
  };

  if (!deferredPrompt || !showInstallPrompt) {
    return null;
  }

  return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center', 
      width: '100%', 
      backgroundColor: (theme) => theme.palette.background.paperDark, 
      borderRadius: '10px', 
      p: 1,
      position: 'relative'
    }}>
      <Typography variant="body1" sx={{ flex: 1, pr: 2 }}>
        Install our app for a seamless experience and quick access to your training data!
      </Typography>
      <Button
        onClick={handleInstallClick}
        variant="contained"
        color="primary"
        startIcon={<GetAppIcon />}
        sx={{
          borderRadius: '20px',
          padding: '10px 20px',
          textTransform: 'none',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
          },
        }}
      >
        Install App
      </Button>
      <IconButton
        onClick={handleHideClick}
        size="small"
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};

export { DownloadPWA };

import React, { useState, useEffect } from "react";
import {
  TextField,
  Collapse,
  Stack,
  Chip,
  Divider,
  Typography,
} from "@mui/material";
import { TimeInputFields, DistanceUnitToggle } from "./common";
import ViewModal from "../../../../components/viewModal/viewModal";

export const AltitudeCalc = ({ open, onClose }) => {
  const [altitude, setAltitude] = useState("");
  const [altitudeUnit, setAltitudeUnit] = useState("meters");
  const [distance, setDistance] = useState("");
  const [distanceUnit, setDistanceUnit] = useState("meters");
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [seconds, setSeconds] = useState("");
  const [convertedTime, setConvertedTime] = useState("00:00:00");

  const convertDistance = (value, fromUnit, toUnit) => {
    if (fromUnit === toUnit) return value;
    const conversionFactors = {
      miles: {
        meters: 1609.34,
        kilometers: 1.60934,
      },
      meters: {
        miles: 1 / 1609.34,
        kilometers: 1 / 1000,
      },
      kilometers: {
        meters: 1000,
        miles: 0.621371,
      },
    };
    return value * (conversionFactors[fromUnit][toUnit] || 1);
  };

  const convertAltitude = (value, fromUnit, toUnit) => {
    if (fromUnit === toUnit) return value;
    const conversionFactors = {
      miles: {
        meters: 1609.34,
        kilometers: 1.60934,
      },
      meters: {
        miles: 1 / 1609.34,
        kilometers: 1 / 1000,
      },
      kilometers: {
        meters: 1000,
        miles: 0.621371,
      },
    };
    return value * (conversionFactors[fromUnit][toUnit] || 1);
  };

  useEffect(() => {
    const calculateConvertedTime = () => {
      const altitudeInMeters = convertAltitude(
        parseFloat(altitude),
        altitudeUnit,
        "meters"
      );
      const distanceInMeters = convertDistance(
        parseFloat(distance),
        distanceUnit,
        "meters"
      );
      const totalSeconds =
        (parseInt(hours) || 0) * 3600 +
        (parseInt(minutes) || 0) * 60 +
        (parseInt(seconds) || 0);

      if (!altitude || !distance || totalSeconds === 0) {
        setConvertedTime("00:00:00");
        return;
      }

      const logAltitude = Math.log(altitudeInMeters);
      const logDistance = Math.log(distanceInMeters);
      const altitudeDistanceProduct = altitudeInMeters * distanceInMeters;
      const logProduct = Math.log(altitudeDistanceProduct);
      const timeInMinutes = totalSeconds / 60;
      const adjustmentFactor =
        altitudeInMeters <= 750
          ? 1
          : 0.0194294334323786 * Math.pow(logAltitude, 4) -
            0.58204012227 * Math.pow(logAltitude, 3) +
            6.53099074887773 * Math.pow(logAltitude, 2) -
            32.4795428772407 * logAltitude +
            61.3421201263799;
      const additionalFactor =
        altitudeInMeters <= 750
          ? -6.62007320653036
          : -0.285467149404212 * Math.pow(logAltitude, 4) +
            8.55856793339852 * Math.pow(logAltitude, 3) -
            96.1580193931405 * Math.pow(logAltitude, 2) +
            478.077054822298 * logAltitude -
            892.151236317125;
      const adjustedTimeInMinutes =
        timeInMinutes /
        (altitudeInMeters < 750
          ? 1
          : (logProduct * adjustmentFactor + additionalFactor) / logDistance);
      const adjustedTimeInSeconds = adjustedTimeInMinutes * 60;

      const adjustedHours = Math.floor(adjustedTimeInSeconds / 3600);
      let adjustedMinutes = Math.floor((adjustedTimeInSeconds % 3600) / 60);
      let adjustedSecondsRemainder = Math.round(adjustedTimeInSeconds % 60);

      if (adjustedSecondsRemainder === 60) {
        adjustedMinutes += 1;
        adjustedSecondsRemainder = 0;
      }

      const formattedTime = `${adjustedHours}:${
        adjustedMinutes < 10 ? "0" : ""
      }${adjustedMinutes}:${
        adjustedSecondsRemainder < 10 ? "0" : ""
      }${adjustedSecondsRemainder}`;
      setConvertedTime(formattedTime);
    };

    calculateConvertedTime();
  }, [altitude, altitudeUnit, distance, distanceUnit, hours, minutes, seconds]);

  const handleDistanceUnitChange = (newUnit) => {
    if (newUnit !== null) {
      const convertedDistance = convertDistance(
        parseFloat(distance),
        distanceUnit,
        newUnit
      );
      setDistanceUnit(newUnit);
      setDistance(convertedDistance.toString());
    }
  };

  const handleAltitudeUnitChange = (newUnit) => {
    if (newUnit !== null) {
      const convertedAltitude = convertAltitude(
        parseFloat(altitude),
        altitudeUnit,
        newUnit
      );
      setAltitudeUnit(newUnit);
      setAltitude(convertedAltitude.toString());
    }
  };

  return (
    <ViewModal open={open} onClose={onClose}>
      <Typography
        variant="h5"
        component="h2"
        sx={{ width: "100%", my: 2, fontWeight: "bold" }}
      >
        Altitude Conversion Calculator
      </Typography>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            label="Altitude"
            type="number"
            value={altitude}
            onChange={(e) => setAltitude(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <DistanceUnitToggle
            distanceUnit={altitudeUnit}
            setDistanceUnit={handleAltitudeUnitChange}
          />
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            label="Race Distance"
            type="number"
            value={distance}
            onChange={(e) => setDistance(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <DistanceUnitToggle
            distanceUnit={distanceUnit}
            setDistanceUnit={handleDistanceUnitChange}
          />
        </Stack>
        <Divider
          sx={{ width: "100%", my: 2, fontWeight: "bold" }}
          component="div"
        >
          Race Time
        </Divider>
        <TimeInputFields
          hours={hours}
          setHours={setHours}
          minutes={minutes}
          setMinutes={setMinutes}
          seconds={seconds}
          setSeconds={setSeconds}
        />
        <Collapse in={true}>
          <Chip label={`Converted Time: ${convertedTime}`} />
        </Collapse>
      </Stack>
    </ViewModal>
  );
};

import React from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "./1.svg";

function MainAppBar() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path) => {
    navigate(path);
    handleMenuClose();
  };

  const logoVariants = {
    initial: { y: -20 },
    animate: {
      y: 0,
      transition: { type: "spring", stiffness: 100, damping: 10 },
    },
  };

  return (
    <AppBar
      position={isDesktop ? "fixed" : "static"}
      sx={{ backgroundColor: (theme) => theme.palette.background.dark }}
    >
      <Toolbar>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box onClick={() => navigate("/home")} sx={{ cursor: "pointer" }}>
            <motion.img
              src={Logo}
              alt="Track Tools Pro"
              style={{ height: "40px" }}
              variants={logoVariants}
              initial="initial"
              animate="animate"
            />
          </Box>
          {isMobile ? (
            <>
              <IconButton color="inherit" onClick={handleMenuClick} edge="end">
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={() => handleNavigation("/calculators")}>
                  Calculators
                </MenuItem>
                <MenuItem onClick={() => handleNavigation("/articles")}>
                  Articles
                </MenuItem>
                <MenuItem onClick={() => handleNavigation("/locations")}>
                  Course Measure
                </MenuItem>
                <MenuItem onClick={() => handleNavigation("/login")}>
                  Login
                </MenuItem>
              </Menu>
            </>
          ) : (
            <Box>
              <Button
                color="inherit"
                onClick={() => navigate("/calculators")}
                sx={{
                  fontWeight: "bold",
                  marginRight: 2,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                  },
                }}
              >
                Calculators
              </Button>
              <Button
                color="inherit"
                onClick={() => navigate("/articles")}
                sx={{
                  fontWeight: "bold",
                  marginRight: 2,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                  },
                }}
              >
                Articles
              </Button>
              <Button
                color="inherit"
                onClick={() => navigate("/locations")}
                sx={{
                  fontWeight: "bold",
                  marginRight: 2,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                  },
                }}
              >
                Course Measure
              </Button>
              <Button
                color="inherit"
                onClick={() => navigate("/login")}
                sx={{
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                  },
                }}
              >
                Login
              </Button>
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export { MainAppBar };

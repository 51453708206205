import React, { useState } from 'react';
import { Box, Typography, Grid, Card, CardContent, CardActionArea } from '@mui/material';
import PageWrapper from '../pageWrapper/pageWrapper';
import ViewModal from '../../components/viewModal/viewModal';

const articles = [
  {
    title: "The Evolution of Pole Vault Technology",
    description: "How modern pole vault poles are engineered for maximum performance and what the future holds for this specialized equipment.",
    category: "Equipment",
    content: `The pole vault has seen dramatic technological evolution since its competitive beginnings, with modern carbon fiber and fiberglass poles enabling athletes to reach previously unimaginable heights. This comprehensive look at pole vault technology explores the engineering behind these sophisticated tools and their impact on the sport.

    Modern competition poles are primarily constructed from layers of fiberglass and carbon fiber composites, materials chosen for their unique combination of strength, flexibility, and lightweight properties. The manufacturing process involves precisely layering these materials in specific orientations to achieve the desired flex characteristics. This careful engineering allows the pole to store and release enormous amounts of energy while maintaining structural integrity under extreme stress.

    The pole's behavior during a vault is a masterclass in physics and materials science. As the vaulter plants the pole and begins the vault, the pole flexes dramatically, converting the athlete's horizontal kinetic energy into potential energy stored in the bent pole. This energy is then returned to the vaulter during the recoil phase, helping propel them upward. Different pole stiffness ratings accommodate various athlete weights and approach speeds, with proper pole selection being crucial for optimal performance.

    Recent innovations have focused on optimizing the fiber layout patterns and resin systems used in pole construction. Advanced computer modeling helps manufacturers predict how different material combinations will perform under the dynamic loads experienced during a vault. Some manufacturers have introduced hybrid designs incorporating both fiberglass and carbon fiber in specific zones to enhance energy return while maintaining durability.

    Safety considerations remain paramount in pole design. Modern poles undergo extensive testing to ensure they can withstand the extreme forces involved in vaulting while maintaining a consistent flex pattern. Manufacturers must balance the desire for maximum performance with the need for predictable, reliable behavior under competition conditions.

    Looking to the future, research continues into new composite materials and construction techniques that could further enhance pole performance. However, the sport's governing bodies carefully regulate equipment specifications to maintain competitive fairness. Any technological advances must work within these established parameters while still pushing the boundaries of what's possible in this dynamic event.

    For athletes and coaches, understanding pole technology has become increasingly important for success in the event. Proper pole selection based on factors like grip height, weight rating, and flex characteristics can significantly impact performance. Regular pole maintenance and inspection are also crucial for both safety and optimal performance.`
  },
  {
    title: "Super Shoes: The Technology Revolution Changing Running",
    description: "A comprehensive guide to carbon-plated super shoes, their impact on running performance, and how to choose the right pair.",
    category: "Equipment",
    content: `The emergence of carbon-plated super shoes has fundamentally transformed competitive running, shattering records and redefining what's possible in distance racing. This comprehensive guide explores the technology behind these revolutionary shoes and helps you understand their impact on running performance.

    Carbon fiber plates combined with highly responsive foam compounds form the core of super shoe technology. The rigid plate acts as a lever, reducing energy loss at toe-off while promoting a more efficient running stride. Meanwhile, the specialized foam provides exceptional energy return while maintaining minimal weight. This combination has been shown to improve running economy by 4-5% in laboratory testing, translating to significant performance gains across all distances.

    When selecting super shoes, several key factors deserve consideration. Stack height and foam composition vary between models, affecting stability and energy return characteristics. Higher stack heights generally offer more cushioning and energy return but may sacrifice stability, especially around corners. The carbon plate's stiffness also varies between models - stiffer plates typically benefit faster runners while more flexible options work better for everyday training.

    The impact of super shoes extends beyond elite competition. Recreational runners report improved recovery and reduced muscle fatigue when using these shoes for long training runs and races. However, the significant cost investment means careful consideration of your running goals and race schedule is essential before purchasing. Many runners opt to reserve their super shoes for key workouts and race days while maintaining traditional trainers for daily mileage.

    Training adaptation becomes particularly important when incorporating super shoes. The altered biomechanics and reduced muscle loading can require an adjustment period. Begin with shorter runs to allow your body to adapt to the new running dynamics. Pay special attention to calf and Achilles loading, as the increased stack height and forward momentum can change tissue stress patterns. Gradually increase duration as you become comfortable with the shoe's unique characteristics.

    While super shoes offer clear performance benefits, they shouldn't be viewed as a complete replacement for proper training and race preparation. Focus on building a strong aerobic base and maintaining good running form regardless of footwear choice. Consider super shoes as one tool in your performance arsenal rather than a guaranteed path to faster times. With proper training and strategic use, these technological marvels can help optimize your racing potential while reducing recovery demands.`
  },
  {
    title: "Understanding Training Zones: A Complete Guide",
    description: "Learn how to effectively use training zones to optimize your running workouts and achieve your performance goals.",
    category: "Training",
    content: `Training zones are fundamental to structured running training, providing a framework for optimizing workout intensity and achieving specific physiological adaptations. This comprehensive guide breaks down the training zone system and explains how to effectively incorporate it into your training.

    The most practical training zone model divides effort levels into six distinct zones, each serving a specific purpose in your development as a runner. Zone 0 represents recovery effort - walking or very light jogging where conversation is completely comfortable. This zone is crucial for active recovery between harder sessions and promoting blood flow for adaptation.

    Zone 1 encompasses true easy running, where you can hold a conversation but might struggle to sing. This foundational zone should comprise about 80% of your training volume, building aerobic capacity and endurance while minimizing stress on your body. Running too fast in this zone is a common mistake that can compromise recovery and limit overall training consistency.

    Zone 2 represents moderate effort, typically around marathon race pace, where conversation becomes more challenging but still possible in short sentences. This zone improves your lactate threshold and teaches your body to efficiently use fat as fuel. Tempo runs in Zone 3 further push your lactate threshold, occurring at an effort where talking becomes very difficult - think 10K to half marathon race pace.

    Zone 4 targets your VO2max, or maximum aerobic capacity, through intervals at 3K to 5K race effort. These high-intensity sessions are crucial for improving running economy and speed, but should be used sparingly - typically once or twice per week. Finally, Zone 5 represents anaerobic training through very short, very fast efforts like strides or hill sprints, developing power and neuromuscular coordination.

    Heart rate monitoring can help gauge your zones, but perceived effort and the "talk test" are equally valuable tools. Your zones will shift as your fitness improves, so regular reassessment is important. Remember that the most effective training plans include a strategic mix of zones, with the majority of training occurring in Zones 1-2 and higher intensities used selectively for specific adaptations.

    When implementing zone training, start by establishing your easy pace through the talk test. Gradually introduce higher zones through structured workouts, always maintaining adequate recovery between hard efforts. Pay attention to how your body responds to different intensities and adjust accordingly. With consistent application of zone-based training, you'll develop a stronger aerobic base while systematically improving your speed and endurance capabilities.`
  },
  {
    title: "Sprint Training: Building Explosive Power",
    description: "Learn key workouts and techniques to develop the explosive power needed for successful sprinting. Covers acceleration drills, plyometrics, and weight room essentials.", 
    category: "Sprints",
    content: `Developing explosive power is the cornerstone of sprint success, requiring a carefully structured training approach that goes beyond raw talent. Elite sprinters understand that proper power development makes the critical difference between good and great performance. This comprehensive guide breaks down the essential components of sprint power training and provides actionable steps to enhance your explosive capabilities.

    The weight room serves as the foundation for building sprint-specific power. Focus on exercises targeting fast-twitch muscle fibers - the key drivers of explosive movement. Power cleans, box jumps, and Olympic lifting variations should form the core of your strength routine. The optimal approach involves performing these movements at 60-80% of your one-rep maximum, emphasizing explosive intent rather than pure weight. This intensity range maximizes power output while maintaining proper biomechanics essential for sprint performance.

    Track-based power development requires a systematic progression of acceleration drills. Begin with hill sprints, which naturally enforce proper sprint mechanics and power application through environmental resistance. As you advance, incorporate weighted sled pulls, focusing on horizontal force production rather than vertical displacement. The progression culminates with resistance band sprints, which are crucial for developing the explosive first step that characterizes elite sprinters. Maintain complete recovery between sets - typically 3-5 minutes - as this is power development, not endurance work.

    Plyometric training forms another crucial pillar of explosive power development. Start with basic bounds and hops, progressing to more complex movements like depth jumps and reactive jumps. These exercises develop the stretch-shortening cycle, critical for maximizing force production in sprinting. Incorporate plyometrics 2-3 times per week, always performing them when fresh at the beginning of workouts.

    Recovery strategies play an equally vital role in power development. Implement a comprehensive recovery protocol including adequate protein intake (1.6-2.0g/kg body weight daily), quality sleep (8-9 hours nightly), and strategic use of contrast therapy. Consider incorporating massage or foam rolling to maintain tissue quality between sessions. Remember that adaptation and strength gains occur during recovery periods, not during the workouts themselves.

    Proper periodization is essential for long-term power development. Structure your training year into distinct phases, starting with general strength building, progressing to power development, and peaking with speed-specific work. Monitor your progress through regular performance tests like vertical jump and 30m sprint times. This systematic approach ensures consistent improvement while minimizing injury risk and optimizing adaptation.`
  },
  {
    title: "Breaking the 4-Hour Marathon Barrier",
    description: "Expert strategies and training tips to help you achieve a sub-4 hour marathon finish time.",
    category: "Distance Running",
    content: `Breaking the 4-hour marathon barrier represents a significant milestone for many recreational runners. This comprehensive guide will walk you through the essential training principles, pacing strategies, and race-day execution needed to achieve this challenging but attainable goal. With proper preparation and dedication, running a sub-4 hour marathon is within reach for many dedicated runners.

    The foundation of a successful sub-4 hour marathon lies in building adequate weekly mileage and incorporating key workouts. Most runners should aim to peak at 40-50 miles per week during training, with long runs gradually increasing to 18-20 miles. Include tempo runs at goal marathon pace (9:09 min/mile) and progressive long runs where the final miles are run at marathon pace. Cross-training activities like cycling or swimming can help build endurance while reducing impact stress.

    Proper pacing is crucial for breaking 4 hours, requiring an average pace of 9:09 per mile throughout the race. Practice this pace extensively during training to develop muscle memory and learn how it should feel. Consider using a GPS watch to monitor your pace, but don't become overly dependent on it. Breaking the race into segments can make the goal more manageable - aim to run the first half slightly slower than goal pace, then maintain steady splits through mile 20 before giving everything you have for the final 10K.

    Nutrition and hydration strategy play a vital role in marathon success. During training, experiment with different energy gels, sports drinks, and fueling schedules to find what works best for your body. On race day, aim to consume 30-60 grams of carbohydrates per hour and stay ahead of your hydration needs by drinking at regular intervals. A proper taper in the final two weeks before the race will ensure your glycogen stores are fully replenished and your legs are fresh for race day.

    Mental preparation is equally important as physical training for achieving a sub-4 hour marathon. Develop positive mantras to use during difficult moments in the race, and practice visualization techniques imagining yourself maintaining strong form and finishing strong. Break the race into smaller, manageable segments rather than focusing on the full 26.2 miles. Remember that discomfort is temporary, but the achievement of breaking 4 hours will last forever.`
  },
  {
    title: "The Science of Easy Running",
    description: "Why running slow is crucial for getting faster and building endurance safely.",
    category: "Distance Running",
    content: `Understanding the science behind easy running is crucial for any runner looking to improve their performance while staying injury-free. While it may seem counterintuitive, running at a slower pace for the majority of your training can actually help you become a faster, stronger runner. This article explores the physiological benefits of easy running and how to incorporate it effectively into your training.

    The primary benefit of easy running lies in its ability to build aerobic capacity without overtaxing your body. During easy runs, your body primarily uses slow-twitch muscle fibers and relies on fat as its main energy source. This helps develop your aerobic energy systems, increases mitochondrial density in your muscles, and improves your body's ability to utilize oxygen efficiently. Research shows that approximately 80% of your training should be done at an easy, conversational pace to optimize these adaptations.

    Easy running also plays a crucial role in recovery and injury prevention. When you run at an easy pace, you put less stress on your joints, muscles, and connective tissues compared to higher-intensity workouts. This allows your body to adapt to the training load gradually while reducing the risk of overuse injuries. Additionally, easy runs promote better blood flow to working muscles, helping to clear metabolic waste products and support tissue repair between harder workouts.

    To properly execute easy runs, focus on maintaining a conversational pace - you should be able to speak in complete sentences without getting winded. Use heart rate as a guide, aiming to stay between 65-75% of your maximum heart rate. Don't worry about pace; it's more important to stay within the right effort level. Remember that even elite runners do most of their training at easy paces, saving their harder efforts for specific workout days.

    The psychological benefits of easy running shouldn't be overlooked either. These runs provide an opportunity to build consistency in your training without the mental stress of hitting specific paces or intervals. They allow you to focus on form, breathing, and simply enjoying the act of running. Over time, this sustainable approach leads to better long-term adherence to training and reduced risk of burnout.`
  },
  {
    title: "Marathon Fueling Strategy",
    description: "Complete guide to pre-race, during race, and post-race nutrition for marathon success.",
    category: "Distance Running",
    content: `Proper fueling is the cornerstone of marathon success, making the difference between hitting the wall and crossing the finish line strong. Understanding the science behind marathon nutrition and implementing an effective fueling strategy across all phases of your race is crucial for achieving your goals. Let's break down the key components of marathon fueling to help you perform at your best on race day.

    Pre-race nutrition begins 2-3 days before your marathon, with a focus on gradually increasing your carbohydrate intake while maintaining adequate hydration. Aim to consume 7-10g of carbohydrates per kilogram of body weight during this carb-loading phase, focusing on easily digestible sources like pasta, rice, and bread. The morning of the race, eat a familiar breakfast 3-4 hours before start time, targeting 300-400 calories of primarily carbohydrates with a small amount of protein and very little fat.

    During the marathon, your primary goal is maintaining steady energy levels through consistent fueling. Begin taking in carbohydrates around the 45-60 minute mark, aiming for 30-60g per hour depending on your body weight and intensity. Energy gels, sports drinks, and easily digestible carbohydrate sources are your best options. Pair your fuel intake with water at aid stations, being careful not to over-hydrate, which can be just as dangerous as dehydration.

    Post-race recovery nutrition is often overlooked but plays a vital role in your body's ability to repair and adapt. Within 30 minutes of finishing, consume a combination of carbohydrates and protein in a 3:1 ratio to replenish glycogen stores and support muscle recovery. A recovery drink or smoothie containing 60-75g of carbohydrates and 20-25g of protein is ideal. Continue eating balanced meals throughout the rest of the day, focusing on whole foods rich in antioxidants to combat inflammation.

    Race day success requires practicing your fueling strategy during training runs. Use your long runs to experiment with different fuel sources and timing to find what works best for your body. Pay attention to how different foods and drinks affect your energy levels and gastrointestinal comfort. Remember, what works for one runner may not work for another, so it's essential to develop a personalized fueling plan that you can execute confidently on race day.`
  },
  {
    title: "Building Your Running Base",
    description: "How to establish a solid foundation of mileage before starting specific training.",
    category: "Distance Running",
    content: `Building a proper running base is the cornerstone of any successful training program, whether you're preparing for your first 5K or aiming for a marathon PR. This foundational phase focuses on gradually increasing your weekly mileage while keeping intensity low, allowing your body to adapt to the demands of consistent running. Understanding how to build your base properly can mean the difference between reaching your goals and risking injury or burnout.

    The key principle of base building is the emphasis on easy-paced miles. During this phase, approximately 80% of your running should be at a conversational pace - meaning you can comfortably hold a conversation while running. This approach allows you to build aerobic fitness while minimizing injury risk and mental fatigue. Start with 3-4 runs per week, gradually increasing both the duration of individual runs and your weekly mileage by no more than 10% each week.

    Cross-training plays a vital role during base building, helping to strengthen supporting muscles and prevent overuse injuries. Include 1-2 sessions per week of low-impact activities like swimming, cycling, or strength training. These complementary activities improve overall fitness while giving your running muscles time to recover. Pay particular attention to core strength and hip stability exercises, as these areas often become problematic for runners as mileage increases.

    Consistency is more important than any single workout during base building. Aim to maintain your running schedule for 8-12 weeks before transitioning into more specific training. This timeframe allows for proper adaptation and creates a strong foundation for the harder workouts to come. Monitor your body's response to the increasing workload - signs of excessive fatigue or persistent soreness indicate a need to scale back temporarily.

    The base-building phase is also ideal for developing proper running form and establishing healthy habits. Focus on maintaining good posture, landing softly, and keeping a relatively high cadence of 170-180 steps per minute. Use this time to experiment with different fueling strategies and find what works best for your body. Proper nutrition and hydration become increasingly important as your mileage grows, so develop these habits early in your training.

    Remember that building a solid base is an investment in your running future. While it may feel less exciting than speed work or long runs, this phase creates the aerobic foundation and durability needed for more intense training. Track your progress through metrics like total weekly mileage and perceived effort, rather than pace. This patient approach will pay dividends when you begin incorporating more challenging workouts into your routine.`
  },
  {
    title: "Recovery Strategies for Distance Runners",
    description: "Essential recovery techniques to prevent injury and maintain peak performance.",
    category: "Distance Running",
    content: `Effective recovery is just as crucial as training itself for distance runners looking to perform at their peak while staying injury-free. Understanding and implementing proper recovery strategies can make the difference between achieving your running goals and facing setbacks due to overtraining or injury. Let's explore the key components of an optimal recovery routine for distance runners.

    Sleep plays a fundamental role in athletic recovery, with research showing that runners need 7-9 hours of quality sleep per night. During deep sleep, your body produces growth hormone, repairs muscle tissue, and consolidates motor learning from your training. Create a consistent sleep schedule and optimize your sleep environment by keeping your bedroom cool, dark, and quiet. Consider using blackout curtains and avoiding screens for at least an hour before bedtime to improve sleep quality.

    Nutrition timing and choices significantly impact recovery effectiveness. Within 30 minutes after finishing a run, consume a combination of carbohydrates and protein in a 3:1 ratio to replenish glycogen stores and support muscle repair. Aim for 20-25 grams of protein and 60-75 grams of carbohydrates. Throughout the day, focus on anti-inflammatory foods rich in antioxidants, such as berries, leafy greens, and fatty fish, to support cellular repair and reduce exercise-induced inflammation.

    Active recovery techniques can accelerate the recovery process when implemented correctly. Light activities like walking, swimming, or gentle cycling help increase blood flow to tired muscles without adding significant stress. Incorporate dynamic stretching and foam rolling to address muscle tension and maintain flexibility. Pay particular attention to common problem areas for runners, such as the IT band, calves, and hip flexors.

    Recovery tools and technologies have evolved significantly, offering runners various options to enhance their recovery routine. Compression garments can help reduce muscle soreness and improve circulation when worn post-run. Consider using percussion massage devices or recovery boots for targeted muscle relief. While these tools can be beneficial, remember they should complement, not replace, fundamental recovery practices like proper sleep and nutrition.

    Monitoring your recovery status helps prevent overtraining and optimize performance. Track your resting heart rate, sleep quality, and perceived fatigue levels. If you notice trends like elevated morning heart rate or persistent fatigue, it may indicate insufficient recovery. Don't hesitate to add extra rest days or reduce training intensity when your body shows signs of needing more recovery time. Remember, adaptation occurs during recovery, not during training itself.`
  },
  {
    title: "Hill Training for Endurance",
    description: "Incorporating hill workouts to build strength and improve running economy.",
    category: "Distance Running",
    content: `Hill training is one of the most effective ways to build running strength, improve form, and boost overall endurance. When incorporated strategically into your training plan, hill workouts can transform your running economy and race performance across all distances. Understanding the science and proper execution of hill training will help you maximize the benefits while minimizing injury risk.

    The physiological benefits of hill training are numerous and well-documented. Running uphill forces your muscles to contract more powerfully, recruiting additional muscle fibers and strengthening key running muscles like your glutes, quadriceps, and calves. This increased muscle activation leads to improved running economy on flat ground, as your body learns to recruit muscles more efficiently. Additionally, hill training naturally promotes proper running form by encouraging a forward lean, high knee drive, and powerful arm swing.

    When starting hill training, begin with short hill repeats of 30-60 seconds on moderate grades (4-6%). Focus on maintaining good form - drive your knees high, keep your chest up, and use your arms to power up the hill. As you progress, gradually increase either the length of the repeats or the steepness of the hills, but avoid increasing both simultaneously. Recovery between repeats should be a slow jog back down the hill, using this time to focus on controlled, relaxed form on the descent.

    Long hill runs serve a different purpose than hill repeats and should be incorporated separately into your training plan. These sustained climbs of 2-3 miles build mental toughness and aerobic endurance while strengthening your legs for race day. Start with a moderate pace that allows you to maintain consistent effort throughout the climb. As your fitness improves, you can begin incorporating progressive long hills where you gradually increase your effort as you ascend.

    Recovery and progression are crucial aspects of successful hill training. Start with one dedicated hill session per week, allowing at least 48 hours of recovery before any other hard workouts. Pay special attention to post-hill workout recovery, including proper hydration, nutrition, and gentle stretching of your calves, hamstrings, and hip flexors. As your body adapts, you can gradually increase the volume and intensity of hill work, but always listen to your body and adjust accordingly.

    For optimal results, integrate hill training throughout your training cycle, not just during specific phases. During base building, focus on longer, moderate hills to build strength and endurance. As you move into race-specific training, incorporate shorter, steeper hills that mimic the demands of your target race. Remember that consistency is key - regular hill training, even if modest in volume, will yield better results than sporadic, intense hill sessions.`
  },
  {
    title: "Marathon Race Day Preparation",
    description: "Complete checklist and strategy guide for marathon race day success.",
    category: "Distance Running",
    content: `Proper preparation is crucial for marathon success, and race day readiness starts well before the starting gun. This comprehensive guide will help ensure you have everything covered for your big day, from what to pack to race morning routines that set you up for success.

    The night before your marathon requires careful attention to detail. Lay out all your race gear, including your bib number, timing chip, and preferred running attire. Double-check the weather forecast and pack accordingly, including options for varying conditions. Prepare your race nutrition, including pre-race breakfast, mid-race fuel, and hydration supplies. Set multiple alarms and plan your transportation to ensure you arrive at least 90 minutes before the start time.

    Race morning nutrition can make or break your marathon performance. Consume a familiar breakfast 2-3 hours before start time, focusing on easily digestible carbohydrates and avoiding anything new or potentially upsetting to your stomach. Aim for 300-400 calories of proven pre-race foods like oatmeal, banana, or toast with peanut butter. Stay hydrated but avoid overdrinking - sip water regularly up until about 45 minutes before the start.

    Your race strategy should be clearly defined and rehearsed before race day. Break the marathon into manageable segments, typically 5-mile chunks, with specific pace goals for each section. Plan your fueling strategy to take in 30-60 grams of carbohydrates per hour, starting around mile 5. Know the course water stop locations and decide in advance whether you'll use them or carry your own hydration.

    Mental preparation is just as important as physical readiness. Visualize success and prepare positive mantras to use when things get tough in the later miles. Have multiple race goals - an 'A' goal for perfect conditions, a 'B' goal that's still satisfying, and a 'C' goal of simply finishing. This tiered approach helps maintain motivation if your primary goal becomes unrealistic during the race.

    Post-race recovery planning deserves attention too. Pack clean, dry clothes, recovery nutrition, and any personal care items you'll want immediately after finishing. Consider bringing cash for celebratory food and transportation options. Having these logistics sorted in advance lets you focus entirely on your race execution and enjoy the achievement of crossing that finish line.`
  },
  {
    title: "Running Form Fundamentals",
    description: "Proper running mechanics for efficiency and injury prevention.",
    category: "Distance Running",
    content: `Proper running form is the foundation for both performance improvement and injury prevention. Understanding and implementing correct running mechanics can help you run more efficiently, maintain better posture, and reduce your risk of common running injuries. Let's explore the key elements that make up proper running form and how to incorporate them into your training.

    The importance of posture cannot be overstated when it comes to running form. Keep your head level and gaze forward, avoiding the common mistake of looking down at your feet. Your shoulders should be relaxed and pulled back slightly, while your arms swing naturally at about a 90-degree angle. Maintain a tall spine and engage your core - imagine a string pulling you up from the crown of your head. This aligned posture helps optimize breathing and reduces unnecessary energy expenditure.

    Your foot strike pattern plays a crucial role in running efficiency and injury prevention. While there's debate about heel vs. midfoot striking, research suggests that a midfoot strike pattern generally provides the best balance of shock absorption and forward propulsion. Focus on landing with your foot under your center of mass, not out in front of your body. This positioning helps reduce braking forces and promotes a more efficient transfer of energy through your stride.

    Cadence, or stride rate, is another critical component of proper running form. Aim for approximately 170-180 steps per minute, which research has shown to be optimal for most runners. A higher cadence typically results in shorter ground contact time and reduced impact forces, potentially lowering injury risk. You can improve your cadence gradually by using a metronome app during training runs or focusing on taking quicker, lighter steps.

    Regular form checks and drills can help reinforce proper running mechanics. Incorporate running-specific strength exercises like single-leg squats and planks to build the stability needed for maintaining good form when fatigue sets in. Video analysis can also be valuable - have someone record you running from different angles to identify areas for improvement. Remember that changes to running form should be implemented gradually to allow your body time to adapt and prevent new stress patterns from developing.`
  },
  {
    title: "Progressive Long Runs",
    description: "How to structure long runs for maximum training benefit.",
    category: "Distance Running",
    content: `Progressive long runs are a cornerstone of distance running training, helping athletes build endurance while teaching the body to run faster on tired legs. This advanced training technique involves gradually increasing pace throughout your long run, finishing at a significantly faster pace than you started. When executed properly, progressive long runs provide both the endurance benefits of traditional long runs and the speed development of tempo runs.

    The structure of a progressive long run typically breaks down into three main segments. The first third should be run at an easy, conversational pace - approximately 60-90 seconds slower than your goal marathon pace. This allows for proper warm-up and helps ensure you don't start too fast. The middle third transitions to a moderate effort, around 30-45 seconds slower than marathon pace. During the final third, gradually increase your pace until you're running at or slightly faster than your goal marathon pace.

    One of the key benefits of progressive long runs is their ability to improve your running economy. As you increase speed while fatigued, your body learns to run more efficiently, recruiting muscle fibers more effectively and improving your form even when tired. This training adaptation is particularly valuable for marathon runners, who need to maintain form and efficiency during the later stages of their race.

    Proper fueling and hydration become especially critical during progressive long runs. Start hydrating well before the run and plan to take in fluids every 15-20 minutes. Consume easily digestible carbohydrates every 45-60 minutes, aiming for 30-60 grams of carbs per hour. This fueling strategy not only supports your performance during the run but also helps train your gut for race day conditions.

    Recovery from progressive long runs requires special attention due to their demanding nature. Plan for an easy recovery day or complete rest day following these workouts. Focus on proper post-run nutrition, consuming a combination of protein and carbohydrates within 30 minutes of finishing. Consider using compression gear and performing light stretching or foam rolling to aid in recovery.

    When incorporating progressive long runs into your training plan, start with one every 2-3 weeks during your base building phase. As you approach your target race, you might increase the frequency to every other week, but be careful not to overdo it. These runs put significant stress on your body and require adequate recovery time. Listen to your body and adjust the intensity or frequency as needed to prevent overtraining.`
  },
  {
    title: "Cross Training for Runners",
    description: "Complementary training activities to improve running performance.",
    category: "Distance Running",
    content: `Cross training is an essential component of any well-rounded running program, offering numerous benefits that can enhance your running performance while reducing injury risk. By incorporating different forms of exercise into your training routine, you can become a stronger, more resilient athlete while maintaining your running fitness during recovery periods.

    Swimming and cycling are two of the most effective cross-training activities for runners. These low-impact cardio exercises help maintain cardiovascular fitness while giving your joints and muscles a break from the repetitive impact of running. Swimming particularly helps develop upper body strength and improved breathing capacity, while cycling builds quad and glute strength that translates directly to more powerful running strides.

    Strength training is another crucial aspect of cross training that many runners overlook. Regular resistance training helps prevent common running injuries by addressing muscle imbalances and building core stability. Focus on compound exercises like squats, lunges, and deadlifts to develop functional strength that supports proper running form. Additionally, include exercises targeting commonly neglected areas such as hip abductors and ankle stabilizers.

    Yoga and Pilates offer unique benefits for runners by improving flexibility, balance, and body awareness. These practices help counteract the muscle tightness that often develops from running, while also strengthening stabilizing muscles that support proper running form. Regular practice can enhance recovery, reduce injury risk, and improve running economy through better posture and movement patterns.

    When incorporating cross training into your running schedule, aim for 2-3 sessions per week, adjusting the intensity based on your current training phase. During peak running weeks, keep cross training light and focused on recovery. During base building or recovery periods, you can increase the intensity of cross training to maintain fitness while giving your running muscles a break.

    Remember that cross training should complement, not compete with, your running goals. Monitor your energy levels and adjust your cross training accordingly to ensure you're fresh for key running workouts. By strategically incorporating these complementary activities, you'll develop into a more well-rounded athlete while extending your running longevity.`
  },
  {
    title: "High Jump Technique: The Fosbury Flop Mastery",
    description: "A comprehensive breakdown of proper Fosbury Flop technique, from approach to takeoff and bar clearance. Includes common mistakes and how to fix them.",
    category: "Jumps", 
    content: `Mastering the Fosbury Flop technique is essential for any high jumper looking to maximize their performance. This revolutionary jumping style, introduced by Dick Fosbury at the 1968 Olympics, has become the gold standard in high jump competition. Understanding the biomechanics and key components of this technique can help athletes achieve greater heights while maintaining consistency in their jumps.

    The approach phase is fundamental to executing a successful Fosbury Flop. Athletes should focus on developing a consistent J-curved approach pattern using 8-10 controlled strides. The curve serves two critical purposes: generating the necessary centripetal force for rotation and creating optimal takeoff conditions. Speed maintenance throughout the curve is crucial - athletes should gradually build momentum while staying relaxed and controlled. Common technical errors include starting the curve too early or running too straight an approach, both of which can compromise the jump's effectiveness.

    Proper takeoff mechanics are vital for converting horizontal momentum into vertical lift. The penultimate step should be slightly elongated, allowing athletes to lower their center of mass and prepare for explosive vertical drive. Foot placement at takeoff should be approximately 1-1.5 feet from the bar, though this distance may vary based on individual characteristics and approach speed. The free leg should drive aggressively upward while maintaining proper body alignment. Many athletes struggle with inconsistent takeoff positions, either jumping too close or too far from the bar, which can significantly impact clearing height.

    The bar clearance phase requires precise timing and body control. Athletes should focus on leading with their hips while maintaining a strong back arch. Proper head position is crucial - keeping the chin tucked helps maintain optimal spinal alignment throughout the jump. Arm position plays a vital role in rotation efficiency; keeping them close to the body during the arch helps maintain proper rotational mechanics. The legs should remain relaxed during the initial phase of bar clearance before executing a quick snap-down motion to clear the bar cleanly.

    Training for the Fosbury Flop should incorporate both technical drills and strength development. Athletes should focus on exercises that enhance posterior chain strength, core stability, and explosive power. Regular video analysis can help identify technical flaws and track progress over time. Additionally, incorporating plyometric exercises can improve takeoff power and body control during the flight phase. Success in the Fosbury Flop comes from consistent practice and attention to technical details.

    Advanced practitioners should pay special attention to the relationship between approach speed and jump height. While faster approaches can generate more power, they also require greater technical precision and control. Athletes should work with their coaches to find the optimal balance between speed and control that maximizes their jumping potential. Regular assessment of technique through video analysis and performance metrics can help fine-tune individual approaches to the Fosbury Flop.`
  },
  {
    title: "Shot Put: Glide vs Rotational Technique - A Complete Guide",
    description: "Master the key differences between glide and rotational shot put techniques. Learn proper form, training tips, and how to choose the right technique for your throwing style.",
    category: "Throws", 
    content: `Shot put technique selection can make or break an athlete's throwing career. Since the revolutionary introduction of the spin technique in the 1970s, throwers have debated the merits of both the traditional glide and modern rotational approaches. This comprehensive guide breaks down the essential elements of each technique to help you make an informed decision for your throwing journey.

    The glide technique, pioneered by Olympic champion Parry O'Brien, remains the foundation of shot putting excellence. This linear approach begins with the athlete facing the back of the circle, followed by an explosive gliding motion across the throwing area. The key advantage lies in its biomechanical efficiency - the implement maintains alignment with the direction of force throughout the throw, maximizing power transfer. For beginners and athletes with strong linear power generation, the glide offers an ideal entry point into competitive throwing.

    In contrast, the rotational technique harnesses angular momentum to achieve exceptional distances. The spinning motion creates a complex kinetic chain that, when properly executed, can generate tremendous power at the point of release. However, mastering this technique demands superior proprioception, balance, and coordination. Athletes must develop an acute awareness of body position while maintaining implement control through multiple rotation phases. This technical complexity makes it particularly suitable for throwers with gymnastics or explosive movement backgrounds.

    Proper training progression plays a crucial role in technique development. Begin with standing throws to establish fundamental positioning and release mechanics. Gradually incorporate movement patterns specific to your chosen technique through drills like half turns for rotational throwers or standing power positions for glide specialists. Focus on building a strong technical foundation before attempting full throws, as proper mechanics will ultimately determine your success more than raw power.

    When selecting between techniques, consider your physical attributes and athletic background. Taller athletes with longer levers often excel with the glide technique's linear nature. Conversely, explosive athletes with quick-twitch muscle fibers may find greater success with the rotational approach. Remember that world records have been set using both methods - Randy Barnes' glide technique and Randy Matson's rotational prowess both produced historic throws. The key lies not in which technique you choose, but in your dedication to mastering its execution.

    Success in shot put ultimately comes down to technical precision, regardless of your chosen method. Implement regular video analysis to identify areas for improvement, and work closely with experienced coaches who can provide technique-specific guidance. Focus on developing explosive power through complementary weight training, emphasizing Olympic lifts and plyometrics that translate directly to throwing performance. With proper training and unwavering commitment to technical excellence, both glide and rotational techniques can lead to championship-caliber performances.`
  },
  {
    title: "Hurdle Rhythm: Three-Step vs Five-Step Approach",
    description: "Master proper hurdle spacing and rhythm. Learn when to use three-step vs five-step approach and how to maintain speed between hurdles.",
    category: "Hurdles", 
    content: `Understanding proper hurdle rhythm and technique is essential for success in track and field hurdle events. The choice between three-step and five-step approaches represents one of the most fundamental decisions hurdlers must make to optimize their race strategy and performance. This comprehensive guide breaks down the key differences and applications of each approach.

    The three-step technique has become the gold standard among elite hurdlers, particularly in the 110m/100m events. This aggressive approach allows athletes to maintain maximum velocity between hurdles by taking exactly three steps in each interval. To execute this effectively, hurdlers need exceptional speed endurance, precise stride length control, and superior flexibility in both lead and trail legs. The three-step pattern creates a smooth, rhythmic flow that, when mastered, results in faster race times and more efficient energy usage.

    For developing athletes and those competing in longer hurdle races like the 400m hurdles, the five-step approach offers distinct advantages. This technique provides greater stability and control, making it especially valuable during the latter stages of races when fatigue becomes a factor. The additional steps between hurdles allow for better adjustment of stride pattern when approaching hurdles off-rhythm, reducing the risk of costly mistakes. Many coaches recommend beginning with the five-step approach to build proper form and confidence before transitioning to the more demanding three-step pattern.

    Proper training progression is crucial for mastering either approach. Athletes should begin with fundamental drills focusing on hurdle form and clearance technique. Once basic mechanics are sound, incorporate rhythm development using wickets or markers set at appropriate distances. Gradually increase speed and complexity as technique improves, always maintaining focus on consistent stride patterns and efficient hurdle clearance. Video analysis can be particularly helpful in identifying areas for improvement in both approaches.

    Advanced hurdlers often develop proficiency in both patterns, allowing them to adapt their strategy based on race conditions, fatigue levels, or competitive situations. This versatility becomes especially important in longer races or when recovering from slight technical mistakes. Regular practice switching between patterns during training sessions helps build this adaptability. Remember that successful hurdling ultimately comes down to finding the rhythm that best matches your physical capabilities and racing style.

    Recovery and maintenance work play vital roles in hurdle training success. Regular flexibility training targeting hip mobility and hamstring elasticity helps maintain the range of motion needed for efficient hurdle clearance. Strength training should focus on developing explosive power in the legs while maintaining core stability. Additionally, incorporating sprint mechanics drills helps reinforce proper running form between hurdles, ensuring optimal performance throughout the race.`
  }
];

const ArticlesPage = () => {
  const [selectedArticle, setSelectedArticle] = useState(null);

  const handleArticleClick = (article) => {
    setSelectedArticle(article);
  };

  const handleClose = () => {
    setSelectedArticle(null);
  };

  return (
    <PageWrapper>
      <Box sx={{ p: 3 }}>
        <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 4 }}>
          Expert advice and insights to help you reach your full potential
        </Typography>

        <Grid container spacing={3}>
          {articles.sort(() => Math.random() - 0.5).map((article, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card 
                sx={{ 
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'translateY(-4px)'
                  }
                }}
              >
                <CardActionArea 
                  sx={{ flexGrow: 1 }}
                  onClick={() => handleArticleClick(article)}
                >
                  <CardContent>
                    <Typography 
                      variant="overline" 
                      color="primary"
                      sx={{ mb: 1 }}
                    >
                      {article.category}
                    </Typography>
                    <Typography 
                      variant="h6" 
                      component="h2"
                      sx={{ mb: 2 }}
                    >
                      {article.title}
                    </Typography>
                    <Typography 
                      variant="body2" 
                      color="text.secondary"
                      sx={{ mb: 2 }}
                    >
                      {article.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>

        <ViewModal
          open={Boolean(selectedArticle)}
          onClose={handleClose}
        >
          {selectedArticle && (
            <>
              <Box display="flex" justifyContent="space-between" alignItems="center" sx={{p: 2}}>
                <Typography variant="h5">{selectedArticle.title}</Typography>
              </Box>
              <Box sx={{p: 2}}>
                <Typography variant="overline" color="primary" display="block" gutterBottom>
                  {selectedArticle.category}
                </Typography>
                <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                  {selectedArticle.content}
                </Typography>
              </Box>
            </>
          )}
        </ViewModal>
      </Box>
    </PageWrapper>
  );
};

export default ArticlesPage;

import React, { useEffect, useState, useContext } from "react";
import "./App.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { requestForToken, onMessageListener } from "./messaging";

// components and contexts
import SideNav from "./components/sideNav/sideNav";
import { AuthContext } from "./context/authContext";
import { ThemeProvider } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import createAppTheme from "./theme";
import { Toaster, toast } from "sonner";
import Island from "./context/island/island";
import { Switch, FormControlLabel } from "@mui/material";
import { MainAppBar } from "./components/appBar/appBar";
import useApiService from "./api/apiService";
import { analytics, logEvent } from "./firebase";

let stripePromise;
try {
  stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
} catch (err) {
  console.error("Failed to load Stripe:", err);
  stripePromise = null;
}

function App() {
  const { token: authToken, team, updateTeam } = useContext(AuthContext);
  const [theme, setTheme] = useState(() => {
    try {
      return createAppTheme();
    } catch (err) {
      console.error("Failed to create theme:", err);
      return {};
    }
  });
  const [isDarkMode, setIsDarkMode] = useState(false);
  const apiService = useApiService();

  // Fetch and update team data on load for coaches if team exists
  const { data: teamData } = apiService.useTeam(team?.id);
  useEffect(() => {
    try {
      if (teamData) {
        updateTeam(teamData);
      }
    } catch (err) {
      console.error("Failed to update team data:", err);
    }
  }, [teamData, updateTeam]);

  useEffect(() => {
    try {
      const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
      const handleChange = (e) => {
        try {
          setIsDarkMode(e.matches);
          setTheme(createAppTheme(e.matches ? "dark" : "light"));
        } catch (err) {
          console.error("Failed to handle theme change:", err);
        }
      };

      handleChange(mediaQuery);
      mediaQuery.addListener(handleChange);

      return () => {
        try {
          mediaQuery.removeListener(handleChange);
        } catch (err) {
          console.error("Failed to remove media query listener:", err);
        }
      };
    } catch (err) {
      console.error("Failed to setup media query:", err);
    }
  }, []);

  const handleThemeToggle = () => {
    try {
      setIsDarkMode(!isDarkMode);
      setTheme(createAppTheme(isDarkMode ? "light" : "dark"));
    } catch (err) {
      console.error("Failed to toggle theme:", err);
    }
  };

  useEffect(() => {
    if (authToken) {
      requestForToken().then((token) => {
        if (token) {
          apiService.saveFcmToken({ fcm_token: token })
            .catch(err => console.error("Failed to save FCM token:", err));
        }
      }).catch(err => console.error("Failed to request FCM token:", err));

      onMessageListener()
        .then((payload) => {
          try {
            console.log("Received foreground message: ", payload);
            toast.success(`New notification: ${payload?.notification?.title}`);
          } catch (err) {
            console.error("Failed to handle message:", err);
          }
        })
        .catch((err) =>
          console.error("Failed to receive foreground message: ", err)
        );
    }

    try {
      // Log user authentication event (only if not on localhost)
      if (analytics && window.location.hostname !== "localhost") {
        logEvent(analytics, "login", { method: "authContext" });
      }
    } catch (err) {
      console.error("Failed to log analytics event:", err);
    }

    // eslint-disable-next-line
  }, [authToken]);

  // // Log page view on route change
  // useEffect(() => {
  //   const logPageView = () => {
  //     const path = location.pathname + location.search;
  //     const pageName = getPageName(location.pathname);
  //     if (analytics && window.location.hostname !== 'localhost') {
  //       logEvent(analytics, 'page_view', {
  //         page_path: path,
  //         page_name: pageName,
  //         page_title: document.title // Include the document title
  //       });
  //       console.log('Logging page view:', { path, pageName, title: document.title });
  //     }
  //   };

  //   // Log on initial load and on location change
  //   logPageView();
  // }, [location]);

  // // Function to determine page name based on path
  // const getPageName = (pathname) => {
  //   // Remove leading slash and split the path
  //   const pathSegments = pathname.slice(1).split("/");

  //   // Basic mapping of paths to page names
  //   switch (pathSegments[0]) {
  //     case "":
  //       return "Home";
  //     case "team-file":
  //       switch (pathSegments[1]) {
  //         case "files":
  //           return "Team Files";
  //         case "categories":
  //           return "File Categories";
  //         default:
  //           return "Team File";
  //       }
  //     case "training-plan":
  //       switch (pathSegments[1]) {
  //         case "schedule":
  //           return "Training Schedule";
  //         case "workouts":
  //           return "Workouts";
  //         default:
  //           return "Training Plan";
  //       }
  //     case "roster":
  //       switch (pathSegments[1]) {
  //         case "athletes":
  //           return "Athletes Roster";
  //         case "coaches":
  //           return "Coaches Roster";
  //         case "training-groups":
  //           return "Training Groups";
  //         case "alumni":
  //           return "Alumni Roster";
  //         default:
  //           return "Roster";
  //       }
  //     case "bulletin":
  //       return "Bulletin Board";
  //     case "calculators":
  //       return "Calculators";
  //     // Add more cases as needed for your routes
  //     default:
  //       return (
  //         pathSegments[0].charAt(0).toUpperCase() + pathSegments[0].slice(1)
  //       );
  //   }
  // };

  // // Set document title based on current page
  // useEffect(() => {
  //   const pageName = getPageName(location.pathname);
  //   document.title = `TTP | ${pageName}`;
  // }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <Elements stripe={stripePromise}>
        <div className="App">
          {authToken && <SideNav />}
          {authToken && <Island />}
          {!authToken && <MainAppBar />}
          <Toaster
            position="top-right"
            toastOptions={{
              duration: 1750,
              success: {
                style: {
                  border: "1px solid green",
                },
              },
            }}
          />
          <Outlet />
          {window.location.hostname.includes("localhost") && (
            <FormControlLabel
              control={
                <Switch checked={isDarkMode} onChange={handleThemeToggle} />
              }
              label="Dark Mode"
              sx={{
                position: "fixed",
                bottom: 16,
                right: 16,
                color: theme.palette.text.primary,
              }}
            />
          )}
        </div>
      </Elements>
    </ThemeProvider>
  );
}

export default App;

import React, { useState, useContext } from "react";
import { Grid, Button, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { AuthContext } from "../../context/authContext";
import useApiService from "../../api/apiService";
import { useNavigate, useLocation } from "react-router-dom";
import Form from "../../components/form/form";
import { AnimatedGridBG } from "../../components/magicUI/animatedGridBG";
import PageWrapper from "../pageWrapper/pageWrapper";

const LogInSignUpPage = () => {
  const { login } = useContext(AuthContext);
  const apiService = useApiService();
  const navigate = useNavigate();
  const location = useLocation();
  const isCreateAccount = location.pathname === "/login/create-account";
  const [isLogin, setIsLogin] = useState(!isCreateAccount);
  const [userType, setUserType] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [teamName, setTeamName] = useState("");

  const { mutate: loginUser } = apiService.useLoginUser();
  const { mutate: registerUser } = apiService.useRegisterUser();

  const handleSwitchMode = () => {
    setIsLogin(!isLogin);
    setUserType(null);
    navigate(isLogin ? "/login/create-account" : "/login");
  };

  const handleNavigateHome = () => {
    navigate("/");
  };

  const handleUserTypeChange = (event, newType) => {
    setUserType(newType);
  };

  const handleLogin = async (formData) => {
    loginUser(
      { email: formData.email, password: formData.password },
      {
        onSuccess: (response) => {
          const { user, token, type, team } = response.data;
          
          try {
            // Store in localStorage
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('token', token);
            localStorage.setItem('userType', type);
            localStorage.setItem('team', JSON.stringify(team));
          } catch (error) {
            console.error('Error storing data in localStorage:', error);
          }

          // Call login from context
          navigate('/home');
          login(user, token, type, team);
        },
        onError: (error) => {
          console.error("Login failed", error);
        },
      }
    );
  };

  const handleSignUp = async (formData) => {
    if (formData.password !== formData.confirmPassword) {
      console.log("Passwords do not match");
      return;
    }
    registerUser(
      {
        email: formData.email,
        password: formData.password,
        first_name: formData.firstName,
        last_name: formData.lastName,
        team_name: formData.teamName,
        user_type: userType,
      },
      {
        onSuccess: (response) => {
          const { user, token, type, team } = response.data;

          try {
            // Store in localStorage
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('token', token);
            localStorage.setItem('userType', type);
            localStorage.setItem('team', JSON.stringify(team));
          } catch (error) {
            console.error('Error storing data in localStorage:', error);
          }

          // Call login from context
          navigate('/home');
          login(user, token, type, team);
        },
        onError: (error) => {
          console.error("Sign up failed", error);
        },
      }
    );
  };

  const fields = [
    !isLogin && {
      name: "userType",
      type: "custom",
      render: () => (
        <Grid container direction="column" spacing={2} sx={{ mb: 3 }}>
          <Grid item>
            <ToggleButtonGroup
              value={userType}
              exclusive
              onChange={handleUserTypeChange}
              aria-label="user type"
            >
              <ToggleButton value="coach">Coach</ToggleButton>
              <ToggleButton value="athlete">Athlete</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {userType === "athlete" && (
            <Grid item>
              <Typography color="error">
                Athletes must be invited by their coach to join. Please contact your coach for an invitation.
              </Typography>
            </Grid>
          )}
        </Grid>
      ),
    },
    ((!isLogin && userType === "coach") || isLogin) && {
      name: "email",
      label: "Email Address", 
      type: "email",
      required: true,
      value: email,
      onChange: setEmail,
    },
    ((!isLogin && userType === "coach") || isLogin) && {
      name: "password",
      label: "Password",
      type: "password", 
      required: true,
      value: password,
      onChange: setPassword,
    },
    !isLogin && userType === "coach" && {
      name: "confirmPassword",
      label: "Confirm Password",
      type: "password",
      required: true,
      value: confirmPassword,
      onChange: setConfirmPassword,
    },
    !isLogin && userType === "coach" && {
      name: "firstName",
      label: "First Name",
      type: "text",
      required: true,
      value: firstName,
      onChange: setFirstName,
    },
    !isLogin && userType === "coach" && {
      name: "lastName",
      label: "Last Name",
      type: "text",
      required: true,
      value: lastName,
      onChange: setLastName,
    },
    !isLogin && userType === "coach" && {
      name: "teamName",
      label: "Team Name",
      type: "text",
      required: true,
      value: teamName,
      onChange: setTeamName,
    },
    {
      name: "switchMode",
      label: isLogin ? "Switch to Sign Up" : "Switch to Login",
      type: "custom",
      render: () => (
        <>
          <Button onClick={handleSwitchMode} sx={{ mt: 2, mr: 2 }}>
            {isLogin ? "Create Account" : "Login"}
          </Button>
          <Button onClick={handleNavigateHome} sx={{ mt: 2 }}>
            Home
          </Button>
        </>
      ),
    },
  ].filter(Boolean);

  return (
    <PageWrapper ttp={true} tabs={[]} sx={{ m: 0, p: 0 }} policy={false}>
      <Grid
        container
        component="main"
        sx={{ height: "100vh", position: "relative" }}
      >
        <AnimatedGridBG />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          sx={{ mx: "auto", mt: 10, position: "relative", zIndex: 1 }}
        >
          <Form
            open={true}
            onClose={() => {}}
            fields={fields}
            onSubmit={isLogin ? handleLogin : handleSignUp}
            title={isLogin ? "Login" : "Sign Up"}
            disabled={!isLogin && userType === "athlete"}
          />
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default LogInSignUpPage;

import React from "react";
import { Source, Layer } from "react-map-gl";

const Route = ({
  name,
  coordinates,
  markerDistance = 400, // Distance between markers in meters
  unitType = "meters", // 'meters' or 'miles'
  hideName = false, // Toggle to hide route name
}) => {
  // Validate unit type
  if (!["meters", "miles"].includes(unitType)) {
    console.warn("Invalid unit type provided. Defaulting to meters.");
    unitType = "meters";
  }

  // Convert marker distance to meters if needed
  const markerDistanceInMeters =
    unitType === "miles" ? markerDistance * 1609.34 : markerDistance;

  // Calculate total distance and points at specified intervals
  const getMarkersAtDistance = (coords) => {
    const markers = [];
    let totalDistance = 0;
    let currentPoint = [...coords[0]];
    let coordIndex = 0;

    while (coordIndex < coords.length - 1) {
      const end = coords[coordIndex + 1];

      // Calculate distance between current point and end using Haversine formula
      const R = 6371e3; // Earth's radius in meters
      const φ1 = (currentPoint[1] * Math.PI) / 180;
      const φ2 = (end[1] * Math.PI) / 180;
      const Δφ = ((end[1] - currentPoint[1]) * Math.PI) / 180;
      const Δλ = ((end[0] - currentPoint[0]) * Math.PI) / 180;

      const a =
        Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const segmentDistance = R * c;

      if (totalDistance + segmentDistance >= markerDistanceInMeters) {
        // Calculate how far along this segment the marker should be
        const remainingDistance = markerDistanceInMeters - totalDistance;
        const ratio = remainingDistance / segmentDistance;

        // Interpolate new point
        const lat = currentPoint[1] + ratio * (end[1] - currentPoint[1]);
        const lng = currentPoint[0] + ratio * (end[0] - currentPoint[0]);

        markers.push([lng, lat]);

        // Update current point to marker location
        currentPoint = [lng, lat];
        totalDistance = 0;
      } else {
        totalDistance += segmentDistance;
        currentPoint = [...end];
        coordIndex++;
      }
    }

    return markers;
  };

  const markerPoints = getMarkersAtDistance(coordinates);

  // Format distance based on unit type
  const formatDistance = (index) => {
    const distanceInMeters = (index + 1) * markerDistanceInMeters;
    if (unitType === "miles") {
      const miles = distanceInMeters / 1609.34;
      // Handle decimal points for miles
      return `${miles % 1 === 0 ? miles.toFixed(0) : miles.toFixed(1)} miles`;
    }
    return `${distanceInMeters.toFixed(0)} meters`;
  };

  return (
    <>
      {/* Line for the route */}
      <Source
        type="geojson"
        data={{
          type: "Feature",
          properties: {
            name: name,
          },
          geometry: {
            type: "LineString",
            coordinates: coordinates,
          },
        }}
      >
        {/* Route line */}
        <Layer
          id={`route-${name}`}
          type="line"
          paint={{
            "line-color": "#4CAF50",
            "line-width": 3,
            "line-opacity": 0.7, // Slightly reduce line opacity
          }}
        />
      </Source>

      {/* Distance marker points */}
      <Source
        type="geojson"
        data={{
          type: "FeatureCollection",
          features: [
            // Start point
            {
              type: "Feature",
              properties: {
                name: name,
                distance: !hideName ? name : "Start",
              },
              geometry: {
                type: "Point",
                coordinates: coordinates[0],
              },
            },
            // Distance markers
            ...markerPoints.map((coord, index) => ({
              type: "Feature",
              properties: {
                index: index,
                name: name,
                distance: formatDistance(index),
              },
              geometry: {
                type: "Point",
                coordinates: coord,
              },
            })),
            // End point
            {
              type: "Feature",
              properties: {
                name: name,
                distance: "End",
              },
              geometry: {
                type: "Point",
                coordinates: coordinates[coordinates.length - 1],
              },
            },
          ],
        }}
      >
        <Layer
          id={`route-markers-${name}`}
          type="circle"
          paint={{
            "circle-radius": 8,
            "circle-color": "#4CAF50",
            "circle-stroke-width": 2,
            "circle-stroke-color": "#FFFFFF",
            "circle-opacity": 0.9,
          }}
        />
        <Layer
          id={`route-markers-labels-${name}`}
          type="symbol"
          layout={{
            "text-field": ["get", "distance"],
            "text-offset": [0, -2],
            "text-anchor": "bottom",
            "text-size": 14,
            "text-font": ["Open Sans Bold"],
          }}
          paint={{
            "text-color": "#2E7D32",
            "text-halo-color": "#FFFFFF",
            "text-halo-width": 3,
            "text-opacity": 0.95,
          }}
        />
      </Source>
    </>
  );
};

export default Route;

import React, { useState } from "react";
import {
  Paper,
  Stack,
  TextField,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Chip,
  Alert,
} from "@mui/material";
import { TimeInputFields, DistanceUnitToggle } from "./common";
import ViewModal from "../../../../components/viewModal/viewModal";

export const DistantEquCalc = ({ open, onClose }) => {
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [seconds, setSeconds] = useState("");
  const [distance, setDistance] = useState("");
  const [distanceUnit, setDistanceUnit] = useState("meters");
  const [vdotmax, setVdotmax] = useState("");
  const [raceTimes, setRaceTimes] = useState({});
  const [error, setError] = useState({ time: false, distance: false });
  const [showAlert, setShowAlert] = useState(false);

  const validateInput = () => {
    const errors = { time: false, distance: false };
    if (!distance) errors.distance = true;
    if (!hours && !minutes && !seconds) errors.time = true;
    setError(errors);
    return errors;
  };

  const calculateVdot = () => {
    const { time, distance: distanceError } = validateInput();
    if (time || distanceError) {
      setShowAlert(true);
      return;
    } else {
      setShowAlert(false);
    }

    let dist = parseFloat(distance);
    dist = convertDistance(dist, distanceUnit, "meters");

    const th = parseInt(hours || 0) * 60;
    const tm = parseInt(minutes || 0);
    const ts = parseInt(seconds || 0) / 60;
    const totalTime = th + tm + ts;

    if (totalTime <= 0 || dist <= 0) return;

    const d = dist / totalTime;
    const p =
      0.8 +
      0.1894393 * Math.exp(-0.012778 * totalTime) +
      0.2989558 * Math.exp(-0.1932605 * totalTime);
    const v = -4.6 + 0.182258 * d + 0.000104 * d * d;
    const vdot = v / p;

    setVdotmax(vdot.toFixed(1));
    setRaceTimes(calculateRaceTimes(vdot));
  };

  const calculateRaceTimes = (VDOT) => {
    const distances = [1500, 1609.34, 3000, 5000, 10000, 21097.5, 42195]; // Distances in meters
    const raceTimes = {};

    distances.forEach((distance) => {
      let bestTime = 0;
      let smallestVDOTDiff = Infinity;

      for (let time = 1; time <= 500; time += 0.01) {
        const d = distance / time; // speed in meters per minute
        const p =
          0.8 +
          0.1894393 * Math.exp(-0.012778 * time) +
          0.2989558 * Math.exp(-0.1932605 * time);
        const v = -4.6 + 0.182258 * d + 0.000104 * d * d;
        const vdot = v / p;

        const vdotDiff = Math.abs(vdot - VDOT);

        if (vdotDiff < smallestVDOTDiff) {
          smallestVDOTDiff = vdotDiff;
          bestTime = time; // time in minutes
        }
      }

      const hours = Math.floor(bestTime / 60);
      let mins = Math.floor(bestTime % 60);
      let secs = Math.round((bestTime % 1) * 60);

      let distanceLabel =
        distance === 1609.34 ? "1 mile" : `${distance} meters`;
      if (distance === 21097.5) distanceLabel = "Half Marathon";
      if (distance === 42195) distanceLabel = "Marathon";

      if (secs === 60) {
        mins += 1;
        secs = 0;
      }
      let formattedTime = `${mins.toString().padStart(2, "0")}:${secs
        .toString()
        .padStart(2, "0")}`;
      if (hours > 0) {
        formattedTime = `${hours}:${formattedTime}`;
      }
      raceTimes[distance] = {
        time: formattedTime,
        label: distanceLabel,
      };
    });

    return raceTimes;
  };

  const convertDistance = (value, fromUnit, toUnit) => {
    if (fromUnit === toUnit) return value;
    const conversionFactors = {
      miles: {
        meters: 1609.34,
        kilometers: 1.60934,
      },
      meters: {
        miles: 1 / 1609.34,
        kilometers: 1 / 1000,
      },
      kilometers: {
        meters: 1000,
        miles: 0.621371,
      },
    };
    return value * (conversionFactors[fromUnit][toUnit] || 1);
  };

  const handleDistanceUnitChange = (newUnit) => {
    if (newUnit !== null && newUnit !== distanceUnit) {
      const convertedDistance = convertDistance(
        parseFloat(distance),
        distanceUnit,
        newUnit
      ).toString();
      setDistanceUnit(newUnit);
      setDistance(convertedDistance);
    }
  };

  return (
    <ViewModal open={open} onClose={onClose}>
      <Typography
        variant="h6"
        component="h2"
        sx={{ width: "100%", my: 2, fontWeight: "bold" }}
      >
        Distance Equivalency Calculator
      </Typography>
      {showAlert && (
        <Alert severity="error" sx={{ width: "100%", mt: 2 }}>
          Please check your inputs.
        </Alert>
      )}
      <Divider
        sx={{ width: "100%", my: 2, fontWeight: "bold" }}
        component="div"
      >
        Time
      </Divider>
      <TimeInputFields
        hours={hours}
        setHours={setHours}
        minutes={minutes}
        setMinutes={setMinutes}
        seconds={seconds}
        setSeconds={setSeconds}
      />
      <Divider
        sx={{ width: "100%", my: 2, fontWeight: "bold" }}
        component="div"
      >
        Distance
      </Divider>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
        alignItems="center"
      >
        <TextField
          label="Distance"
          value={distance}
          onChange={(e) => setDistance(e.target.value)}
          error={error.distance}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <DistanceUnitToggle
          distanceUnit={distanceUnit}
          setDistanceUnit={handleDistanceUnitChange}
        />
        <Button
          variant="contained"
          sx={{ mt: { xs: 2, sm: 0 } }}
          onClick={calculateVdot}
        >
          Calculate
        </Button>
      </Stack>
      {vdotmax && (
        <>
          <Chip label={`VDOT: ${vdotmax}`} color="primary" sx={{ mt: 2 }} />
          <TableContainer
            component={Paper}
            sx={{ maxHeight: 240, overflowY: "scroll", width: "100%", mt: 2 }}
          >
            <Table sx={{ width: "100%" }} aria-label="equivalent races table">
              <TableHead>
                <TableRow>
                  <TableCell>Distance</TableCell>
                  <TableCell align="right">Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(raceTimes)
                  .sort((a, b) => parseFloat(a[0]) - parseFloat(b[0]))
                  .map(([distance, raceTime]) => (
                    <TableRow
                      key={distance}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {raceTime.label}
                      </TableCell>
                      <TableCell align="right">{raceTime.time}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </ViewModal>
  );
};

import React from 'react';
import {
  SwipeableDrawer,
  Box,
  Typography,
  useTheme,
} from '@mui/material';

const MobileModal = ({ open, onClose, children, sx = {}, title }) => {
  const theme = useTheme();

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      PaperProps={{
        style: { width: '100%', borderRadius: '16px 16px 0 0' },
        sx: sx, // Allow sx to affect SwipeableDrawer's Paper component
      }}
      ModalProps={{
        keepMounted: false,
      }}
      disableSwipeToOpen={true}
      swipeAreaWidth={0}
    >
      <Box
        sx={{
          p: 2,
          pb: 4,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          position: 'relative',
          ...sx, // Apply sx to the main Box as well
        }}
      >
        {/* Pull Indicator */}
        <Box
          sx={{
            width: '40px',
            height: '4px',
            backgroundColor: theme.palette.grey[400],
            borderRadius: '2px',
            mx: 'auto',
            mb: 2,
          }}
        />

        {title && (
          <Typography variant="h6" align="center" sx={{ mb: 2 }}>
            {title}
          </Typography>
        )}

        <Box sx={{ overflowY: 'auto', flexGrow: 1 }}>
          {children}
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

export default MobileModal;

import React, { useState, useEffect } from "react";
import useApiService from "../../api/apiService";
import {
  Typography,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ComponentWrapper } from "../../components/utils";
import Form from "../../components/form/form";
import AreYouSure from "../../components/utils/areYouSure/areYouSure";

const CategorySection = () => {
  const [categories, setCategories] = useState([]);
  const [isCategoryFormOpen, setIsCategoryFormOpen] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);
  const [deletingCategory, setDeletingCategory] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    useFileCategories,
    useCreateFileCategory,
    useDeleteFileCategory,
    useUpdateFileCategory,
  } = useApiService();

  const { data: fileCategories } = useFileCategories();
  const createFileCategory = useCreateFileCategory();
  const deleteFileCategory = useDeleteFileCategory();
  const updateFileCategory = useUpdateFileCategory();

  useEffect(() => {
    if (fileCategories) setCategories(fileCategories);
  }, [fileCategories]);

  const handleCreateCategory = async (formData) => {
    try {
      console.log("Creating category:", formData);
      await createFileCategory.mutateAsync(formData);
      setIsCategoryFormOpen(false);
    } catch (error) {
      console.error("Create category error:", error);
    }
  };

  const handleDeleteCategory = async () => {
    try {
      await deleteFileCategory.mutateAsync(deletingCategory.id);
      setDeletingCategory(null);
    } catch (error) {
      console.error("Delete category error:", error);
    }
  };

  const handleEditCategory = (category) => {
    setEditingCategory(category);
    setIsCategoryFormOpen(true);
  };

  const handleUpdateCategory = async (formData) => {
    try {
      const updatedData = {
        id: editingCategory.id,
        data: {
          name: formData.name,
          description: formData.description,
          athletesViewable: formData.athletesViewable,
          athletesUploadable: formData.athletesUploadable,
        },
      };
      await updateFileCategory.mutateAsync(updatedData);
      setIsCategoryFormOpen(false);
      setEditingCategory(null);
    } catch (error) {
      console.error("Update category error:", error);
    }
  };

  const categoryFormFields = [
    {
      name: "name",
      label: "Category Name",
      type: "text",
    },
    {
      name: "description",
      label: "Description",
      type: "textarea",
      optional: true,
    },
    {
      name: "athletesViewable",
      label: "Athletes can view",
      type: "bool",
    },
    {
      name: "athletesUploadable",
      label: "Athletes can upload",
      type: "bool",
    },
  ];

  return (
    <ComponentWrapper sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Manage Categories</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setEditingCategory(null);
            setIsCategoryFormOpen(true);
          }}
          startIcon={<AddIcon />}
          size={isMobile ? "small" : "medium"}
        >
          Create Category
        </Button>
      </Box>

      <Form
        open={isCategoryFormOpen}
        onClose={() => {
          setIsCategoryFormOpen(false);
          setEditingCategory(null);
        }}
        fields={categoryFormFields}
        onSubmit={editingCategory ? handleUpdateCategory : handleCreateCategory}
        title={editingCategory ? "Edit Category" : "Create Category"}
        defaultValues={editingCategory || {}}
      />

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Athletes View</TableCell>
              <TableCell>Athletes Upload</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((category) => (
              <TableRow key={category.id}>
                <TableCell>{category.name}</TableCell>
                <TableCell>{category.description}</TableCell>
                <TableCell>{category.athletesViewable ? "Yes" : "No"}</TableCell>
                <TableCell>{category.athletesUploadable ? "Yes" : "No"}</TableCell>
                <TableCell>
                  <Button
                    startIcon={<EditIcon />}
                    onClick={() => handleEditCategory(category)}
                    size={isMobile ? "small" : "medium"}
                  >
                    Edit
                  </Button>
                  <Button
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      setDeletingCategory(category);
                      setIsDeleteModalOpen(true);
                    }}
                    size={isMobile ? "small" : "medium"}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <AreYouSure
        isOpen={isDeleteModalOpen}
        handleClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteCategory}
        itemName={deletingCategory ? deletingCategory.name : ""}
      />
    </ComponentWrapper>
  );
};

export default CategorySection;

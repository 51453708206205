import React, { useState, useEffect, useCallback } from "react";
import {
  TextField,
  Stack,
  Divider,
  Chip,
  Collapse,
  Typography,
} from "@mui/material";
import { TimeInputFields, DistanceUnitToggle } from "./common";
import ViewModal from "../../../../components/viewModal/viewModal";

export const PaceCalc = ({ open, onClose }) => {
  const [distance, setDistance] = useState();
  const [distanceUnit, setDistanceUnit] = useState("meters"); // Default to meters
  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();
  const [splitDistance, setSplitDistance] = useState();
  const [splitDistanceUnit, setSplitDistanceUnit] = useState("meters"); // Default to meters
  const [splitTime, setSplitTime] = useState();
  const [showSplitTime, setShowSplitTime] = useState(false);

  const convertDistance = (value, fromUnit, toUnit) => {
    if (fromUnit === toUnit) return value;
    const conversionFactors = {
      miles: {
        meters: 1609.34,
        kilometers: 1.60934,
      },
      meters: {
        miles: 1 / 1609.34,
        kilometers: 1 / 1000,
      },
      kilometers: {
        meters: 1000,
        miles: 0.621371,
      },
    };
    return value * (conversionFactors[fromUnit][toUnit] || 1);
  };

  const calculateSplitTime = useCallback(() => {
    const totalSeconds =
      (parseInt(hours) * 3600 || 0) +
      (parseInt(minutes) * 60 || 0) +
      (parseInt(seconds) || 0);
    const distanceInMeters = convertDistance(
      parseFloat(distance),
      distanceUnit,
      "meters"
    );
    const splitDistanceInMeters = convertDistance(
      parseFloat(splitDistance),
      splitDistanceUnit,
      "meters"
    );
    if (
      distanceInMeters === 0 ||
      splitDistanceInMeters === 0 ||
      isNaN(totalSeconds) ||
      isNaN(distanceInMeters) ||
      isNaN(splitDistanceInMeters)
    ) {
      setSplitTime("");
      setShowSplitTime(false);
      return;
    }
    const pacePerMeter = totalSeconds / distanceInMeters;
    const splitTimeInSeconds = pacePerMeter * splitDistanceInMeters;
    const splitMinutes = Math.floor(splitTimeInSeconds / 60);
    const splitSeconds = Math.round((splitTimeInSeconds % 60) * 10) / 10;
    if (splitSeconds === 60) {
      setSplitTime(`${splitMinutes + 1}:00`);
    } else {
      const formattedSplitSeconds =
        splitSeconds < 10 ? `0${splitSeconds}` : splitSeconds;
      const formattedSplitMinutes = splitMinutes > 0 ? `${splitMinutes}:` : "";
      const formattedSplitTime =
        isNaN(splitMinutes) || isNaN(formattedSplitSeconds)
          ? ""
          : `${formattedSplitMinutes}${formattedSplitSeconds}`;
      setSplitTime(formattedSplitTime);
    }
    setShowSplitTime(true);
  }, [
    hours,
    minutes,
    seconds,
    distance,
    distanceUnit,
    splitDistance,
    splitDistanceUnit,
  ]);

  useEffect(() => {
    let adjustedHours = hours;
    let adjustedMinutes = minutes;
    let adjustedSeconds = seconds;

    if (adjustedSeconds === "60") {
      adjustedSeconds = "00";
      adjustedMinutes = (parseInt(adjustedMinutes) + 1).toString();
    }

    if (adjustedMinutes === "60") {
      adjustedMinutes = "00";
      adjustedHours = (parseInt(adjustedHours) + 1).toString();
    }

    if (
      adjustedHours === "" &&
      adjustedMinutes === "" &&
      adjustedSeconds === ""
    ) {
      adjustedHours = adjustedMinutes = adjustedSeconds = " ";
    }

    if (
      adjustedHours !== hours ||
      adjustedMinutes !== minutes ||
      adjustedSeconds !== seconds
    ) {
      setHours(adjustedHours);
      setMinutes(adjustedMinutes);
      setSeconds(adjustedSeconds);
    }

    if (
      adjustedHours !== " " ||
      adjustedMinutes !== " " ||
      adjustedSeconds !== " "
    ) {
      calculateSplitTime();
    }
  }, [
    hours,
    minutes,
    seconds,
    distance,
    distanceUnit,
    splitDistance,
    splitDistanceUnit,
    calculateSplitTime,
  ]);

  const handleDistanceChange = (event) => {
    setDistance(event.target.value);
  };

  const handleDistanceUnitChange = (newUnit) => {
    if (newUnit !== null && newUnit !== distanceUnit) {
      const convertedDistance = convertDistance(
        parseFloat(distance),
        distanceUnit,
        newUnit
      );
      setDistanceUnit(newUnit);
      setDistance(convertedDistance.toString());
    }
  };

  const handleSplitDistanceChange = (event) => {
    setSplitDistance(event.target.value);
  };

  const handleSplitDistanceUnitChange = (newUnit) => {
    if (newUnit !== null && newUnit !== splitDistanceUnit) {
      const convertedSplitDistance = convertDistance(
        parseFloat(splitDistance),
        splitDistanceUnit,
        newUnit
      );
      setSplitDistanceUnit(newUnit);
      setSplitDistance(convertedSplitDistance.toString());
    }
  };

  return (
    <ViewModal open={open} onClose={onClose}>
      <Stack direction="column" spacing={2}>
        <Typography
          variant="h5"
          component="h2"
          sx={{ width: "100%", my: 2, fontWeight: "bold" }}
        >
          Pace Calculator
        </Typography>
        <Divider
          sx={{ width: "100%", my: 2, fontWeight: "bold" }}
          component="div"
        >
          Race Input
        </Divider>
        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            label="Race Distance"
            type="number"
            value={distance}
            onChange={handleDistanceChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <DistanceUnitToggle
            distanceUnit={distanceUnit}
            setDistanceUnit={handleDistanceUnitChange}
          />
        </Stack>
        <TimeInputFields
          hours={hours}
          setHours={setHours}
          minutes={minutes}
          setMinutes={setMinutes}
          seconds={seconds}
          setSeconds={setSeconds}
        />
        <Divider
          sx={{ width: "100%", my: 2, fontWeight: "bold" }}
          component="div"
        >
          Split
        </Divider>
        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            label="Split Distance"
            type="number"
            value={splitDistance}
            onChange={handleSplitDistanceChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <DistanceUnitToggle
            distanceUnit={splitDistanceUnit}
            setDistanceUnit={handleSplitDistanceUnitChange}
          />
        </Stack>
        <Collapse in={showSplitTime}>
          <Chip label={`Split Time: ${splitTime}`} />
        </Collapse>
      </Stack>
    </ViewModal>
  );
};

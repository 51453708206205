import React from "react";
import { Source, Layer } from "react-map-gl";

const Pin = ({ coordinates, name, color = "#FF4081", size = 15 }) => {
  return (
    <Source
      type="geojson"
      data={{
        type: "Feature",
        properties: {
          name: name,
        },
        geometry: {
          type: "Point",
          coordinates: coordinates,
        },
      }}
    >
      <Layer
        id={`pin-${coordinates.join("-")}`}
        type="circle"
        paint={{
          "circle-radius": size,
          "circle-color": color,
          "circle-stroke-width": 2,
          "circle-stroke-color": "#FFFFFF",
          "circle-opacity": 0.9,
        }}
      />
      <Layer
        id={`pin-label-${coordinates.join("-")}`}
        type="symbol"
        layout={{
          "text-field": ["get", "name"],
          "text-offset": [0, -2],
          "text-anchor": "bottom",
          "text-size": 14,
          "text-font": ["Open Sans Bold"],
        }}
        paint={{
          "text-color": color,
          "text-halo-color": "#FFFFFF",
          "text-halo-width": 3,
          "text-opacity": 0.95,
        }}
      />
    </Source>
  );
};

export default Pin;

import React from "react";
import { Box } from "@mui/material";
import AthletesGoals from "./athletesGoals";
import ModalWrapper from "../../../../utils/wrappers/modalWrapper";

const GoalModal = ({ athlete, open, onClose }) => {
  return (
    <ModalWrapper open={open} handleClose={onClose}>
      <Box>
        <AthletesGoals athlete={athlete} />
      </Box>
    </ModalWrapper>
  );
};

export default GoalModal;

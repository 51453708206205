import React, { useState, useEffect } from "react";
import {
  TextField,
  Collapse,
  Stack,
  Chip,
  Divider,
  Slider,
  Typography,
} from "@mui/material";
import { TimeInputFields, DistanceUnitToggle } from "./common";
import ViewModal from "../../../../components/viewModal/viewModal";

export const EffortCalc = ({ open, onClose }) => {
  const [distance, setDistance] = useState("");
  const [distanceUnit, setDistanceUnit] = useState("meters"); // Default to meters
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [seconds, setSeconds] = useState("");
  const [effortPercentage, setEffortPercentage] = useState(100);
  const [targetDistance, setTargetDistance] = useState("");
  const [targetDistanceUnit, setTargetDistanceUnit] = useState("meters"); // Default to meters
  const [resultTime, setResultTime] = useState("");
  const [showResult, setShowResult] = useState(false);

  useEffect(() => {
    const calculateTime = () => {
      const totalTimeInSeconds =
        (parseInt(hours) * 3600 || 0) +
        (parseInt(minutes) * 60 || 0) +
        (parseInt(seconds) || 0);
      const distanceInMeters =
        distanceUnit === "miles"
          ? distance * 1609.34
          : distanceUnit === "kms"
          ? distance * 1000
          : parseFloat(distance);
      const targetDistanceInMeters =
        targetDistanceUnit === "miles"
          ? targetDistance * 1609.34
          : targetDistanceUnit === "kms"
          ? targetDistance * 1000
          : parseFloat(targetDistance);
      const pacePerMeter = totalTimeInSeconds / distanceInMeters;
      const adjustedPace = pacePerMeter * (100 / effortPercentage);
      const resultTimeInSeconds = adjustedPace * targetDistanceInMeters;
      let resultHours = Math.floor(resultTimeInSeconds / 3600);
      let resultMinutes = Math.floor((resultTimeInSeconds % 3600) / 60);
      let resultSeconds = (resultTimeInSeconds % 60).toFixed(1);

      resultMinutes = resultMinutes < 10 ? "0" + resultMinutes : resultMinutes;
      resultSeconds =
        parseFloat(resultSeconds) < 10 ? "0" + resultSeconds : resultSeconds;

      if (parseFloat(resultSeconds) >= 60) {
        resultSeconds = "00.0";
        resultMinutes = parseInt(resultMinutes) + 1;
        resultMinutes =
          resultMinutes < 10 ? "0" + resultMinutes : resultMinutes;
      }

      if (resultMinutes === "60") {
        resultMinutes = "00";
        resultHours += 1;
      }

      return `${resultHours}:${resultMinutes}:${resultSeconds}`;
    };

    if (
      distance &&
      (hours || minutes || seconds) &&
      effortPercentage &&
      targetDistance
    ) {
      const calculatedTime = calculateTime();
      setResultTime(calculatedTime);
      setShowResult(true);
    } else {
      setShowResult(false);
    }
  }, [
    distance,
    distanceUnit,
    hours,
    minutes,
    seconds,
    effortPercentage,
    targetDistance,
    targetDistanceUnit,
  ]);

  const handleDistanceChange = (event) => {
    setDistance(event.target.value);
  };

  const handleTargetDistanceChange = (event) => {
    setTargetDistance(event.target.value);
  };

  const convertDistance = (value, fromUnit, toUnit) => {
    if (fromUnit === toUnit) return value;
    const conversionFactors = {
      miles: {
        meters: 1609.34,
        kilometers: 1.60934,
      },
      meters: {
        miles: 1 / 1609.34,
        kilometers: 1 / 1000,
      },
      kilometers: {
        meters: 1000,
        miles: 0.621371,
      },
    };
    return value * (conversionFactors[fromUnit][toUnit] || 1);
  };

  const handleDistanceUnitChange = (newUnit) => {
    if (newUnit !== null) {
      const convertedDistance = convertDistance(
        parseFloat(distance),
        distanceUnit,
        newUnit
      );
      setDistanceUnit(newUnit);
      setDistance(convertedDistance.toString());
    }
  };

  const handleTargetDistanceUnitChange = (newUnit) => {
    if (newUnit !== null) {
      const convertedTargetDistance = convertDistance(
        parseFloat(targetDistance),
        targetDistanceUnit,
        newUnit
      );
      setTargetDistanceUnit(newUnit);
      setTargetDistance(convertedTargetDistance.toString());
    }
  };

  const handleEffortChange = (event, newValue) => {
    setEffortPercentage(newValue);
  };

  return (
    <ViewModal open={open} onClose={onClose}>
      <Stack sx={{ p: 2 }}>
        <Typography
          variant="h5"
          component="h2"
          sx={{ width: "100%", my: 2, fontWeight: "bold" }}
        >
          Effort Calculator
        </Typography>
        <Stack direction="column" spacing={2}>
          <Divider
            sx={{ width: "100%", my: 2, fontWeight: "bold" }}
            component="div"
          >
            Distance & Time
          </Divider>
          <Stack direction="row" spacing={2}>
            <TextField
              label={`Distance (${distanceUnit})`}
              type="number"
              value={distance}
              onChange={handleDistanceChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <DistanceUnitToggle
              distanceUnit={distanceUnit}
              setDistanceUnit={handleDistanceUnitChange}
            />
          </Stack>
          <TimeInputFields
            hours={hours}
            setHours={setHours}
            minutes={minutes}
            setMinutes={setMinutes}
            seconds={seconds}
            setSeconds={setSeconds}
          />
          <Divider
            sx={{ width: "100%", my: 2, fontWeight: "bold" }}
            component="div"
          >
            Effort Percentage
          </Divider>
          <Slider
            aria-label="Effort Percentage"
            defaultValue={100}
            valueLabelDisplay="auto"
            step={1}
            marks
            min={50}
            max={125}
            onChange={handleEffortChange}
            value={
              typeof effortPercentage === "number" ? effortPercentage : 100
            }
            valueLabelFormat={(value) => `${value}%`}
          />
          <Stack direction="row" spacing={2}>
            <TextField
              label={`Target Distance (${targetDistanceUnit})`}
              type="number"
              value={targetDistance}
              onChange={handleTargetDistanceChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <DistanceUnitToggle
              distanceUnit={targetDistanceUnit}
              setDistanceUnit={handleTargetDistanceUnitChange}
            />
          </Stack>
        </Stack>
        <Collapse in={showResult}>
          <Chip label={`${resultTime}`} />
        </Collapse>
      </Stack>
    </ViewModal>
  );
};

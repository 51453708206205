import React, { useState, useCallback, useContext } from "react";
import {
  Box,
  Button,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import useApiService from "../../api/apiService";
import ViewModal from "../viewModal/viewModal";
import { AuthContext } from "../../context/authContext";

const SubscriptionForm = ({ open, onClose, initialIsYearly }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [checkoutUrl, setCheckoutUrl] = useState(null);
  const [cancelSuccess, setCancelSuccess] = useState(false);
  const { createCheckoutSession, cancelSubscription } = useApiService();
  const { team } = useContext(AuthContext);

  const isPro =
    team?.subscription?.status === "active" ||
    team?.subscription?.status === "trialing";

  const fetchCheckoutUrl = useCallback(async () => {
    if (checkoutUrl || isPro) return;
    setLoading(true);
    try {
      const response = await createCheckoutSession({
        plan: initialIsYearly ? "plan_pro_yearly" : "plan_pro_monthly",
      });
      setCheckoutUrl(response.data.url);
    } catch (err) {
      setError(err.message || "Failed to create checkout session");
    } finally {
      setLoading(false);
    }
  }, [createCheckoutSession, initialIsYearly, checkoutUrl, isPro]);

  if (open && !checkoutUrl && !loading && !error && !isPro) {
    fetchCheckoutUrl();
  }

  const handleRedirectToCheckout = () => {
    if (checkoutUrl) {
      window.location.href = checkoutUrl;
    }
  };

  const handleCancelSubscription = async () => {
    setLoading(true);
    try {
      await cancelSubscription();
      setCancelSuccess(true);
    } catch (err) {
      setError(err.message || "Failed to cancel subscription");
    } finally {
      setLoading(false);
    }
  };

  return (
    <ViewModal open={open} onClose={onClose}>
      <DialogTitle>Pro Plan Subscription</DialogTitle>
      <DialogContent>
        {isPro ? (
          <>
            <Typography sx={{ mt: 2 }}>
              You are currently subscribed to the Pro plan.
              {team.subscription.cancelAtPeriodEnd ? (
                <>
                  {" "}
                  Your subscription will end on{" "}
                  {new Date(
                    team.subscription.currentPeriodEnd
                  ).toLocaleDateString()}
                  .
                </>
              ) : (
                <>
                  {" "}
                  Your subscription will renew on{" "}
                  {new Date(
                    team.subscription.currentPeriodEnd
                  ).toLocaleDateString()}
                  .
                </>
              )}
            </Typography>
            {!team.subscription.cancelAtPeriodEnd && !cancelSuccess && (
              <Button
                onClick={handleCancelSubscription}
                sx={{ mt: 2 }}
                disabled={loading}
              >
                {loading ? "Canceling..." : "Cancel Subscription"}
              </Button>
            )}
            {cancelSuccess && (
              <Typography color="success.main" sx={{ mt: 2 }}>
                Your subscription has been successfully canceled. You will have
                access until the end of your current billing period.
              </Typography>
            )}
          </>
        ) : loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        ) : checkoutUrl ? (
          <Box sx={{ mt: 2, textAlign: "center" }}>
            <Typography variant="h6" gutterBottom>
              Ready to upgrade to Pro?
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRedirectToCheckout}
              sx={{ mt: 2 }}
            >
              Proceed to Checkout
            </Button>
          </Box>
        ) : (
          <Typography sx={{ mt: 2 }}>Loading checkout session...</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </ViewModal>
  );
};

export default SubscriptionForm;

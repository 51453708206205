import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  ListItem,
  Menu,
  MenuItem,
  Divider,
  Collapse,
  Chip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailIcon from "@mui/icons-material/Email";
import CollapseIcon from "../../../../components/utils/collapseIcon/collapseIcons";
import MenuIcon from "../../../../components/utils/menuIcon";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { AuthContext } from "../../../../context/authContext";
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';

const CoachPod = ({
  coach,
  showFields = ["email", "lastSeen"],
  options = false,
  handleOpenForm,
  handleDeleteClick,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const { userType } = useContext(AuthContext);
  // const theme = useTheme();
  // const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const extraFields = ["email", "lastSeen"];
  const hasExtraFields = extraFields.some((field) =>
    showFields.includes(field)
  );

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    handleOpenForm(coach);
    setAnchorEl(null);
  };

  const handleRemoveClick = () => {
    handleDeleteClick(coach);
    setAnchorEl(null);
  };

  const handleExpandClick = (event) => {
    if (hasExtraFields) {
      event.stopPropagation();
      setExpanded(!expanded);
    }
  };

  const handlePodClick = () => {
    if (hasExtraFields) {
      setExpanded(!expanded);
    }
  };

  const formatLastSeen = (lastSeen) => {
    if (!lastSeen) return null;
    const date = new Date(lastSeen);
    return date.toLocaleDateString();
  };

  return (
    <ListItem key={coach?.id || "unknown"} sx={{ p: 0.5 }}>
      <Box
        onClick={handlePodClick}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          pl: 1,
          pr: 1,
          border: (theme) => `1px solid ${theme.palette.border.main}`,
          borderRadius: "20px",
          cursor: hasExtraFields ? "pointer" : "default",
          backgroundColor: (theme) => theme.palette.background.paperSecondary,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
            {options && (
              <MenuIcon open={Boolean(anchorEl)} onClick={handleMenuClick} />
            )}
            <Typography
              sx={{ ml: 1, display: { xs: "block", sm: "block" } }}
            >{`${coach?.firstName || "Unknown"} ${
              coach?.lastName || "Coach"
            }`}</Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            {hasExtraFields && (
              <CollapseIcon expanded={expanded} onClick={handleExpandClick} />
            )}
          </Box>
        </Box>

        {hasExtraFields && (
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box
              sx={{ mt: 2, display: "flex", flexWrap: "wrap", gap: 1, p: 1 }}
            >
              {showFields.includes("email") && coach?.email && (
                <Chip
                  icon={<EmailIcon />}
                  label={coach?.email}
                  variant="outlined"
                />
              )}
              {showFields.includes("lastSeen") && coach?.lastSeen && (
                <Chip
                  icon={<CalendarTodayIcon />}
                  label={`Last Login: ${formatLastSeen(coach.lastSeen)}`}
                  variant="outlined"
                />
              )}
            </Box>
          </Collapse>
        )}
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {userType === "coach" && (
          <>
            <MenuItem onClick={handleEditClick}>
              <EditIcon fontSize="small" style={{ marginRight: "8px" }} />
              Edit
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleRemoveClick}>
              <DeleteIcon fontSize="small" style={{ marginRight: "8px" }} />
              Remove from Team
            </MenuItem>
          </>
        )}
      </Menu>
    </ListItem>
  );
};

const TableCoach = ({ coaches, handleOpenForm, handleDeleteClick }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      {coaches.map((coach) => (
        <CoachPod
          key={coach.id}
          coach={coach}
          showFields={["email", "lastSeen"]}
          options={true}
          handleOpenForm={handleOpenForm}
          handleDeleteClick={handleDeleteClick}
        />
      ))}
    </Box>
  );
};

export { TableCoach };

import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Box } from "@mui/material";
import PageWrapper from "../pageWrapper/pageWrapper";
import FileTable from "./fileTable";
import CategorySection from "./categorySection";

const PageFiles = () => {
  const tabs = [
    { label: "Files", path: "files" },
    { label: "Categories", path: "categories" },
  ];

  return (
    <PageWrapper title="Team Files" tabs={tabs} rootUrl="/team-file">
      <Box sx={{ padding: 2 }}>
        <Routes>
          <Route index element={<Navigate to="files" replace />} />
          <Route
            path="files"
            element={<FileTable />}
          />
          <Route
            path="categories"
            element={<CategorySection />}
          />
        </Routes>
      </Box>
    </PageWrapper>
  );
};

export default PageFiles;

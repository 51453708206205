// src/messaging.js
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "./firebase";

export const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, { vapidKey: 'BFYf3MpvO9W8GrJAIFyGn3562I5WTNehW4drVOCJKlyQN_oo0mJxFcPdq9v0hG8UlNFu_QK6aq_GXEYjW4mFtR0' });
    if (currentToken) {
      return currentToken;
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (error) {
    console.error('An error occurred while retrieving token. ', error);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    const unsubscribe = onMessage(messaging, (payload) => {
      resolve(payload);
    });
    return unsubscribe;
  });

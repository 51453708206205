import React, { useContext } from "react";
import { Box } from "@mui/material";
import { Routes, Route, Navigate } from "react-router-dom";
import PageWrapper from "../pageWrapper/pageWrapper";
import { AthleteSection } from "./_components/athletesSection";
import { EventGroupSection } from "./_components/eventGroupSection";
import { CoachesSection } from "./_components/coachesSection";
import { AuthContext } from "../../context/authContext";
import AthletePage from "./_components/athletesSection/athleteID/athletePage";

const getTabs = (userType) => {
  const tabs = [
    { label: "Athletes", path: "athletes" },
    { label: "Coaches", path: "coaches" },
  ];

  if (userType === "coach") {
    tabs.push(
      { label: "Groups", path: "training-groups" },
      { label: "Alumni", path: "alumni" }
    );
  }

  return tabs;
};

function Roster() {
  const { userType } = useContext(AuthContext);
  const tabs = getTabs(userType);

  return (
    <PageWrapper title="Roster Management" tabs={tabs} rootUrl="/roster">
      <Box sx={{ padding: 2 }}>
        <Routes>
          <Route index element={<Navigate to="athletes" replace />} />
          <Route path="athletes">
            <Route index element={<AthleteSection />} />
            <Route path=":athleteId" element={<AthletePage />} />
          </Route>
          {userType === "coach" && (
            <>
              <Route
                path="training-groups"
                element={
                  <>
                    <EventGroupSection />
                  </>
                }
              />
              <Route
                path="alumni"
                element={
                  <>
                    <div>Coming soon...</div>
                  </>
                }
              />
            </>
          )}
          <Route path="coaches" element={<CoachesSection />} />
        </Routes>
      </Box>
    </PageWrapper>
  );
}

export default Roster;

import React from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

export const DistanceUnitToggle = ({ distanceUnit, setDistanceUnit }) => {
  const handleDistanceUnitChange = (event, newUnit) => {
    if (newUnit !== null) {
      setDistanceUnit(newUnit);
    }
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={distanceUnit}
      exclusive
      onChange={handleDistanceUnitChange}
      aria-label="distance unit"
    >
      <ToggleButton value="miles">Mi</ToggleButton>
      <ToggleButton value="meters">M</ToggleButton>
      <ToggleButton value="kilometers">Km</ToggleButton>
    </ToggleButtonGroup>
  );
};

import React, { useState } from 'react';
import { Button, Typography, List, ListItem, ListItemIcon, ListItemText, Box } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ModalWrapper from '../../../components/utils/wrappers/modalWrapper';
import PageWrapper from '../pageWrapper';

const PrivacyPolicy = ({ page = false }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const PrivacyPolicyContent = () => (
    <>
      <Typography variant="h4" gutterBottom sx={{ textAlign: 'left', fontWeight: 'bold' }}>
        Privacy Policy
      </Typography>
      <Typography variant="body1" paragraph sx={{ textAlign: 'left', fontWeight: 'bold' }}>
        Introduction
      </Typography>
      <Typography variant="body2" paragraph sx={{ textAlign: 'left' }}>
        At Track Tools Pro LLC, we respect and are committed to protecting your privacy. This Privacy Statement explains how we collect, use, disclose, and safeguard your information when you use our services. By using our website or app, you consent to the data practices described in this statement.
      </Typography>
      <Typography variant="body1" paragraph sx={{ textAlign: 'left', fontWeight: 'bold' }}>
        Information We Collect
      </Typography>
      <Typography variant="body2" paragraph sx={{ textAlign: 'left' }}>
        Personal Data: This includes but is not limited to your name, email address, and any other information you provide voluntarily when registering or using our services.
        Usage Data: We collect information on how you interact with our services, including log data, device information, and details about your interactions with our content.
      </Typography>
      <Typography variant="body1" paragraph sx={{ textAlign: 'left', fontWeight: 'bold' }}>
        How We Use Your Information
      </Typography>
      <List dense disablePadding>
        <ListItem>
          <ListItemIcon><FiberManualRecordIcon fontSize="small" /></ListItemIcon>
          <ListItemText primary="Service Provision: To provide, maintain, and improve our services." />
        </ListItem>
        <ListItem>
          <ListItemIcon><FiberManualRecordIcon fontSize="small" /></ListItemIcon>
          <ListItemText primary="Personalization: To tailor your experience, including personalized content and recommendations." />
        </ListItem>
        <ListItem>
          <ListItemIcon><FiberManualRecordIcon fontSize="small" /></ListItemIcon>
          <ListItemText primary="Analytics: For understanding how our services are used to help us improve user experience." />
        </ListItem>
        <ListItem>
          <ListItemIcon><FiberManualRecordIcon fontSize="small" /></ListItemIcon>
          <ListItemText primary="Communication: To send you updates, marketing materials, or respond to your inquiries with your consent." />
        </ListItem>
        <ListItem>
          <ListItemIcon><FiberManualRecordIcon fontSize="small" /></ListItemIcon>
          <ListItemText primary="Legal Compliance: To comply with laws, legal processes, or to protect our rights, privacy, safety, or property." />
        </ListItem>
      </List>
      <Typography variant="body1" paragraph sx={{ textAlign: 'left', fontWeight: 'bold' }}>
        Data Sharing
      </Typography>
      <Typography variant="body2" paragraph sx={{ textAlign: 'left' }}>
        Third Parties: We may share data with third-party service providers who assist us in operating our business and services. These parties are obligated to protect your information.
        Legal Requirements: We will disclose your information if required to do so by law or in response to valid requests by public authorities.
      </Typography>
      <Typography variant="body1" paragraph sx={{ textAlign: 'left', fontWeight: 'bold' }}>
        Data Security
      </Typography>
      <Typography variant="body2" paragraph sx={{ textAlign: 'left' }}>
        We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the internet or electronic storage is 100% secure.
      </Typography>
      <Typography variant="body1" paragraph sx={{ textAlign: 'left', fontWeight: 'bold' }}>
        Your Rights
      </Typography>
      <List dense disablePadding>
        <ListItem>
          <ListItemIcon><FiberManualRecordIcon fontSize="small" /></ListItemIcon>
          <ListItemText primary="Access/Correct: You have the right to access, correct, or delete your personal information." />
        </ListItem>
        <ListItem>
          <ListItemIcon><FiberManualRecordIcon fontSize="small" /></ListItemIcon>
          <ListItemText primary="Opt-Out: You can opt-out of marketing communications at any time." />
        </ListItem>
        <ListItem>
          <ListItemIcon><FiberManualRecordIcon fontSize="small" /></ListItemIcon>
          <ListItemText primary="Data Portability: You can request to receive your data in a commonly used format." />
        </ListItem>
      </List>
      <Typography variant="body1" paragraph sx={{ textAlign: 'left', fontWeight: 'bold' }}>
        Children's Privacy
      </Typography>
      <Typography variant="body2" paragraph sx={{ textAlign: 'left' }}>
        Our services are not directed to individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we learn we have collected personal information from a child under 13, we will take steps to delete such information.
      </Typography>
      <Typography variant="body1" paragraph sx={{ textAlign: 'left', fontWeight: 'bold' }}>
        Changes to This Privacy Statement
      </Typography>
      <Typography variant="body2" paragraph sx={{ textAlign: 'left' }}>
        We may update this Privacy Statement to reflect changes to our practices or for other operational, legal, or regulatory reasons. We will notify you of significant changes via email or through our website.
      </Typography>
      <Typography variant="body1" paragraph sx={{ textAlign: 'left', fontWeight: 'bold' }}>
        Contact Us
      </Typography>
      <Typography variant="body2" paragraph sx={{ textAlign: 'left' }}>
        If you have questions or comments about this Privacy Statement, please contact us at support@tracktoolspro.com.
      </Typography>
    </>
  );

  if (page) {
    return (
      <Box p={2}>
        <PageWrapper>
          <PrivacyPolicyContent />
        </PageWrapper>
      </Box>
    );
  }

  return (
    <>
      <Button onClick={handleOpen} variant="contained" color="primary" size="small">
        View Privacy Policy
      </Button>
      <ModalWrapper open={open} handleClose={handleClose} sx={{ zIndex: 10000 }}>
        <PrivacyPolicyContent />
      </ModalWrapper>
    </>
  );
};

export default PrivacyPolicy;

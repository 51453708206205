import React, { useState, useCallback } from "react";
import {
  TextField,
  Stack,
  Divider,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Collapse,
  Chip,
  Tooltip,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ViewModal from "../../../../components/viewModal/viewModal";
import useApiService from "../../../../api/apiService";

export const FScoreCalc = ({ open, onClose }) => {
  const [raceTime, setRaceTime] = useState("");
  const [raceType, setRaceType] = useState("");
  const [fScore, setFScore] = useState("");
  const [showResult, setShowResult] = useState(false);

  const { findScore } = useApiService();

  const convertTimeToSeconds = useCallback((timeString) => {
    const parts = timeString.split(":").map(Number);
    let seconds = 0;
    if (parts.length === 3) {
      seconds = parts[0] * 3600 + parts[1] * 60 + parts[2];
    } else if (parts.length === 2) {
      seconds = parts[0] * 60 + parts[1];
    } else if (parts.length === 1) {
      seconds = parts[0];
    }
    return seconds;
  }, []);

  const guessEventByTime = useCallback((timeInSeconds) => {
    if (timeInSeconds >= 7200) return "marathon";
    if (timeInSeconds >= 3600) return "half_marathon";
    if (timeInSeconds >= 1680) return "10000";
    if (timeInSeconds >= 860) return "5000";
    if (timeInSeconds >= 480) return "3000";
    if (timeInSeconds >= 240) return "mile";
    if (timeInSeconds >= 180) return "1000";
    return "800";
  }, []);

  const handleRaceTimeChange = useCallback(
    (e) => {
      const newRaceTime = e.target.value;
      setRaceTime(newRaceTime);
      if (newRaceTime) {
        const timeInSeconds = convertTimeToSeconds(newRaceTime);
        const guessedRaceType = guessEventByTime(timeInSeconds);
        setRaceType(guessedRaceType);
      }
    },
    [convertTimeToSeconds, guessEventByTime]
  );

  const handleFindScore = useCallback(async () => {
    try {
      const timeInSeconds = convertTimeToSeconds(raceTime);
      const response = await findScore({
        timeInSeconds: timeInSeconds,
        race: raceType,
      });
      if (response.data && response.data.fScore) {
        const newFScore = Number(response.data.fScore).toFixed(2);
        setFScore(newFScore);
        setShowResult(true);
      }
    } catch (error) {
      console.error("Error finding score:", error);
    }
  }, [convertTimeToSeconds, findScore, raceTime, raceType]);

  return (
    <ViewModal open={open} onClose={onClose}>
      <Stack direction="column" spacing={2} sx={{ p: 2 }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="h5" component="h2" sx={{ fontWeight: "bold" }}>
            FScore Calculator
          </Typography>
          <Tooltip
            title={
              "The F-Score is an advanced fitness metric designed specifically for " +
              "distance runners that combines multiple training calculators and race " +
              "prediction algorithms into a single, high-precision score. This " +
              "system analyzes your current fitness level to generate " +
              "optimized training paces across all intensity zones and predict race " +
              "performances with exceptional accuracy. Unlike traditional calculators " +
              "that use basic formulas, the F-Score leverages sophisticated " +
              "statistical modeling to account for the complex relationships between " +
              "different race distances and training intensities."
            }
            arrow
          >
            <IconButton size="small">
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
        <Divider
          sx={{ width: "100%", my: 2, fontWeight: "bold" }}
          component="div"
        >
          Race Details
        </Divider>
        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            size="small"
            name="raceTime"
            label="Time (HH:MM:SS)"
            value={raceTime}
            onChange={handleRaceTimeChange}
            placeholder="00:00:00"
            sx={{ flex: 1 }}
          />
          <FormControl size="small" sx={{ flex: 1 }}>
            <InputLabel id="race-type-label">Race Type</InputLabel>
            <Select
              labelId="race-type-label"
              value={raceType}
              onChange={(e) => setRaceType(e.target.value)}
              label="Race Type"
            >
              <MenuItem value="marathon">Marathon</MenuItem>
              <MenuItem value="half_marathon">Half Marathon</MenuItem>
              <MenuItem value="10000">10000m</MenuItem>
              <MenuItem value="8000">8000m</MenuItem>
              <MenuItem value="5000">5000m</MenuItem>
              <MenuItem value="3000">3000m</MenuItem>
              <MenuItem value="mile">Mile</MenuItem>
              <MenuItem value="1500">1500m</MenuItem>
              <MenuItem value="1000">1000m</MenuItem>
              <MenuItem value="800">800m</MenuItem>
            </Select>
          </FormControl>
          <Button
            onClick={handleFindScore}
            variant="contained"
            color="primary"
            size="small"
          >
            Calculate
          </Button>
        </Stack>
        <Collapse in={showResult}>
          <Chip label={`FScore: ${fScore}`} color="primary" />
        </Collapse>
      </Stack>
    </ViewModal>
  );
};

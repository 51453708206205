import React, { useState } from "react";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  CircularProgress,
  Button,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TrophyIcon from "@mui/icons-material/EmojiEvents";
import ViewModal from "../../../../components/viewModal/viewModal";

export const TffrsSim = ({ open, onClose }) => {
  const [trackType, setTrackType] = useState(null);
  const [divisions, setDivisions] = useState([]);
  const [loadingDivisions, setLoadingDivisions] = useState(false);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [gender, setGender] = useState();
  const [conferences, setConferences] = useState([]);
  const [loadingConferences, setLoadingConferences] = useState(false);
  const [teamScores, setTeamScores] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [teamDetails, setTeamDetails] = useState([]);
  const [selectedTeamDetails, setSelectedTeamDetails] = useState([]);

  const handleTrackType = (event, newTrackType) => {
    if (newTrackType !== null) {
      setTrackType(newTrackType);
      fetchTrackData(newTrackType);
      setExpanded("panel1"); // Auto-collapse other sections
    }
  };

  const handleDivisionType = async (divisionName) => {
    setSelectedDivision(divisionName);
    setLoadingConferences(true);
    const division = divisions.find(
      (division) => division.name === divisionName
    );
    if (division) {
      try {
        const response = await fetch(division.link);
        const data = await response.text();
        extractConferencesFromHTML(data);
      } catch (error) {
        console.error("Error fetching division data:", error);
      } finally {
        setLoadingConferences(false);
      }
    }
    setExpanded("panel2"); // Auto-collapse other sections
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const fetchTrackData = async (type) => {
    setLoadingDivisions(true);
    const url =
      type === "indoor"
        ? "https://tf.tfrrs.org/indoor_lists.html"
        : "https://tf.tfrrs.org/outdoor_lists.html";
    try {
      const response = await fetch(url);
      const data = await response.text();
      const extractedDivisions = extractDivisionsFromHTML(data);
      setDivisions(extractedDivisions);
    } catch (error) {
      console.error("Error fetching track data:", error);
    } finally {
      setLoadingDivisions(false);
    }
  };

  const extractDivisionsFromHTML = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const divisions = [];

    const tabContent = doc.querySelectorAll(".tab-content .tab-pane");
    tabContent.forEach((tab) => {
      const divisionName = tab.id.toUpperCase();
      const turboFrame = tab.querySelector("turbo-frame");
      const link = turboFrame.getAttribute("src");
      divisions.push({
        name: divisionName,
        link: `https://tf.tfrrs.org${link}`,
      });
    });

    return divisions;
  };

  const extractConferencesFromHTML = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const conferenceList = doc.querySelectorAll(".list-unstyled li");
    const extractedConferences = Array.from(conferenceList).map((li) => {
      const conferenceName = li.querySelector("a").textContent.trim();
      const menLink = li
        .querySelector('a[href*="?gender=m"]')
        .getAttribute("href");
      const womenLink = li
        .querySelector('a[href*="?gender=f"]')
        .getAttribute("href");
      return { name: conferenceName, menLink, womenLink };
    });
    setConferences(extractedConferences);
  };

  const handleGenderChange = (event, newGender) => {
    if (newGender !== null) {
      setGender(newGender);
    }
  };

  const handleConferenceSelection = async (conference) => {
    const baseLink = gender === "m" ? conference.menLink : conference.womenLink;
    try {
      const initialResponse = await fetch(baseLink);
      const initialData = await initialResponse.text();
      const parser = new DOMParser();
      const doc = parser.parseFromString(initialData, "text/html");
      calculateDynamicMeetScores(doc);
    } catch (error) {
      console.error(
        `Error fetching conference data for ${conference.name}:`,
        error
      );
    }
  };

  const handleTeamSelection = (teamName) => {
    setSelectedTeam(teamName);
    const details = teamDetails.find((detail) => detail.teamName === teamName);
    if (details) {
      setSelectedTeamDetails(details.athletes);
    }
    setExpanded(false);
  };

  const calculateDynamicMeetScores = (htmlDocument) => {
    const pointsAllocation = [
      { place: 1, points: 10 },
      { place: 2, points: 8 },
      { place: 3, points: 6 },
      { place: 4, points: 4 },
      { place: 5, points: 2 },
      { place: 6, points: 1 },
    ];

    let localTeamScores = {};
    let localTeamDetails = [];
    const eventTables = htmlDocument.querySelectorAll("table");

    eventTables.forEach((table) => {
      const hasAthletesColumn = table
        .querySelector("thead tr th")
        .textContent.includes("Athletes");
      const teamColumnIndex = hasAthletesColumn ? 1 : 3;

      const rows = table.querySelectorAll("tbody tr");
      rows.forEach((row, index) => {
        if (index < pointsAllocation.length) {
          const teamCell = row.querySelector(
            `td:nth-child(${teamColumnIndex + 1})`
          );
          const teamName = teamCell
            ? teamCell.textContent.trim()
            : "Unknown Team";
          const athleteName = row
            .querySelector("td:nth-child(2)")
            .textContent.trim();
          const athleteLink = row
            .querySelector("td:nth-child(2) a")
            .getAttribute("href");
          const athleteEventTime = row
            .querySelector("td:nth-child(5)")
            .textContent.trim();
          const athleteEvent = table.previousElementSibling
            .querySelector("h3")
            .textContent.trim();
          const eventPlace = row
            .querySelector("td:nth-child(1)")
            .textContent.trim();

          if (!teamName.includes(",") && teamName !== "Unknown Team") {
            const points = pointsAllocation[index].points;
            localTeamScores[teamName] = (localTeamScores[teamName] || 0) + points;

            let teamDetail = localTeamDetails.find(
              (detail) => detail.teamName === teamName
            );
            if (!teamDetail) {
              teamDetail = { teamName, athletes: [] };
              localTeamDetails.push(teamDetail);
            }

            const athleteIndex = teamDetail.athletes.findIndex(
              (athlete) => athlete.athleteName === athleteName
            );
            if (athleteIndex !== -1) {
              teamDetail.athletes[athleteIndex].points += points;
              teamDetail.athletes[athleteIndex].events.push({
                event: athleteEvent,
                time: athleteEventTime,
                place: eventPlace,
              });
            } else {
              teamDetail.athletes.push({
                athleteName,
                points,
                athleteLink,
                events: [
                  {
                    event: athleteEvent,
                    time: athleteEventTime,
                    place: eventPlace,
                  },
                ],
              });
            }
          }
        }
      });
    });

    localTeamDetails.forEach((teamDetail) => {
      teamDetail.athletes.sort((a, b) => b.points - a.points);
    });

    const sortedTeamScores = Object.entries(localTeamScores).sort(
      (a, b) => b[1] - a[1]
    );
    setTeamScores(sortedTeamScores);
    setTeamDetails(localTeamDetails);
    setExpanded("panel3");
  };

  return (
    <ViewModal open={open} onClose={onClose}>
      <Stack spacing={2}>
        <Typography variant="h5" align="center" gutterBottom>
          Conference Meet Simulator
        </Typography>

        <ToggleButtonGroup
          color="primary"
          value={trackType}
          exclusive
          onChange={handleTrackType}
          aria-label="Track Type"
          sx={{ justifyContent: "center" }}
          size="small"
        >
          <ToggleButton value="indoor">Indoor Season</ToggleButton>
          <ToggleButton value="outdoor">Outdoor Season</ToggleButton>
        </ToggleButtonGroup>

        {trackType && (
          <>
            {loadingDivisions ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleAccordionChange("panel1")}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Select Division</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2} justifyContent="center">
                    {divisions.map((division, index) => (
                      <Grid item key={index} xs={12} sm={6} md={4}>
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={() => handleDivisionType(division.name)}
                          sx={{ height: "36px" }}
                          size="small"
                        >
                          {division.name}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}
          </>
        )}

        {selectedDivision && (
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleAccordionChange("panel2")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Select Conference & Gender</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {loadingConferences ? (
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              ) : (
                <Stack spacing={3}>
                  <ToggleButtonGroup
                    color="primary"
                    value={gender}
                    exclusive
                    onChange={handleGenderChange}
                    aria-label="Gender selection"
                    sx={{ justifyContent: "center" }}
                    size="small"
                  >
                    <ToggleButton value="m">Men</ToggleButton>
                    <ToggleButton value="f">Women</ToggleButton>
                  </ToggleButtonGroup>

                  {gender && (
                    <Grid container spacing={2}>
                      {conferences.map((conference, index) => (
                        <Grid item key={index} xs={12} sm={6} md={4}>
                          <Button
                            variant="contained"
                            onClick={() => handleConferenceSelection(conference)}
                            sx={{ height: "36px" }}
                            size="small"
                          >
                            {conference.name}
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Stack>
              )}
            </AccordionDetails>
          </Accordion>
        )}

        {teamScores.length > 0 && (
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleAccordionChange("panel3")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Projected Results</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={2} alignItems="center">
                {teamScores.map(([teamName, score], index) => (
                  <Chip
                    key={index}
                    label={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {index < 3 && (
                          <TrophyIcon
                            sx={{
                              mr: 1,
                              color:
                                index === 0
                                  ? "gold"
                                  : index === 1
                                  ? "silver"
                                  : "#cd7f32",
                            }}
                          />
                        )}
                        <Typography>
                          {teamName}: {score} points
                        </Typography>
                      </Box>
                    }
                    onClick={() => handleTeamSelection(teamName)}
                    color="primary"
                    variant={selectedTeam === teamName ? "filled" : "outlined"}
                    sx={{
                      width: "100%",
                      maxWidth: 300,
                      height: "auto",
                      py: 1,
                      "& .MuiChip-label": {
                        display: "block",
                        whiteSpace: "normal",
                      },
                    }}
                    size="small"
                  />
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>
        )}

        {selectedTeam && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" gutterBottom>
              {selectedTeam} - Scoring Breakdown
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Athlete</TableCell>
                    <TableCell>Events</TableCell>
                    <TableCell align="right">Points</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedTeamDetails.map((athlete, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Chip
                          label={athlete.athleteName}
                          component="a"
                          href={athlete.athleteLink}
                          target="_blank"
                          clickable
                          color="primary"
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <Stack spacing={1}>
                          {athlete.events.map((event, idx) => (
                            <Typography key={idx} variant="body2">
                              {event.place}. {event.event}: {event.time}
                            </Typography>
                          ))}
                        </Stack>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="h6">{athlete.points}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Stack>
    </ViewModal>
  );
};

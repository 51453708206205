// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyASQcPg5czoCAXNbdYIzsSRDrcePhFdThs",
  authDomain: "track-tools-pro-93274.firebaseapp.com",
  projectId: "track-tools-pro-93274",
  storageBucket: "track-tools-pro-93274.appspot.com",
  messagingSenderId: "295614010630",
  appId: "1:295614010630:web:9dd5f94c41210d9865ba87",
  measurementId: "G-W748BW6J1K"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// Initialize Analytics only if running in the browser
let analytics = null;
if (typeof window !== "undefined") {
  analytics = getAnalytics(app);
}

export { messaging, analytics, logEvent };

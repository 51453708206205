import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import ModalWrapper from '../wrappers/modalWrapper';

const AreYouSure = ({ isOpen, handleClose, onConfirm, itemName, sx }) => {
  const truncatedName = itemName.length > 40 ? itemName.substring(0, 37) + '...' : itemName;

  return (
    <ModalWrapper open={isOpen} handleClose={handleClose} sx={{ minWidth: '250px', ...sx }}>
      <Typography variant="h6" gutterBottom>
        Are you sure?
      </Typography>
      <Typography variant="body1" paragraph>
        Do you really want to delete {truncatedName}? This action cannot be undone.
      </Typography>
      <Box display="flex" justifyContent="flex-end">
        <Button onClick={handleClose} color="primary" sx={{ mr: 2 }}>
          Cancel
        </Button>
        <Button 
          onClick={() => {
            onConfirm();
            handleClose();
          }} 
          variant="contained" 
          color="error"
        >
          Delete
        </Button>
      </Box>
    </ModalWrapper>
  );
};

export default AreYouSure;

import React, { useState } from 'react';
import { Button, Typography, Box, CircularProgress, Alert } from '@mui/material';
import axios from 'axios';
import useApiService from '../../../../api/apiService';
import { motion, AnimatePresence } from 'framer-motion';
import { styled } from '@mui/system';

const AnimatedButton = styled(motion(Button))(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
}));

const ProgressOverlay = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
}));

const SyncTffrsPrs = ({ athletes }) => {
  const [syncing, setSyncing] = useState(false);
  const [progress, setProgress] = useState(0);
  const { useCreateAthletePr } = useApiService();
  const createAthletePr = useCreateAthletePr();

  const convertTimeToSeconds = (timeString) => {
    const parts = timeString.split(":").map(Number);
    let seconds = 0;
    if (parts.length === 3) {
      seconds = parts[0] * 3600 + parts[1] * 60 + parts[2];
    } else if (parts.length === 2) {
      seconds = parts[0] * 60 + parts[1];
    } else if (parts.length === 1) {
      seconds = parts[0];
    }
    return seconds;
  };

  const syncPrs = async () => {
    setSyncing(true);
    setProgress(0);
    const totalAthletes = athletes.length;

    for (let i = 0; i < totalAthletes; i++) {
      const athlete = athletes[i];
      if (athlete.tffrsLink) {
        try {
          const response = await axios.get(athlete.tffrsLink);
          const parser = new DOMParser();
          const htmlDocument = parser.parseFromString(response.data, "text/html");

          const tables = htmlDocument.querySelectorAll(
            ".table.bests, .table.xc_bests, .table.indoor_bests, .table.outdoor_bests"
          );
          
          for (const table of tables) {
            const rows = table.querySelectorAll("tr");
            for (const row of rows) {
              const cells = row.querySelectorAll("td");
              for (let j = 0; j < cells.length; j += 2) {
                const event = cells[j]?.textContent.trim();
                const performance = cells[j + 1]?.querySelector("a")?.textContent.trim();
                if (event && performance && event !== "\xa0") {
                  const isDistanceEvent = ["SP", "DT", "HT", "JT", "LJ", "TJ", "HJ", "PV"].some((e) => event.includes(e));
                  let timeInSeconds = null;
                  let distance = null;
                  if (isDistanceEvent) {
                    if (performance.includes(".")) {
                      distance = parseFloat(performance);
                    } else {
                      const [feet, inches] = performance.split("-").map(Number);
                      distance = Math.round((feet * 12 + inches) * 0.0254);
                    }
                  } else {
                    timeInSeconds = convertTimeToSeconds(performance);
                  }
                  try {
                    await createAthletePr.mutateAsync({
                      athleteId: athlete.id,
                      event: event,
                      unitType: isDistanceEvent ? "distance" : "time",
                      distance: isDistanceEvent ? distance : null,
                      time: isDistanceEvent ? null : timeInSeconds,
                      source: "tffrs",
                    });
                  } catch (error) {
                    console.error(`Error updating PR for ${athlete.firstName} ${athlete.lastName}, event: ${event}`, error);
                    // Continue with the next PR even if there's an error
                  }
                }
              }
            }
          }
        } catch (error) {
          console.error(`Error syncing PRs for ${athlete.firstName} ${athlete.lastName}:`, error);
          // Continue with the next athlete even if there's an error
        }
      }
      setProgress(((i + 1) / totalAthletes) * 100);
    }
    setSyncing(false);
  };

  return (
    <Box>
      <Alert severity="info" sx={{ mb: 2 }}>
        To sync PRs, each athlete needs a valid TFFRS link in their profile. Please ensure all athletes have their TFFRS links updated before proceeding.
      </Alert>
      <AnimatePresence>
        <AnimatedButton
          variant="contained"
          onClick={syncPrs}
          disabled={syncing}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          {syncing ? 'Syncing...' : 'Sync TFFRS PRs'}
          <AnimatePresence>
            {syncing && (
              <ProgressOverlay
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <CircularProgress
                  variant="determinate"
                  value={progress}
                  size={24}
                  thickness={4}
                />
              </ProgressOverlay>
            )}
          </AnimatePresence>
        </AnimatedButton>
      </AnimatePresence>
      {syncing && (
        <Box sx={{ width: '100%', mt: 2 }}>
          <motion.div
            initial={{ width: 0 }}
            animate={{ width: `${progress}%` }}
            style={{
              height: 4,
              background: 'linear-gradient(to right, #4CAF50, #8BC34A)',
              borderRadius: 2,
            }}
          />
          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            sx={{ mt: 1 }}
          >
            {`${Math.round(progress)}%`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default SyncTffrsPrs;
